import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Api, Api_Token } from "../../utils/Api";
import Cookies from "js-cookie";

export const send_Register = createAsyncThunk("Register", async (data) => {
  const response = await Api.post("/auth/register", data);
  return response.data;
});

export const send_login = createAsyncThunk("login", async (data) => {
  const response = await Api.post("/auth/login", data);
  return response.data;
});

export const get_user = createAsyncThunk("user", async () => {
  const response = await Api_Token.get("/user");
  return response.data;
});

const initialState = {
  loading: false,
  IsAuth: false,
  user: {},
};

export const AuthSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    checkIsAuth: (state, action) => {
      state.IsAuth = Cookies.get("token") ? true : false;
    },
    CheckEmailRegulaire: (state, action) => {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailPattern.test(action.email);
    },
    Logout: (state, action) => {
      Cookies.remove("token");
      state.user = {};
      state.IsAuth = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(send_Register.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(send_Register.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(send_Register.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(send_login.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(send_login.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload.user;
      Cookies.set("token", action.payload.token);
      state.IsAuth = true;
    });
    builder.addCase(send_login.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(get_user.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(get_user.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
    });
    builder.addCase(get_user.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default AuthSlice.reducer;
export const { checkIsAuth, CheckEmailRegulaire, Logout } = AuthSlice.actions;
