import { useCallback } from "react";

const useSortPorts = () => {
  return useCallback((ports) => {
    if (!Array.isArray(ports)) {
      console.error("useSortPorts: ports is not an array", ports);
      return [];
    }

    return [...ports].sort((a, b) => {
      // Convert dateArrival to Date object for comparison
      console.log(a, b);
      const dateA = new Date(a.pivot.dateArrival);
      const dateB = new Date(b.pivot.dateArrival);

      // Define order for typePort: depart -> escale -> arrivee (or arrival)
      const order = ["depart", "escale", "arrival"];
      const typeA = order.indexOf(a.pivot.typePort.trim().toLowerCase());
      const typeB = order.indexOf(b.pivot.typePort.trim().toLowerCase());

      // If typePort is different, sort by typePort
      if (typeA !== typeB) {
        return typeA - typeB;
      }

      // If typePort is the same, sort by dateArrival
      return dateA - dateB;
    });
  }, []);
};

export default useSortPorts;
