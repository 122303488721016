import Indexdasboard from "../../components/dashboard/IndexDashbord";
import { Box } from "@mui/material";
import PagepriceList from "../../components/Products/PagePriceList";

const PagePriceListCommercial = () => {
  return (
    <Box>
      <Indexdasboard Children={<PagepriceList />} />
    </Box>
  );
};

export default PagePriceListCommercial;
