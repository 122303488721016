import React, { useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { GetDayStatistics } from "../../store/slice/StatistiqueSlice";

const JourneyTimeAnalysis = () => {
  const dispatch = useDispatch();
  const { dayStatistique } = useSelector((state) => state.Statistique);
  useEffect(() => {
    dispatch(GetDayStatistics());
  }, [dispatch]);
  const labels = dayStatistique?.map((item) => item.time_interval);
  const dataPoints = dayStatistique?.map((item) => item.count);
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Journey Time",
        data: dataPoints,
        backgroundColor: "#0671B5",
        borderColor: "#0671B5",
        borderWidth: 1,
        borderRadius: 20,
        borderSkipped: false,
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#888",
          //maxRotation: 90,
          //  minRotation: 90,
          padding: 10,
        },
        position: "bottom",
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
        beginAtZero: true,
      },
    },
    barPercentage: 0.5, // Adjusts the width of the bars
    categoryPercentage: 0.5, // Adjusts the spacing between bars
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%", // Ensures the Box takes up the full height of its container
        padding: 2, // Adds some padding around the content
      }}
    >
      <h2>Journey Time Analysis</h2>
      <Bar data={data} options={options} />
    </Box>
  );
};

export default JourneyTimeAnalysis;
