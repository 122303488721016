import {
  Box,
  Card,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TitreGraphik from "../../../components/Typography/TitreGraphik";
import ButtonSimple from "../../../components/Button/ButtonSimple";
import ExportButton from "../../../components/Button/ButtonExport";
import Indexdasboard from "../../../components/dashboard/IndexDashbord";
import { StyledTableCell } from "../../../assets/style/Table";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import {
  GetCustomer,
  etatInisialCustomerSlice,
  GetCostumerExcel,
} from "../../../store/slice/customerSlice";
import { useEffect, useState, useCallback } from "react";
import ButtonRadius from "../../../components/Button/ButtonRadius";
import { Link } from "react-router-dom";
import EditCustomer from "../../../components/Popup/EditCustomer";
import CustomAlert from "../../../components/Alert/Alert";
import { CircularIndeterminate } from "../../../components/loading";
import LabelTypography from "../../../components/Typography/LabelTypography";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { styleInput } from "../../../assets/style/Input";

export default function PageCustomerManagement() {
  return <Indexdasboard Children={<CustomerManagement />} />;
}

function CustomerManagement() {
  const { CustomerData, CustomerAllData, etat, message } = useSelector(
    (state) => state.CustomerSlice
  );
  const { user } = useSelector((state) => state.AuthSlice);
  const [Page, setPage] = useState(1);
  const [dataUpdate, setDataUpdate] = useState();
  const [openEdit, setOpenEdit] = useState(false);
  const [filter, setFilter] = useState();
  const dispatch = useDispatch();
  const HasMore = CustomerData.current_page < CustomerData.last_page;
  console.log(Page);
  console.log(CustomerData.current_page);
  console.log(CustomerData.last_page);
  console.log(HasMore);
  const fetchMoreData = useCallback(
    (Data) => {
      dispatch(GetCustomer(Data));
    },
    [dispatch]
  );

  useEffect(() => {
    const data = {
      page: Page,
      filter: filter,
    };

    const delayFetch = setTimeout(() => {
      fetchMoreData(data);
    }, 1000);

    return () => clearTimeout(delayFetch);
  }, [fetchMoreData, Page, filter]);

  console.log(CustomerAllData);
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TitreGraphik>Customer Management</TitreGraphik>
        </Grid>
        <Grid item xs={12} sx={{ display: { xs: "none", md: "flex" } }}>
          <Card sx={{ padding: "2%", width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Link to={`/${user.Role}/AddCustomer`}>
                  <ButtonSimple
                    variant="contained"
                    sx={{ background: "#064C91" }}
                  >
                    + Add a customer
                  </ButtonSimple>
                </Link>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "right" }}>
                <ExportButton
                  onClick={() => {
                    dispatch(GetCostumerExcel(filter));
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "left" }}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={filter}
                  onChange={(e) => {
                    setFilter(e.target.value);
                    setPage(1);
                  }}
                  placeholder="Chercher par Name or email or number ..."
                  sx={{ ...styleInput, minWidth: "35%" }}
                />
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <InfiniteScroll
                    dataLength={CustomerAllData.length}
                    next={() => {
                      setPage(Page + 1);
                    }}
                    hasMore={HasMore}
                    loader={<CircularIndeterminate />}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Id Customer</StyledTableCell>
                          <StyledTableCell>First name</StyledTableCell>
                          <StyledTableCell>Last name</StyledTableCell>
                          <StyledTableCell>Company</StyledTableCell>
                          <StyledTableCell>Adress</StyledTableCell>
                          <StyledTableCell>Island</StyledTableCell>
                          <StyledTableCell>Email</StyledTableCell>
                          <StyledTableCell>Phone number</StyledTableCell>
                          <StyledTableCell>Action</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {CustomerAllData.map((c, index) => (
                          <TableRow key={index}>
                            <StyledTableCell>
                              <Link
                                to={`/${user.Role}/customerDetaill/${c.id}`}
                                style={{
                                  textDecoration: "none",
                                  color: "inherit",
                                }}
                              >
                                <LabelTypography
                                  sx={{
                                    textAlign: "center",
                                    color: "#064C91",
                                  }}
                                >
                                  #{c.id}
                                </LabelTypography>
                              </Link>
                            </StyledTableCell>
                            <StyledTableCell>{c.firstName}</StyledTableCell>
                            <StyledTableCell>{c.lastName}</StyledTableCell>
                            <StyledTableCell>
                              {c?.company?.nomCompany}
                            </StyledTableCell>
                            <StyledTableCell>{c?.adress}</StyledTableCell>
                            <StyledTableCell>{c?.island}</StyledTableCell>
                            <StyledTableCell>{c.Email}</StyledTableCell>
                            <StyledTableCell>{c.NumberPhone}</StyledTableCell>
                            <StyledTableCell>
                              <ButtonRadius
                                onClick={() => {
                                  setDataUpdate(c);
                                  setOpenEdit(true);
                                }}
                                sx={{
                                  background: "#064C91",
                                  color: "white",
                                  minWidth: "100px",
                                }}
                              >
                                Edit
                              </ButtonRadius>
                            </StyledTableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </InfiniteScroll>
                </TableContainer>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} sx={{ display: { xs: "flex", md: "none" } }}>
          <InfiniteScroll
            dataLength={CustomerAllData.length}
            next={() => {
              setPage(Page + 1);
            }}
            hasMore={HasMore}
            loader={<CircularIndeterminate />}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Link to={`/${user.Role}/AddCustomer`}>
                  <ButtonSimple
                    variant="contained"
                    sx={{ background: "#064C91" }}
                  >
                    + Add a customer
                  </ButtonSimple>
                </Link>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "right" }}>
                <ExportButton
                  onClick={() => {
                    dispatch(GetCostumerExcel(filter));
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "left" }}>
                <Card>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    value={filter}
                    onChange={(e) => {
                      setFilter(e.target.value);
                      setPage(1);
                    }}
                    placeholder="Chercher par Name or email or number ..."
                    sx={{ minWidth: "100%" }}
                  />
                </Card>
              </Grid>
              {CustomerAllData.map((c, index) => (
                <Grid item xs={12}>
                  <Card
                    variant="outlined"
                    sx={{ maxWidth: 345, padding: "2%", margin: "auto" }}
                  >
                    <LabelTypography variant="body1" color="text.primary">
                      Id Customer :{" "}
                      <Link
                        to={`/${user.Role}/customerDetaill/${c.id}`}
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                        }}
                      >
                        <LabelTypography component="span" color="#064C91">
                          #{c.id}
                        </LabelTypography>
                      </Link>
                    </LabelTypography>
                    <LabelTypography variant="body1" color="text.primary">
                      Name :
                      <LabelTypography component="span" color="#064C91">
                        {c.firstName} {c.lastName}
                      </LabelTypography>
                    </LabelTypography>

                    <LabelTypography variant="body1" color="text.primary">
                      Company :
                      <LabelTypography component="span" color="#064C91">
                        {" "}
                        {c?.company?.nomCompany}
                      </LabelTypography>
                    </LabelTypography>

                    <LabelTypography variant="body1" color="text.primary">
                      Adress :
                      <LabelTypography component="span" color="#064C91">
                        {" "}
                        {c?.adress}
                      </LabelTypography>
                    </LabelTypography>
                    <LabelTypography variant="body1" color="text.primary">
                      island :
                      <LabelTypography component="span" color="#064C91">
                        {" "}
                        {c?.island}
                      </LabelTypography>
                    </LabelTypography>
                    <LabelTypography variant="body1" color="text.primary">
                      Email :
                      <LabelTypography component="span" color="#064C91">
                        {" "}
                        {c.Email}
                      </LabelTypography>
                    </LabelTypography>
                    <LabelTypography variant="body1" color="text.primary">
                      Phone number :
                      <LabelTypography component="span" color="#064C91">
                        {" "}
                        {c.NumberPhone}
                      </LabelTypography>
                    </LabelTypography>
                    <ButtonRadius
                      onClick={() => {
                        setDataUpdate(c);
                        setOpenEdit(true);
                      }}
                      sx={{
                        background: "#064C91",
                        color: "white",
                        width: "30%",
                      }}
                    >
                      Edit
                    </ButtonRadius>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </InfiniteScroll>
        </Grid>
      </Grid>
      <EditCustomer
        open={openEdit}
        onClose={() => {
          setOpenEdit(false);
        }}
        UpdateData={() => {
          setPage(1);
          const data = {
            page: 1,
            filter: filter,
          };
          dispatch(GetCustomer(data));
        }}
        data={dataUpdate}
      />
      <CustomAlert
        open={etat !== "inisial"}
        onClose={() => {
          dispatch(etatInisialCustomerSlice());
        }}
        etat={etat}
        message={message}
      />
    </Box>
  );
}
