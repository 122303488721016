import { Grid, Box } from "@mui/material";
import TitreGraphik from "../../components/Typography/TitreGraphik";
import ListShips from "../../components/ships/ListShips";
import Indexdasboard from "../../components/dashboard/IndexDashbord";
import CaptainList from "../../components/captain/CaptaineList";

export default function FleetManagementPage() {
  return <Indexdasboard Children={<FleetManagement />} />;
}
function FleetManagement() {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TitreGraphik>Fleet Management</TitreGraphik>
        </Grid>
        <Grid item xs={12}>
          <ListShips />
        </Grid>
        <Grid item xs={12}>
          <CaptainList />
        </Grid>
      </Grid>
    </Box>
  );
}
