import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Box,
  DialogTitle,
} from "@mui/material";
import ButtonSonRadius from "../Button/buttonSonRadius";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TitreGraphik from "../Typography/TitreGraphik";

const AddStokeIn = ({ open, onClose, Add }) => {
  const { TypeStoke } = useSelector((state) => state.BookingSlice);
  const submitForm = (e) => {
    e.preventDefault();
    const data = { idTypeStoke: e.target.typeStoke.value };
    Add(data);
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={submitForm}>
        <DialogTitle>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton color="error" onClick={onClose}>
                <CancelIcon />
              </IconButton>
            </Box>
            <TitreGraphik>Add stockage</TitreGraphik>
          </Box>
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <Select
              labelId="type-de-storage"
              id="type-de-storage"
              name="typeStoke"
              required
            >
              {TypeStoke?.map((s) => (
                <MenuItem value={s.id}>{s.type}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <ButtonSonRadius variant="contained" color="error" onClick={onClose}>
            cancel
          </ButtonSonRadius>
          <ButtonSonRadius variant="contained" type="submit" color="primary">
            Add
          </ButtonSonRadius>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddStokeIn;
