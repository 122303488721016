import { Box, Card, Grid } from "@mui/material";
import Indexdasboard from "../../../components/dashboard/IndexDashbord";
import TitreGraphik from "../../../components/Typography/TitreGraphik";
import ExportButton from "../../../components/Button/ButtonExport";
import ListBookingVoyageCosutmer from "../../../components/Booking/ListBookingVoyageCosutmer";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { useEffect, useState } from "react";
import {
  GetBooingVoyageCustomerScrole,
  GetBookingExcel,
  etatInisialBookingSlice,
} from "../../../store/slice/bookingSlice";
import { getStatus } from "../../../store/slice/StatistiqueSlice";
import CustomAlert from "../../../components/Alert/Alert";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ButtonSimple from "../../../components/Button/ButtonSimple";
import { CircularIndeterminate } from "../../../components/loading";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { styleInput } from "../../../assets/style/Input";
import { Link } from "react-router-dom";
const head = [
  "N° booking",
  "Creation date",
  "Container",
  "Route",
  "Destination",
  "Customer",
  "N° Voyage",
  "Date departure",
  "Date arrival",
  "Status",
  "Invoice amount",
  "Payment",
  "Action",
];

export default function PageBookingManagements() {
  return <Indexdasboard Children={<BookingsManagements />} />;
}

function BookingsManagements() {
  const dispatch = useDispatch();
  const { InfobookingDataScrole, bookingDataScrole, etat, message } =
    useSelector((state) => state.BookingSlice);
  const { user } = useSelector((state) => state.AuthSlice);

  const [Page, setPage] = useState(1);
  const [filter, setFilter] = useState();
  const [periode, setPeriode] = useState("years");
  const HasMore =
    InfobookingDataScrole.current_page < InfobookingDataScrole.last_page;

  useEffect(() => {
    const handler = setTimeout(() => {
      const data = {
        page: Page,
        filter: filter,
        periode: periode,
      };
      dispatch(GetBooingVoyageCustomerScrole(data));
    }, 1000);
    return () => clearTimeout(handler);
  }, [dispatch, filter, Page, periode]);

  useEffect(() => {
    dispatch(getStatus());
  }, [dispatch]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TitreGraphik>Booking Management</TitreGraphik>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ padding: "1%", display: { xs: "none", md: "flex" } }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TitreGraphik
                      sx={{ fontSize: { xs: "1rem", md: "1.5rem" } }}
                    >
                      List of quotes and reservations
                    </TitreGraphik>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      value={filter}
                      onChange={(e) => {
                        setFilter(e.target.value);
                        setPage(1);
                      }}
                      placeholder="Chercher par destination, shipper ou receiver ..."
                      sx={{ ...styleInput, minWidth: "45%" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sx={{ textAlign: "right" }}>
                    <Link to={`/${user.Role}/NewBooking`}>
                      <ButtonSimple
                        sx={{
                          ...styleInput,
                          color: "primary",
                          padding: "10px",
                          width: "30%",
                        }}
                      >
                        + New booking
                      </ButtonSimple>
                    </Link>
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: "right" }}>
                    <ExportButton
                      sx={{ width: "30%" }}
                      onClick={() => {
                        dispatch(GetBookingExcel(filter));
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: "right" }}>
                    {periode === "years" ? (
                      <ButtonSimple
                        //variant="contained"
                        startIcon={<CalendarTodayIcon />}
                        sx={{
                          width: "30%",
                          backgroundColor: "lightgray",
                          color: "gray",

                          "&:hover": {
                            backgroundColor: "lightgray",
                            color: "gray",
                          },
                        }}
                        onClick={() => {
                          setPage(1);
                          setPeriode("month");
                        }}
                      >
                        This month
                      </ButtonSimple>
                    ) : (
                      <ButtonSimple
                        variant="contained"
                        startIcon={<CalendarTodayIcon />}
                        sx={{
                          width: "30%",
                          backgroundColor: "lightgray",
                          color: "gray",

                          "&:hover": {
                            backgroundColor: "lightgray",
                            color: "gray",
                          },
                        }}
                        onClick={() => {
                          setPage(1);
                          setPeriode("years");
                        }}
                      >
                        This year
                      </ButtonSimple>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <InfiniteScroll
                  dataLength={bookingDataScrole.length}
                  next={() => {
                    setPage(Page + 1);
                  }}
                  hasMore={HasMore}
                  loader={<CircularIndeterminate />}
                >
                  <ListBookingVoyageCosutmer
                    head={head}
                    Bookings={bookingDataScrole}
                    updateList={() => {
                      dispatch(GetBooingVoyageCustomerScrole(Page));
                    }}
                  />
                </InfiniteScroll>
              </Grid>
            </Grid>
          </Card>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <Grid container spacing={2}>
              <Grid item xs={12} textAlign="center">
                <Card>
                  <Link to={`/${user.Role}/NewBooking`}>
                    <ButtonSimple
                      sx={{
                        ...styleInput,
                        color: "primary",
                        padding: "10px",
                        width: "100%",
                      }}
                    >
                      + New booking
                    </ButtonSimple>
                  </Link>
                </Card>
              </Grid>
              <Grid item xs={6} textAlign="center">
                <ExportButton
                  sx={{ width: "100%" }}
                  onClick={() => {
                    dispatch(GetBookingExcel(filter));
                  }}
                />
              </Grid>
              <Grid item xs={6} textAlign="center">
                {periode === "years" ? (
                  <ButtonSimple
                    variant="contained"
                    startIcon={<CalendarTodayIcon />}
                    sx={{
                      backgroundColor: "lightgray",
                      color: "gray",

                      "&:hover": {
                        backgroundColor: "lightgray",
                        color: "gray",
                      },
                    }}
                    onClick={() => {
                      setPage(1);
                      setPeriode("month");
                    }}
                  >
                    This month
                  </ButtonSimple>
                ) : (
                  <ButtonSimple
                    variant="contained"
                    startIcon={<CalendarTodayIcon />}
                    sx={{
                      backgroundColor: "lightgray",
                      color: "gray",

                      "&:hover": {
                        backgroundColor: "lightgray",
                        color: "gray",
                      },
                    }}
                    onClick={() => {
                      setPage(1);
                      setPeriode("years");
                    }}
                  >
                    This year
                  </ButtonSimple>
                )}
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    value={filter}
                    onChange={(e) => {
                      setFilter(e.target.value);
                    }}
                    placeholder="Chercher par destination, shipper ou receiver ..."
                    sx={{ minWidth: "100%" }}
                  />
                </Card>
              </Grid>
              <Grid item xs={12}>
                <InfiniteScroll
                  dataLength={bookingDataScrole.length}
                  next={() => {
                    setPage(Page + 1);
                  }}
                  hasMore={HasMore}
                  loader={<CircularIndeterminate />}
                >
                  <ListBookingVoyageCosutmer
                    head={head}
                    Bookings={bookingDataScrole}
                    updateList={() => {
                      dispatch(GetBooingVoyageCustomerScrole(Page));
                    }}
                  />
                </InfiniteScroll>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      <CustomAlert
        open={etat !== "inisial"}
        onClose={() => {
          dispatch(etatInisialBookingSlice());
        }}
        etat={etat}
        message={message}
      />
    </Box>
  );
}
