import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Api_Token } from "../../utils/Api";

export const getStatus = createAsyncThunk("get etat", async (param) => {
  const response = await Api_Token.get(`status/getStatus/${param}`);
  return response.data;
});
export const GetStatistiqueGeneral = createAsyncThunk(
  "StatistiqueGeneral",
  async () => {
    const response = await Api_Token.get(`statistique/statistiqueGeneral`);
    return response.data;
  }
);

export const GetStatistiqueSaleChart = createAsyncThunk(
  "chartsale",
  async (data) => {
    const response = await Api_Token.get(
      `statistique/chartsale?time=${data.time}&portId=${data.portId}`,
      data
    );
    return response.data;
  }
);

export const GetStokeStatistics = createAsyncThunk(
  "StokeStatistics",
  async (time) => {
    const response = await Api_Token.get(
      `statistique/StokeStatistics?time=${time}`
    );
    return response.data;
  }
);

export const GetDayStatistics = createAsyncThunk("dayStatistics", async () => {
  const response = await Api_Token.get(`statistique/dayStatistique`);
  return response.data;
});

export const GetStatistiqueExcel = createAsyncThunk(
  "export/statistiqueExcel",
  async (data) => {
    const response = await Api_Token.get(
      `statistique/getStatistiqueExcel?dataChoice=${data.dataChoice}&period=${
        data.period
      }${
        data.detailedManifest
          ? `&detailedManifest=${data.detailedManifest}`
          : ""
      }`,
      {
        responseType: "blob", // Ensure the response is handled as a blob
      }
    );

    // Create a URL for the blob and trigger the download
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "statistique.xlsx");
    document.body.appendChild(link);
    link.click();
    link.remove();

    return true;
  }
);

const initialState = {
  loading: false,
  etat: "inisial", // inisial  , success  , error
  message: "",
  StatistiqueGeneral: {},
  status: [],
  chartsale: [],
  Stoke: [],
  dayStatistique: [],
};
export const StatistiqueSlice = createSlice({
  name: "Statistique slice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetStatistiqueGeneral.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetStatistiqueGeneral.fulfilled, (state, action) => {
      state.StatistiqueGeneral = action.payload;
      state.loading = false;
    });
    builder.addCase(GetStatistiqueGeneral.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getStatus.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.status = action.payload;
    });
    builder.addCase(getStatus.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(GetStatistiqueSaleChart.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetStatistiqueSaleChart.fulfilled, (state, action) => {
      state.loading = false;
      state.chartsale = action.payload;
    });
    builder.addCase(GetStatistiqueSaleChart.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(GetStokeStatistics.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetStokeStatistics.fulfilled, (state, action) => {
      state.loading = false;
      state.Stoke = action.payload;
    });
    builder.addCase(GetStokeStatistics.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(GetDayStatistics.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetDayStatistics.fulfilled, (state, action) => {
      state.loading = false;
      state.dayStatistique = action.payload;
    });
    builder.addCase(GetDayStatistics.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default StatistiqueSlice.reducer;
