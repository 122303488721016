import { useEffect, useState } from "react";
import PriceList from "../../components/Products/PriceList";
import TitreGraphik from "../../components/Typography/TitreGraphik";
import { Box, Card, Grid, TextField, InputAdornment } from "@mui/material";
import { styleInput } from "../../assets/style/Input";
import SearchIcon from "@mui/icons-material/Search";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { CircularIndeterminate } from "../../components/loading";
import {
  etatInisialProductSlice,
  GetPricelist,
} from "../../store/slice/ProductSlice";
import ButtonSimple from "../../components/Button/ButtonSimple";
import { Link, useNavigate } from "react-router-dom";
import CustomAlert from "../Alert/Alert";

const PagepriceList = () => {
  const Dispatch = useDispatch();
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(1);
  const { ProductPriceList, infoPriceList, etat, message } = useSelector(
    (state) => state.ProductSlice
  );
  const navigate = useNavigate();
  const HasMore = infoPriceList.current_page < infoPriceList.last_page;
  useEffect(() => {
    const data = {
      params: filter,
      page: page,
    };
    const delayDispatch = setTimeout(() => {
      Dispatch(GetPricelist(data));
    }, 1000);

    return () => clearTimeout(delayDispatch);
  }, [Dispatch, page, filter]);
  return (
    <Box>
      <TitreGraphik>
        {" "}
        <span onClick={() => navigate(-1)}>⬅</span> price list
      </TitreGraphik>
      <br />
      <br />
      <Card sx={{ padding: "3%", display: { xs: "none", md: "flex" } }}>
        <Box width="100%">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={filter}
                  onChange={(e) => {
                    setPage(1);
                    setFilter(e.target.value);
                  }}
                  placeholder="Search by product or category"
                  sx={{ ...styleInput, minWidth: "35%" }}
                />

                <Link to="/admin/newProduct">
                  <ButtonSimple
                    sx={{ ...styleInput, color: "primary", padding: "10px" }}
                  >
                    + New Product
                  </ButtonSimple>
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <InfiniteScroll
                dataLength={ProductPriceList.length}
                next={() => {
                  setPage(page + 1);
                }}
                hasMore={HasMore}
                loader={<CircularIndeterminate />}
              >
                <PriceList Product={ProductPriceList} />
              </InfiniteScroll>
            </Grid>
          </Grid>
        </Box>
      </Card>
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <Box width="100%">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={filter}
                  onChange={(e) => {
                    setPage(1);
                    setFilter(e.target.value);
                  }}
                  placeholder="Search by product or category"
                  fullWidth
                />
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <Link to="/admin/newProduct">
                  <ButtonSimple
                    sx={{
                      ...styleInput,
                      color: "primary",
                      padding: "10px",
                      width: "100%",
                    }}
                  >
                    + New Product
                  </ButtonSimple>
                </Link>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <InfiniteScroll
                dataLength={ProductPriceList.length}
                next={() => {
                  setPage(page + 1);
                }}
                hasMore={HasMore}
                loader={<CircularIndeterminate />}
              >
                <PriceList Product={ProductPriceList} />
              </InfiniteScroll>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <CustomAlert
        open={etat !== "inisial"}
        etat={etat}
        message={message}
        onClose={() => {
          Dispatch(etatInisialProductSlice());
        }}
      />
    </Box>
  );
};

export default PagepriceList;
