import { configureStore } from "@reduxjs/toolkit";
import CategoryPackageSlice from "./slice/CategoryPackageSlice";
import BookingSlice from "./slice/bookingSlice";
import productSlice from "./slice/ProductSlice";
import AuthSlice from "./slice/AuthSlice";
import StyleSlice from "./slice/StyleSlice";
import PortsSlice from "./slice/PortsSlice";
import VoyageSlice from "./slice/VoyageSlice";
import StatistiqueSlice from "./slice/StatistiqueSlice";
import CustomerSlice from "./slice/customerSlice";

const Store = configureStore({
  reducer: {
    AuthSlice: AuthSlice,
    CategoryPackageSlice: CategoryPackageSlice,
    BookingSlice: BookingSlice,
    ProductSlice: productSlice,
    style: StyleSlice,
    PortsSlice: PortsSlice,
    VoyageSlice: VoyageSlice,
    Statistique: StatistiqueSlice,
    CustomerSlice: CustomerSlice,
  },
});

export default Store;
