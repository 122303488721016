import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { GetStokeStatistics } from "../../store/slice/StatistiqueSlice";
import { Box, FormControl, Select, MenuItem } from "@mui/material";
import { styleInput } from "../../assets/style/Input";

const VolumeBreakdown = () => {
  const dispatch = useDispatch();
  const { Stoke } = useSelector((state) => state.Statistique);
  const [time, setTime] = useState("month");

  useEffect(() => {
    dispatch(GetStokeStatistics(time));
  }, [dispatch, time]);

  const calculatePercentages = (data) => {
    const total = data?.reduce((sum, item) => sum + item.total_packages, 0);
    return data?.map((item) => ({
      type: item.type,
      percentage: ((item.total_packages / total) * 100).toFixed(2),
    }));
  };

  const percentages = calculatePercentages(Stoke);
  console.log(percentages);
  const data = {
    labels: percentages?.map((item) => item.type),
    datasets: [
      {
        data: percentages?.map((item) => item.percentage),
        backgroundColor: ["#0671B5", "#023B5F", "#EFF4FB"], // Adjust colors if needed
        hoverBackgroundColor: ["#0671B5", "#023B5F", "#EFF4FB"], // Adjust colors if needed
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          usePointStyle: true, // Use point style for legend items
          pointStyle: "circle", // Shape of the legend item (circle, rectangle, etc.)
          padding: 20, // Padding around legend items
          font: {
            size: 14, // Font size for legend labels
          },
          color: "#333", // Color of legend labels
        },
      },
    },
    tooltip: {
      callbacks: {
        label: function (tooltipItem) {
          return tooltipItem.label + ": " + tooltipItem.raw;
        },
      },
    },
    //F  maintainAspectRatio: false,
    layout: {
      padding: 20,
    },
  };

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <h2>Breakdown of Volume / Transported Volume</h2>
        <FormControl>
          <Select
            labelId="sortlist"
            id="sortList"
            name="sortList"
            sx={styleInput}
            value={time}
            onChange={(e) => {
              setTime(e.target.value);
            }}
          >
            <MenuItem value={"month"}>This month</MenuItem>
            <MenuItem value={"year"}>This year</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Pie data={data} options={options} />
    </Box>
  );
};

export default VolumeBreakdown;
