import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Api_Token } from "../../utils/Api";

export const GetCustomer = createAsyncThunk("get customer", async (data) => {
  let url = `customer/getAll?page=${data.page}`;
  if (data.filter) {
    url += `&filter=${data.filter}`;
  }
  const response = await Api_Token.get(url);
  return response.data;
});

export const SendCustomer = createAsyncThunk("add customer", async (data) => {
  const response = await Api_Token.post(`customer/AddCustomer`, data);
  return response.data;
});

export const EditCustomerAction = createAsyncThunk(
  "edit customer",
  async (data) => {
    const response = await Api_Token.post(`customer/editCustomer`, data);
    return response.data;
  }
);

export const DeleteCustomerAction = createAsyncThunk(
  "delete customer",
  async (id) => {
    const response = await Api_Token.delete(`customer/deleteCustomer/${id}`);
    return response.data;
  }
);

export const GetCustomerInputShipper = createAsyncThunk(
  "get customer input",
  async (data) => {
    let url = `customer/FilterCustomer`;
    if (data.serche) {
      url += `?search=${data.serche}`;
    }
    const response = await Api_Token.get(url);
    return response.data;
  }
);

export const GetCustomerInputRecever = createAsyncThunk(
  "get customer input Receve",
  async (data) => {
    let url = `customer/FilterCustomer`;
    if (data.serche) {
      url += `?search=${data.serche}`;
    }
    const response = await Api_Token.get(url);
    return response.data;
  }
);

export const GetCostumerById = createAsyncThunk(
  "get customer by id ",
  async (id) => {
    const response = await Api_Token.get(`customer/getCostumerById/${id}`);
    return response.data;
  }
);

export const GetCostumerExcel = createAsyncThunk(
  "export customers excel ",
  async (filter) => {
    const response = await Api_Token.get(`customer/export-customers`, {
      params: { search: filter },
      responseType: "blob", // Ensure the response is handled as a blob
    });

    // Create a URL for the blob and trigger the download
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "customers.xlsx");
    document.body.appendChild(link);
    link.click();
    link.remove();

    return true;
  }
);

const initialState = {
  loading: false,
  CustomerData: {},
  CustomerAllData: [],
  etat: "inisial", // inisial  , success  , error
  message: "",
  ShipperOptions: [],
  ReceiverOptions: [],
  loadingShipperOptions: false,
  loadingReceiverOptions: false,
  CostumerById: {},
};

export const CustomerSlice = createSlice({
  name: "CustomerSlice",
  initialState,
  reducers: {
    etatInisialCustomerSlice: (state, action) => {
      state.etat = "inisial";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetCustomer.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetCustomer.fulfilled, (state, action) => {
      state.loading = false;
      state.CustomerData = action.payload;

      if (action.payload.current_page === 1) {
        state.CustomerAllData = action.payload.data;
      } else {
        state.CustomerAllData = [
          ...state.CustomerAllData,
          ...action.payload.data,
        ];
      }
    });
    builder.addCase(GetCustomer.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(SendCustomer.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(SendCustomer.fulfilled, (state, action) => {
      state.loading = false;
      state.etat = "success";
      state.message = action.payload.message;
    });
    builder.addCase(SendCustomer.rejected, (state, action) => {
      state.loading = false;
      state.etat = "error";
      state.message = action.payload.message;
    });

    builder.addCase(EditCustomerAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(EditCustomerAction.fulfilled, (state, action) => {
      state.loading = false;
      state.etat = "success";
      state.message = action.payload.message;
    });
    builder.addCase(EditCustomerAction.rejected, (state, action) => {
      state.loading = false;
      state.etat = "error";
      state.message = action.error.message;
    });
    builder.addCase(DeleteCustomerAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(DeleteCustomerAction.fulfilled, (state, action) => {
      state.loading = false;
      state.etat = "success";
      state.message = action.payload.message;
    });
    builder.addCase(DeleteCustomerAction.rejected, (state, action) => {
      state.loading = false;
      state.etat = "error";
      state.message = action.error.message;
    });
    builder.addCase(GetCustomerInputShipper.pending, (state, action) => {
      state.loadingShipperOptions = true;
    });
    builder.addCase(GetCustomerInputShipper.fulfilled, (state, action) => {
      state.loadingShipperOptions = false;
      state.ShipperOptions = action.payload;
    });
    builder.addCase(GetCustomerInputShipper.rejected, (state, action) => {
      state.loadingShipperOptions = false;
    });
    builder.addCase(GetCustomerInputRecever.pending, (state, action) => {
      state.loadingReceiverOptions = true;
    });
    builder.addCase(GetCustomerInputRecever.fulfilled, (state, action) => {
      state.ReceiverOptions = action.payload;
      state.loadingReceiverOptions = false;
    });
    builder.addCase(GetCustomerInputRecever.rejected, (state, action) => {
      state.loadingReceiverOptions = false;
    });
    builder.addCase(GetCostumerById.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetCostumerById.fulfilled, (state, action) => {
      state.loading = false;
      state.CostumerById = action.payload;
    });
    builder.addCase(GetCostumerById.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default CustomerSlice.reducer;

export const { etatInisialCustomerSlice } = CustomerSlice.actions;
