import { Box } from "@mui/material";
import Indexdasboard from "../../components/dashboard/IndexDashbord";
import PagepriceList from "../../components/Products/PagePriceList";

const PagePriceListAdmin = () => {
  return (
    <Box>
      <Indexdasboard Children={<PagepriceList />} />
    </Box>
  );
};

export default PagePriceListAdmin;
