import LoginImage from "../../assets/Images/imageLogin.jpeg";
import LoginLogoImage from "../../assets/Images/logoLoginImage.png";
import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { Typography } from "@mui/material";
import LabelTypography from "../../components/Typography/LabelTypography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Divider } from "@mui/material";
import ButtonSonRadius from "../../components/Button/buttonSonRadius";
import { send_login, CheckEmailRegulaire } from "../../store/slice/AuthSlice";
import { updateAuthToken } from "../../utils/Api";

// TODO remove, this demo shouldn't need to reset the theme.

function Login() {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const location = useLocation();
  const fromPath = location.state?.form;
  const [emailError, setEmailError] = useState(null);
  const [passwordError, SetPasswordError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    InisialState();
    const data = {
      email: event.target.email.value,
      password: event.target.password.value,
    };
    console.log(!CheckEmailRegulaire(data.email));
    if (!CheckEmailRegulaire(data.email)) {
      setEmailError("Invalid email");
      return;
    }
    if (data.password.length < 8) {
      SetPasswordError("password plus que 8");
    }
    try {
      dispatch(send_login(data)).then((result) => {
        if (send_login.fulfilled.match(result)) {
          updateAuthToken(result.payload.token);
          console.log(fromPath);
          /*   if (fromPath) {
            Navigate(`/${fromPath}`);
          } else {
            Navigate("/dashboard");
          }*/
          Navigate(`/${result.payload.user.Role}/dashboard`);
        }
        if (send_login.rejected.match(result)) {
          SetPasswordError("email or password Incorrect");
        }
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  function InisialState() {
    setEmailError(null);
    SetPasswordError(null);
  }
  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />

      <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            px: { xs: 2, sm: 4, md: 6, lg: 8, xl: 10 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <img
              src={LoginLogoImage}
              height="100px"
              width="auto"
              alt="logo login pbs"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              fontFamily="Graphik"
              fontSize="34px"
              textAlign="right"
              fontWeight="800"
            >
              Log in
            </Typography>
          </Box>

          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 1 }}
          >
            <LabelTypography required={true}>E-mail Address</LabelTypography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              error={emailError == null ? false : true}
              helperText={emailError}
              autoFocus
            />
            <LabelTypography required={true}>Password</LabelTypography>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              error={passwordError == null ? false : true}
              helperText={passwordError}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <ButtonSonRadius
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, height: "auto" }}
            >
              Sign In
            </ButtonSonRadius>
          </Box>
          <Grid container textAlign="center" spacing={2}>
            <Grid item xs={12} textAlign="center">
              <Link
                href="#"
                variant="body2"
                color="primary"
                alignItems="center"
                gutterBottom
              >
                <LabelTypography
                  sx={{ textDecoration: "none", color: "#0671B5" }}
                >
                  Oublié mot de passe ?
                </LabelTypography>
              </Link>
            </Grid>
            <Grid item xs={12} container alignItems="center">
              <Box sx={{ width: "43%", float: "left" }}>
                <Divider />
              </Box>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ mx: 2, whiteSpace: "nowrap" }}
              >
                Ou
              </Typography>
              <Box sx={{ width: "43%", float: "right" }}>
                <Divider />
              </Box>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Don't have an account?
              </Typography>

              <Link href="#" variant="body2" color="primary">
                <LabelTypography
                  sx={{ textDecoration: "none", color: "#0671B5" }}
                >
                  Register
                </LabelTypography>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid
        item
        xs={false}
        sm={4}
        md={6}
        sx={{
          backgroundImage: `url(${LoginImage})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
    </Grid>
  );
}

export default Login;
