import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { StyledTableCell, StyledTableRow } from "../../assets/style/Table";
import { Link } from "react-router-dom";
import useSortPorts from "../../hooks/SortPorts";
import DateFormate from "../date/dateFormat";
import StatusLabel from "../status/status";
import ButtonRadius from "../Button/ButtonRadius";
import { Box, Card, Grid, IconButton } from "@mui/material";
import LabelTypography from "../Typography/LabelTypography";
import { Fragment, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { format } from "date-fns";
import Collapse from "@mui/material/Collapse";
import TableCell from "@mui/material/TableCell";
import { useSelector } from "react-redux";

function VoyageList({ voyages, head, setUdatePopup, setData }) {
  const Portsort = useSortPorts();
  const { user } = useSelector((state) => state.AuthSlice);

  return (
    <Box>
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        <Box width="100%">
          <TableContainer>
            <Table sx={{ minWidth: 700 }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center"></StyledTableCell>
                  {head?.map((h) => (
                    <StyledTableCell
                      align="center"
                      colSpan={h === "Action" ? 2 : 1}
                    >
                      {h}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {voyages?.map((voyage) => {
                  const DataPortSort = Portsort(voyage.ports);
                  return (
                    <RowVoyage
                      voyage={voyage}
                      DataPortSort={DataPortSort}
                      head={head}
                      Role={user.Role}
                      openUpdate={() => {
                        setData(voyage);
                        setUdatePopup(true);
                      }}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <Box sx={{ width: "100%" }}>
          <Grid container spacing={2}>
            {voyages?.map((voyage, voyageIndex) => {
              const DataPortSort = Portsort(voyage.ports);
              return (
                <Grid item xs={12}>
                  <Card
                    key={voyageIndex}
                    variant="outlined"
                    sx={{ maxWidth: 345, padding: "2%", margin: "auto" }}
                  >
                    <LabelTypography variant="body1" color="text.primary">
                      N°Voyage :
                      <Link
                        to={`/${user.Role}/VoyageDetaill/${voyage.id}`}
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <LabelTypography
                          component="span"
                          color="#064C91"
                          sx={{ textAlign: "center" }}
                        >
                          #{voyage.id}
                        </LabelTypography>
                      </Link>
                    </LabelTypography>
                    <LabelTypography variant="body1" color="text.primary">
                      Ship Name:
                      <LabelTypography component="span" color="#064C91">
                        {voyage.ships.nameShips}
                      </LabelTypography>
                    </LabelTypography>
                    <LabelTypography variant="body1" color="text.primary">
                      Captain's name :
                      <LabelTypography component="span" color="#064C91">
                        {" "}
                        {voyage.captain.name}
                      </LabelTypography>
                    </LabelTypography>
                    {head.includes("Route") && (
                      <LabelTypography variant="body1" color="text.primary">
                        Route :
                        <LabelTypography component="span" color="#064C91">
                          {" "}
                          {voyage.Route}
                        </LabelTypography>
                      </LabelTypography>
                    )}
                    {head.includes("Departure Port") && (
                      <LabelTypography variant="body1" color="text.primary">
                        Departure Port :
                        <LabelTypography component="span" color="#064C91">
                          {" "}
                          {DataPortSort[0]?.nomPort}
                        </LabelTypography>
                      </LabelTypography>
                    )}
                    {head.includes("Layovers") && (
                      <LabelTypography variant="body1" color="text.primary">
                        Layovers :
                        <LabelTypography component="span" color="#064C91">
                          {" "}
                          {DataPortSort.slice(1, DataPortSort.length - 1).map(
                            (port, index) => (
                              <span key={index}>
                                {port?.nomPort} :{" "}
                                {format(
                                  new Date(port.pivot.dateArrival),
                                  "dd MMM - HH:mm"
                                )}
                                {index < DataPortSort.length - 2 ? ", " : ""}
                              </span>
                            )
                          )}
                        </LabelTypography>
                      </LabelTypography>
                    )}
                    {head.includes("Arrival Port") && (
                      <LabelTypography variant="body1" color="text.primary">
                        Arrival Port :
                        <LabelTypography component="span" color="#064C91">
                          {" "}
                          {DataPortSort[DataPortSort.length - 1]?.nomPort}
                        </LabelTypography>
                      </LabelTypography>
                    )}

                    {head.includes("Date and time cut-off") && (
                      <LabelTypography variant="body1" color="text.primary">
                        Date and Time cut-off:
                        <LabelTypography component="span" color="#064C91">
                          <DateFormate date={voyage.dateCutof} />
                        </LabelTypography>
                      </LabelTypography>
                    )}
                    {head.includes("Date & time departure") && (
                      <LabelTypography variant="body1" color="text.primary">
                        Date & Time Departure :
                        <LabelTypography component="span" color="#064C91">
                          <DateFormate date={voyage.dateDeparture} />
                        </LabelTypography>
                      </LabelTypography>
                    )}
                    {head.includes("Date and time Arrival") && (
                      <LabelTypography variant="body1" color="text.primary">
                        Date and Time Arrival :
                        <LabelTypography component="span" color="#064C91">
                          <DateFormate date={voyage.dateArrival} />
                        </LabelTypography>
                      </LabelTypography>
                    )}

                    {head.includes("Status") && (
                      <LabelTypography variant="body1" color="text.primary">
                        Status :
                        <LabelTypography component="span" color="#064C91">
                          <StatusLabel status={voyage.status.etat} />
                        </LabelTypography>
                      </LabelTypography>
                    )}
                    {head.includes("Action") && (
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <ButtonRadius
                            sx={{
                              background: "#064C91",
                              color: "white",
                              width: "100%",
                            }}
                          >
                            Manifeste
                          </ButtonRadius>
                        </Grid>
                        <Grid item xs={6}>
                          <ButtonRadius
                            sx={{
                              background: "#064C91",
                              color: "white",
                              width: "100%",
                            }}
                            onClick={() => {
                              setData(voyage);
                              setUdatePopup(true);
                            }}
                          >
                            Edit
                          </ButtonRadius>
                        </Grid>
                      </Grid>
                    )}
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

function RowVoyage({ head, voyage, DataPortSort, Role, openUpdate }) {
  const [open, setOpen] = useState(false);

  return (
    <Fragment sx={{ "& > *": { borderBottom: "unset" } }}>
      <StyledTableRow>
        <StyledTableCell align="center">
          {DataPortSort[2] && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </StyledTableCell>
        <StyledTableCell align="center">
          <Link
            to={`/${Role}/VoyageDetaill/${voyage.id}`}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <LabelTypography sx={{ textAlign: "center", color: "#064C91" }}>
              #{voyage.id}
            </LabelTypography>
          </Link>
        </StyledTableCell>
        <StyledTableCell align="center">
          {voyage.ships.nameShips}
        </StyledTableCell>
        <StyledTableCell align="center">{voyage.captain.name}</StyledTableCell>
        {head.includes("Route") && (
          <StyledTableCell align="center">{voyage.Route}</StyledTableCell>
        )}
        {head.includes("Departure Port") && (
          <StyledTableCell align="center">
            {DataPortSort[0]?.nomPort}
          </StyledTableCell>
        )}
        {head.includes("Layovers") && (
          <StyledTableCell align="center">
            {DataPortSort.slice(1, DataPortSort.length - 1).map(
              (port, index) => (
                <p key={index + 2}>{port?.nomPort}</p>
              )
            )}
          </StyledTableCell>
        )}
        {head.includes("Arrival​ Port") && (
          <StyledTableCell align="center">
            {DataPortSort[DataPortSort.length - 1]?.nomPort}
          </StyledTableCell>
        )}

        {head.includes("Date and time cut-off") && (
          <StyledTableCell align="center" sx={{ minWidth: "150px" }}>
            <DateFormate date={voyage.dateCutof} />
          </StyledTableCell>
        )}
        {head.includes("Date & time departure") && (
          <StyledTableCell align="center" sx={{ minWidth: "150px" }}>
            <DateFormate date={voyage.dateDeparture} />
          </StyledTableCell>
        )}
        {head.includes("Date and time Arrival") && (
          <StyledTableCell align="center" sx={{ minWidth: "150px" }}>
            <DateFormate date={voyage.dateArrival} />
          </StyledTableCell>
        )}

        {head.includes("Status") && (
          <StyledTableCell align="center">
            <StatusLabel status={voyage.status.etat} />
          </StyledTableCell>
        )}
        {head.includes("Action") && (
          <>
            <StyledTableCell align="center">
              <Link
                to={`/${Role}/VoyageManifest/${voyage.id}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <ButtonRadius
                  sx={{
                    background: "#064C91",
                    color: "white",
                    minWidth: "100px",
                  }}
                  onClick={() => {
                    //  openUpdate();
                  }}
                >
                  manifeste
                </ButtonRadius>
              </Link>
            </StyledTableCell>
            <StyledTableCell align="center">
              <ButtonRadius
                sx={{
                  background: "#064C91",
                  color: "white",

                  minWidth: "100px",
                }}
                onClick={() => {
                  openUpdate();
                }}
              >
                edit
              </ButtonRadius>
            </StyledTableCell>
          </>
        )}
      </StyledTableRow>{" "}
      {DataPortSort[1] && (
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>port</StyledTableCell>
                    <StyledTableCell>Date and time Arrival</StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {DataPortSort.filter(
                    (_, index) =>
                      index !== 0 && index !== DataPortSort.length - 1
                  ).map((item, index) => (
                    <TableRow>
                      <TableCell>{item?.nomPort}</TableCell>
                      <TableCell>
                        {format(
                          new Date(item.pivot.dateArrival),
                          "dd MMM - HH:mm"
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      )}
    </Fragment>
  );
}

export default VoyageList;
