import React, { useEffect, useState } from "react";
import { CssBaseline } from "@mui/material";
import { useDispatch } from "react-redux";
import LoadingPage from "./components/loading";
import { checkIsAuth, get_user } from "./store/slice/AuthSlice";
import IndexRouter from "./Router/IndexRouter";

function App() {
  const dispatch = useDispatch();
  const [check, setcheck] = useState(false);

  useEffect(() => {
    dispatch(checkIsAuth());
    dispatch(get_user()).then(() => {
      setcheck(true);
    });
  }, [dispatch]);

  return (
    <div className="App">
      <CssBaseline />
      {check ? (
        <div style={{ minHeight: "100vh", backgroundColor: "#f7f7f7" }}>
          <IndexRouter />
        </div>
      ) : (
        <LoadingPage open={true} />
      )}
    </div>
  );
}

export default App;
