import { Box, Card } from "@mui/material";
import FormProduct from "../../components/Products/FormProduct";
import Indexdasboard from "../../components/dashboard/IndexDashbord";
import TitreGraphik from "../../components/Typography/TitreGraphik";
import { useNavigate } from "react-router-dom";
import ButtonSonRadius from "../../components/Button/buttonSonRadius";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { NewProductAction } from "../../store/slice/ProductSlice";

export default function PageNewProduct() {
  return <Indexdasboard Children={<NewProduct />} />;
}

function NewProduct() {
  const navigate = useNavigate();
  const [categoryId, setcategoryId] = useState(null);
  const dispatch = useDispatch();
  const send = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.append("categoryId", categoryId);
    dispatch(NewProductAction(formData)).then(() => {
      navigate(`/admin/priceList`);
    });
  };
  return (
    <Box>
      <TitreGraphik>
        <span onClick={() => navigate(-1)}>⬅</span>New Product
      </TitreGraphik>
      <form onSubmit={send}>
        <Card sx={{ padding: "2%", marginTop: "2%" }}>
          <FormProduct categoryId={categoryId} setcategoryId={setcategoryId} />
        </Card>
        <ButtonSonRadius
          variant="contained"
          type="submit"
          sx={{
            height: "8vh",
            marginTop: "3%",
          }}
        >
          Add New Product
        </ButtonSonRadius>
      </form>
    </Box>
  );
}
