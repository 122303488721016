import PageAddCustomer from "../pages/all/new/AddCustomer";
import PageBookingManagements from "../pages/all/management/BookingManagements";
import PageCustomerManagement from "../pages/all/management/CustomerManagement";
import PageManifesteDeBord from "../pages/all/ManifesteDeBord";
import PageNewFastBookingcapitan from "../pages/all/new/NewFastBooking";
import DetailVoyagePage from "../pages/all/detaill/Detailvoyage";
import PageDashboardCaptain from "../pages/captain/DashbordCaptain";
import DashboardCommercial from "../pages/commercial/DashboradCommercial";
import PageVoyageManagementCommercial from "../pages/commercial/VoyageManagementCommercial";
import PagePriceListCommercial from "../pages/commercial/PriceListCommercial";

import PageDashboardDock from "../pages/dock/DashbordDock";
import DetailCustomerPage from "../pages/all/detaill/CustomerDetaill";
import DashboardAdmin from "../pages/admin/DashboardAdmin";
import PagePriceListAdmin from "../pages/admin/PriceListAdmin";
import PageNewProduct from "../pages/admin/NewProduct";
import PageNewVoyage from "../pages/admin/NewVoyage";
import VoyageManagements from "../pages/admin/VoyageManagements";
import FleetManagementPage from "../pages/admin/FleetManagement";
import StatistiqueAdmin from "../pages/admin/statistiqueAdmin";
import ExportDataPage from "../pages/admin/ExportDataPage";
import VoyageManifestePage from "../components/Voyage/VoyageManifest";

const PriviteRoute = [
  // captain

  {
    path: "/captain/dashboard",
    element: <PageDashboardCaptain />,
    role: "captain",
  },
  {
    path: "/captain/NewBooking",
    element: <PageNewFastBookingcapitan />,
    role: "captain",
  },
  {
    path: "/captain/ManifesteDeBord",
    element: <PageManifesteDeBord />,
    role: "captain",
  },

  // dock

  {
    path: "/dock/dashboard",
    element: <PageDashboardDock />,
    role: "dock",
  },
  {
    path: "/dock/NewBooking",
    element: <PageNewFastBookingcapitan />,
    role: "dock",
  },

  // commercial

  {
    path: "/commercial/dashboard",
    element: <DashboardCommercial />,
    role: "commercial",
  },
  {
    path: "/commercial/NewBooking",
    element: <PageNewFastBookingcapitan />,
    role: "commercial",
  },
  {
    path: "/commercial/dashboard",
    element: <DashboardCommercial />,
    role: "commercial",
  },
  {
    path: "/commercial/VoyageManagement",
    element: <PageVoyageManagementCommercial />,
    role: "commercial",
  },
  {
    path: "/commercial/CustomerManagement",
    element: <PageCustomerManagement />,
    role: "commercial",
  },
  {
    path: "/commercial/AddCustomer",
    element: <PageAddCustomer />,
    role: "commercial",
  },
  {
    path: "/commercial/BookingsManagements",
    element: <PageBookingManagements />,
    role: "commercial",
  },
  {
    path: "/commercial/priceList",
    element: <PagePriceListCommercial />,
    role: "commercial",
  },
  {
    path: "/commercial/VoyageDetaill/:id",
    element: <DetailVoyagePage />,
    role: "commercial",
  },
  {
    path: "/commercial/customerDetaill/:id",
    element: <DetailCustomerPage />,
    role: "commercial",
  },

  // admin

  {
    path: "/admin/dashboard",
    element: <DashboardAdmin />,
    role: "admin",
  },
  {
    path: "/admin/priceList",
    element: <PagePriceListAdmin />,
    role: "admin",
  },
  {
    path: "/admin/newProduct",
    element: <PageNewProduct />,
    role: "admin",
  },
  {
    path: "/admin/newVoyage",
    element: <PageNewVoyage />,
    role: "admin",
  },
  {
    path: "/admin/VoyageManagement",
    element: <VoyageManagements />,
    role: "admin",
  },
  {
    path: "/admin/CustomerManagement",
    element: <PageCustomerManagement />,
    role: "admin",
  },
  {
    path: "/admin/AddCustomer",
    element: <PageAddCustomer />,
    role: "admin",
  },
  {
    path: "/admin/BookingsManagements",
    element: <PageBookingManagements />,
    role: "admin",
  },
  {
    path: "/admin/NewBooking",
    element: <PageNewFastBookingcapitan />,
    role: "admin",
  },

  {
    path: "/admin/VoyageDetaill/:id",
    element: <DetailVoyagePage />,
    role: "admin",
  },
  {
    path: "/admin/VoyageManifest/:id",
    element: <VoyageManifestePage />,
    role: "admin",
  },
  {
    path: "/admin/customerDetaill/:id",
    element: <DetailCustomerPage />,
    role: "admin",
  },

  {
    path: "/admin/FleetManagement/",
    element: <FleetManagementPage />,
    role: "admin",
  },
  {
    path: "/admin/statistique/",
    element: <StatistiqueAdmin />,
    role: "admin",
  },
  {
    path: "/admin/export/",
    element: <ExportDataPage />,
    role: "admin",
  },
];

export default PriviteRoute;
