import VoyageManagement from "../../components/Voyage/VoyageManagement";
import Indexdasboard from "../../components/dashboard/IndexDashbord";
const head = [
  "N°Voyage",
  "Ship name",
  "Captain's name",
  "Route",
  "Departure Port",
  "Layovers",
  "Arrival​ Port",
  "Status",
  "Action",
];

function PageVoyageManagementAdmin() {
  return (
    <Indexdasboard
      Children={<VoyageManagement NewVoyage={true} head={head} />}
    />
  );
}

export default PageVoyageManagementAdmin;
