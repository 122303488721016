import { Box, Card, Grid } from "@mui/material";
import Indexdasboard from "../../components/dashboard/IndexDashbord";
import NumberGeneral from "../../components/Statistique/NumberGeneral";
import TitreGraphik from "../../components/Typography/TitreGraphik";
import ButtonSimple from "../../components/Button/ButtonSimple";
import VoyageList from "../../components/Voyage/VoyageList";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { GetVoyageDashboard } from "../../store/slice/VoyageSlice";
import LoadingPage from "../../components/loading";
import { styleInput } from "../../assets/style/Input";
import { GetBooingVoyageCustomer } from "../../store/slice/bookingSlice";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
//import LabelTypography from "../../components/Typography/LabelTypography";
import ListBookingVoyageCosutmer from "../../components/Booking/ListBookingVoyageCosutmer";
import { Link } from "react-router-dom";
const head = [
  "N°Voyage",
  "Ship name",
  "Captain's name",
  "Route",
  "Date and time cut-off",
  "Date & time departure",
  "Date and time Arrival",
];

const headBooking = [
  "N° booking",
  "Creation date",
  "Container",
  "Destination",
  "Customer",
  "N° Voyage",
  "Date departure",
  "Date arrival",
  "Status",
  "Action",
];
function DashboardCommercial() {
  return (
    <Box>
      <Indexdasboard Children={<PageDashboard />} />
    </Box>
  );
}

export function PageDashboard() {
  const voyages = useSelector((state) => state.VoyageSlice.voyageDashboard);
  const bookingData = useSelector((state) => state.BookingSlice.bookings);
  const [order, setOrder] = useState("desc");
  const dispatch = useDispatch();
  useEffect(() => {
    const data = {
      sort: "desc",
      number: 5,
    };
    dispatch(GetVoyageDashboard(data));
  }, [dispatch]);

  useEffect(() => {
    const data = {
      order: order,
      number: 5,
    };
    dispatch(GetBooingVoyageCustomer(data));
  }, [dispatch, order]);
  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TitreGraphik>Dashboard</TitreGraphik>
        </Grid>
        <Grid item xs={12}>
          <NumberGeneral />
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ padding: "1%" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <TitreGraphik sx={{ fontSize: { xs: "1rem", md: "1.5rem" } }}>
                voyage List
              </TitreGraphik>
              <Link to="/commercial/VoyageManagement">
                <ButtonSimple
                  sx={{ ...styleInput, color: "primary", padding: "10px" }}
                >
                  view more
                </ButtonSimple>
              </Link>
            </Box>
            <br />
            <VoyageList voyages={voyages} head={head} />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ padding: "1%" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={1}>
                  <Grid item xs={6} md={12}>
                    <TitreGraphik
                      sx={{ fontSize: { xs: "1rem", md: "1.5rem" } }}
                    >
                      Booking list
                    </TitreGraphik>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      textAlign: "left",
                    }}
                  >
                    {/* <LabelTypography id="sortList">sort :{" "}</LabelTypography>*/}
                    <FormControl>
                      <Select
                        labelId="sortlist"
                        id="sortList"
                        name="sortList"
                        sx={{ ...styleInput, minWidth: "300px" }}
                        value={order}
                        onChange={(e) => setOrder(e.target.value)}
                      >
                        <MenuItem value={"desc"}>
                          From newest to oldest
                        </MenuItem>
                        <MenuItem value={"asc"}>From oldest to newest</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid
                  container
                  spacing={2}
                  justifyContent={{ xs: "center", md: "flex-end" }}
                >
                  <Grid
                    item
                    xs={6}
                    md={12}
                    sx={{ textAlign: { xs: "center", md: "right" } }}
                  >
                    <Link to="/commercial/BookingsManagements">
                      <ButtonSimple
                        sx={{
                          ...styleInput,
                          color: "primary",
                          padding: "10px",
                          Height: "8vh",
                          width: { xs: "100%", md: "30%" },
                        }}
                      >
                        view more
                      </ButtonSimple>
                    </Link>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={12}
                    sx={{ textAlign: { xs: "center", md: "right" } }}
                  >
                    <Link to="/commercial/NewBooking">
                      <ButtonSimple
                        sx={{
                          ...styleInput,
                          color: "primary",
                          padding: "10px",
                          Height: "8vh",
                          width: { xs: "100%", md: "30%" },
                        }}
                      >
                        + New booking
                      </ButtonSimple>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <br />
            <ListBookingVoyageCosutmer
              head={headBooking}
              Bookings={bookingData}
            />
          </Card>
        </Grid>
      </Grid>
      <LoadingPage />
    </Box>
  );
}

export default DashboardCommercial;
