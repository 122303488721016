import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Box,
  DialogTitle,
} from "@mui/material";
import ButtonSonRadius from "../Button/buttonSonRadius";
import CancelIcon from "@mui/icons-material/Cancel";

const AfficherImagePackage = ({ open, onClose, image }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton color="error" onClick={onClose}>
              <CancelIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box component="img" src={image} height="300px" />
      </DialogContent>
      <DialogActions>
        <ButtonSonRadius variant="contained" color="error" onClick={onClose}>
          cancel
        </ButtonSonRadius>
      </DialogActions>
    </Dialog>
  );
};

export default AfficherImagePackage;
