import axios from "axios";
import Cookies from "js-cookie";
//const serveurUrl = `http://127.0.0.1:8000`;
const serveurUrl = "https://lagriffe.nusuk.ma";
const api_base = `${serveurUrl}/api/`;

const Api = axios.create({
  baseURL: api_base,
});

const Api_Token = axios.create({
  baseURL: api_base,
  // headers: {
  //    'Content-Type': 'application/json',
  //  }
});

function updateAuthToken(token) {
  Api_Token.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

const authToken = Cookies.get("token");
if (authToken !== undefined) {
  updateAuthToken(authToken);
}
export { api_base, updateAuthToken, Api_Token, Api, serveurUrl };
