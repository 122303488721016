import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import TitreGraphik from "../Typography/TitreGraphik";
import CaptaineForm from "../captain/captaineFrom";
import ButtonSonRadius from "../Button/buttonSonRadius";
import { CheckEmailRegulaire } from "../../pages/all/new/AddCustomer";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { AddCaptaineAction } from "../../store/slice/VoyageSlice";

export default function AddCaptainePopup({ onclose, open, updateData }) {
  const [errorForm, setFormError] = useState({});
  const dispatch = useDispatch();
  function onSubmit(e) {
    e.preventDefault();
    const Data = new FormData(e.target);
    const error = {};

    if (!CheckEmailRegulaire(e.target.Email.value)) {
      error.email = "email invalide";
      setFormError(error);
      return;
    }
    if (e.target.password.value.length < 8) {
      error.password = "Password must be at least 8 characters long";
      setFormError(error);
      return;
    }

    dispatch(AddCaptaineAction(Data)).then((result) => {
      if (AddCaptaineAction.fulfilled.match(result)) {
        setFormError();
        onclose();
      }
    });
  }
  return (
    <Dialog open={open} onClose={onclose} maxWidth="md" fullWidth>
      <form onSubmit={onSubmit}>
        <DialogTitle>
          <TitreGraphik textAling="center">Add Captaine</TitreGraphik>
        </DialogTitle>
        <DialogContent>
          <CaptaineForm formError={errorForm} password={true} />
        </DialogContent>
        <DialogActions>
          <ButtonSonRadius variant="contained" color="error" onClick={onclose}>
            cancel
          </ButtonSonRadius>
          <ButtonSonRadius
            variant="contained"
            sx={{
              backgroundColor: "#064C91",

              "&:hover": {
                backgroundColor: "#0A3D6A",
              },
            }}
            type="submit"
          >
            Add
          </ButtonSonRadius>
        </DialogActions>
      </form>
    </Dialog>
  );
}
