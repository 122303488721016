import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { StyledTableCell, StyledTableRow } from "../../assets/style/Table";
import editIcon from "../../assets/Images/editIcon.svg";
import { Box, Divider, Card, CardContent, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import LabelTypography from "../Typography/LabelTypography";
import ActionBookinStatus from "./ActionBookingStatus";
import { useDispatch } from "react-redux";
import { getStatus } from "../../store/slice/StatistiqueSlice";

function ListBooking({
  booking,
  setId,
  setPaye,
  setPopupOnboard,
  setlivre,
  role,
  head,
}) {
  const dispatch = useDispatch();
  return (
    <Box>
      <TableContainer sx={{ display: { xs: "none", md: "flex" } }}>
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              {head.map((h) => (
                <StyledTableCell align="center">{h}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {booking?.map((b) => {
              const shipper = b?.customer?.find(
                (c) => c.pivot.type === "Shipper"
              );
              // Find the customer of type 'Receiver'
              const receiver = b?.customer?.find(
                (c) => c.pivot.type === "Receiver"
              );

              const destination = b?.port?.find(
                (p) => p.pivot.typePort === "destination"
              );
              return (
                <StyledTableRow key={b.id}>
                  <StyledTableCell align="center">
                    <Link
                      to={`/bookingData/${b.id}`}
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                      }}
                    >
                      <LabelTypography
                        sx={{
                          textAlign: "center",
                          color: "#064C91",
                        }}
                      >
                        #{b.id}
                      </LabelTypography>
                    </Link>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {shipper
                      ? `${shipper.firstName} ${shipper.lastName} `
                      : "No Shipper"}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {receiver
                      ? `${receiver.firstName} ${receiver.lastName} `
                      : "No Receiver"}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {destination ? destination.nomPort : "No destination"}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Link
                      to={`/bookingData/${b.id}`}
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                      }}
                    >
                      <LabelTypography
                        sx={{
                          textAlign: "center",
                          color: "#064C91",
                        }}
                      >
                        #{b.QtyPackage}
                      </LabelTypography>
                    </Link>
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    {b.inContainer === 1 ? "oui" : "non"}
                  </StyledTableCell>

                  <StyledTableCell>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {b.status?.etat}
                      <img
                        src={editIcon}
                        alt="Edit"
                        height="30"
                        style={{ marginLeft: "5px" }}
                        onClick={() => {
                          setId(b.id);
                          dispatch(getStatus("booking"));
                          setPopupOnboard(true);
                        }}
                      />
                    </Box>
                  </StyledTableCell>
                  {role === "captain" && (
                    <>
                      <StyledTableCell align="center">
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {b.Payé}
                          <img
                            src={editIcon}
                            alt="Edit"
                            height="30"
                            style={{ marginLeft: "5px" }}
                            onClick={() => {
                              setId(b.id);
                              setPaye(true);
                            }}
                          />
                        </Box>
                      </StyledTableCell>
                    </>
                  )}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <Box width="100%">
          <Grid container spacing={2}>
            {booking?.map((b) => {
              const shipper = b?.customer?.find(
                (c) => c.pivot.type === "Shipper"
              );
              // Find the customer of type 'Receiver'
              const receiver = b?.customer?.find(
                (c) => c.pivot.type === "Receiver"
              );

              const destination = b?.port?.find(
                (p) => p.pivot.typePort === "destination"
              );

              return (
                <Grid item xs={12}>
                  <Card
                    variant="outlined"
                    sx={{ maxWidth: 345, width: "95%", margin: "auto" }}
                  >
                    <CardContent>
                      <LabelTypography textAlign="center">
                        ID Booking:{" "}
                        <Link
                          to={`/bookingData/${b.id}`}
                          style={{
                            textDecoration: "none",
                            color: "inherit",
                          }}
                        >
                          <LabelTypography
                            sx={{
                              textAlign: "center",
                              color: "#064C91",
                            }}
                          >
                            #{b.id}
                          </LabelTypography>
                        </Link>
                      </LabelTypography>
                      <LabelTypography textAlign="center">
                        Destination:
                        <LabelTypography
                          component="span"
                          style={{ color: "#064C91" }}
                        >
                          {destination ? destination.nomPort : "No destination"}
                        </LabelTypography>
                      </LabelTypography>
                      <Divider sx={{ margin: "10px 0" }} />

                      <LabelTypography color="text.primary">
                        From :
                        <LabelTypography component="span" color="#0671B5">
                          {shipper
                            ? `${shipper.firstName} ${shipper.lastName} `
                            : "No Shipper"}
                        </LabelTypography>
                      </LabelTypography>

                      <LabelTypography color="text.primary">
                        To :
                        <LabelTypography component="span" color="#0671B5">
                          {receiver
                            ? `${receiver.firstName} ${receiver.lastName} `
                            : "No Receiver"}
                        </LabelTypography>
                      </LabelTypography>

                      <Divider sx={{ margin: "10px 0" }} />
                      <LabelTypography color="text.primary">
                        Number of Packages:{" "}
                        <Link
                          to={`/bookingData/${b.id}`}
                          style={{
                            textDecoration: "none",
                            color: "inherit",
                          }}
                        >
                          <LabelTypography
                            sx={{
                              textAlign: "center",
                              color: "#064C91",
                            }}
                          >
                            #{b.QtyPackage}
                          </LabelTypography>
                        </Link>
                      </LabelTypography>
                      <Divider sx={{ margin: "10px 0" }} />
                      <ActionBookinStatus
                        setPopupOnboard={setPopupOnboard}
                        setId={setId}
                        setlivre={setlivre}
                        setPaye={setPaye}
                        b={b}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default ListBooking;
