import Package from "../../../components/Package/Package";
import { Box, Grid, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import ButtonSonRadius from "../../../components/Button/buttonSonRadius";
import {
  NewBookingAction,
  etatInisialBookingSlice,
} from "../../../store/slice/bookingSlice";
import CustomAlert from "../../../components/Alert/Alert";
import TitreGraphik from "../../../components/Typography/TitreGraphik";
import NewFastBookingFormCostumer from "../../../components/CostmureForm/formNewFastBooking";
//import { Getstoke } from "../../../store/slice/bookingSlice";
import LoadingPage from "../../../components/loading";
import Indexdasboard from "../../../components/dashboard/IndexDashbord";
import { useNavigate } from "react-router-dom";
import FormAddNewFastBooking from "../../../components/port/FormAddNewBooking";
import { AddStokeInAction } from "../../../store/slice/bookingSlice";
import AddStokeIn from "../../../components/Popup/AddStokeIn";
import {
  getPorts,
  getVoyagePortCaptain,
} from "../../../store/slice/PortsSlice";
import { useBookingFunctions } from "../../../hooks/useBookingFunctions";
import { NumericFormat } from "react-number-format";
import { FormControl, MenuItem, Select } from "@mui/material";
import LabelTypography from "../../../components/Typography/LabelTypography";
import { listStatusPayment } from "../../../assets/Data";
import { getStatus } from "../../../store/slice/StatistiqueSlice";

const PageNewFastBookingcapitan = () => {
  return <Indexdasboard Children={<NewFastBooking />} />;
};

function NewFastBooking() {
  const {
    packages,
    IndexsFileNull,
    setIndexFileNull,
    AddProductsPackage,
    AddfilePackage,
    DeletefilePackage,
    UpdateQuantite,
    handleRemove,
    UpdateStoke,
    deletePackage,
    addPackage,
    UpdateStokeContainer,
    areAllPackagesTrue,
    PrixTotal,
    calculePrixPack,
  } = useBookingFunctions();
  /*const [packages, setPackages] = useState([
    {
      produits: [],
      file: null,
    },
  ]);*/
  const [errors, setErrors] = useState({});
  //  const [IndexsFileNull, setIndexFileNull] = useState([]);

  const dispatch = useDispatch();
  const { etat, message, loading } = useSelector((state) => state.BookingSlice);
  const { user } = useSelector((state) => state.AuthSlice);
  const { ports } = useSelector((state) => state.PortsSlice);
  const dataStatus = useSelector((state) => state.Statistique.status);
  const Navigate = useNavigate();
  const [onBoard, setOnboard] = useState(false);
  const checkStatusOnBoard = (value) => {
    console.log(value.toLowerCase());
    if (value.toLowerCase() === "on board") {
      setOnboard(true);
    } else {
      setOnboard(false);
    }
  };
  const [openAddStoke, setOpenAddStoke] = useState(false);
  const [shipperInfo, setShipperInfo] = useState({
    id: "",
    firstName: "",
    lastName: "",
    NumberPhone: "",
    Email: "",
    nomCompany: "",
  });
  const [ReceiverInfo, SetReceiverInfo] = useState({
    id: "",
    firstName: "",
    lastName: "",
    NumberPhone: "",
    Email: "",
    nomCompany: "",
  });
  const navigate = useNavigate();
  useEffect(() => {
    if (ports.length === 0) {
      if (user.Role === "captain") {
        dispatch(getVoyagePortCaptain());
      } else {
        dispatch(getPorts());
      }
    }
    dispatch(getStatus("booking"));
  }, [dispatch, ports, user]);

  /*const handleRemove = (indexPack, indexProduct) => {
    setPackages((prev) => {
      const updatedPack = [...prev];
      updatedPack[indexPack].produits.splice(indexProduct, 1);
      return updatedPack;
    });
  };

  const UpdateQuantite = (indexPack, indexProduct, quantity) => {
    if (quantity > 0) {
      setPackages((prev) => {
        const updatedPack = [...prev];
        updatedPack[indexPack].produits[indexProduct] = {
          ...updatedPack[indexPack].produits[indexProduct],
          quantity: quantity,
        };
        return updatedPack;
      });
    }
  };

  const UpdateStoke = (indexPack, stoke) => {
    setPackages((prev) => {
      const updatedPack = [...prev];
      updatedPack[indexPack] = {
        ...updatedPack[indexPack],
        stoke: stoke,
      };
      return updatedPack;
    });
  };

  const AddProductsPackage = (index, products) => {
    setPackages((prev) => {
      const updatedProducts = [...prev];
      const updatedProduits = [...updatedProducts[index].produits, ...products];
      updatedProducts[index] = {
        ...updatedProducts[index],
        produits: updatedProduits,
      };
      return updatedProducts;
    });
  };

  const AddfilePackage = (index, file) => {
    setPackages((prev) => {
      const updatedProducts = [...prev];

      updatedProducts[index] = {
        ...updatedProducts[index],
        file: file,
      };
      return updatedProducts;
    });
  };

    const addPackage = () => {
    setPackages([
      ...packages,
      {
        produits: [],
        file: null,
      },
    ]);
  };

  const deletePackage = (index) => {
    setPackages(packages.filter((_, i) => i !== index));
  };

*/

  const sendData = async (e) => {
    e.preventDefault();
    setIndexFileNull([]);

    // Form customer
    const formData = new FormData(e.target);
    const shipperPhoneNumber = shipperInfo.NumberPhone;
    const ShipperEmail = shipperInfo.Email;
    const receiverPhoneNumber = ReceiverInfo.NumberPhone;
    const ReceiverEmail = ReceiverInfo.Email;

    const errors = {};
    const inContainer = await areAllPackagesTrue();
    if (!isValidPhoneNumber(shipperPhoneNumber)) {
      errors.ShipperPhoneNumber =
        "Numéro invalide. Il doit contenir de 5 à 7 chiffres après l'indicatif +678.";
    }

    if (!isValidPhoneNumber(receiverPhoneNumber)) {
      errors.ReceiverPhoneNumber =
        "Numéro invalide. Il doit contenir de 5 à 7 chiffres après l'indicatif +678.";
    }
    if (ReceiverEmail) {
      if (!isValidEmail(ReceiverEmail)) {
        errors.ReceiverEmail =
          "Invalid email address. Please provide a valid email.";
      }
    }
    if (ShipperEmail) {
      if (!isValidEmail(ShipperEmail)) {
        errors.ShipperEmail =
          "Invalid email address. Please provide a valid email.";
      }
    }

    // Validate packages
    let stopProcess = false; // Initialiser le drapeau pour indiquer si le processus doit être arrêté

    packages.forEach((pack, index) => {
      if (!pack.file) {
        setIndexFileNull((prev) => [...prev, index]);
        stopProcess = true;
        return;
      }
    });

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      stopProcess = true;
    }

    if (stopProcess) {
      return;
    }

    if (IndexsFileNull.length > 0) {
      return;
    }
    //filter pack if n'exist pas un produit
    const dataPackFilter = packages.filter(
      (pack) => pack.produits.length > 0 && pack.file
    );
    formData.append("packages", JSON.stringify(dataPackFilter));
    formData.append("shipper", JSON.stringify(shipperInfo));
    formData.append("receiver", JSON.stringify(ReceiverInfo));
    dataPackFilter.forEach((pack, index) => {
      formData.append(`image${index}`, pack.file);
      formData.append(`prixPack${index}`, calculePrixPack(pack.produits));
    });
    formData.append("prixTotal", PrixTotal);
    formData.append("inContainer", inContainer);
    dispatch(NewBookingAction(formData)).then((result) => {
      if (NewBookingAction.fulfilled.match(result)) {
        if (user.Role === "admin" || user.Role === "commercial") {
          Navigate(`/${user.Role}/BookingsManagements`);
        } else {
          Navigate(`/${user.Role}/dashboard`);
        }
      }
    });
  };

  const isValidPhoneNumber = (phoneNumber) => {
    const vanuatuPhoneRegex = /^\+678\d{5,7}$/;
    return vanuatuPhoneRegex.test(phoneNumber);
  };

  const isValidEmail = (email) => {
    // Regular expression pattern for email validation
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const DispatchAddStokeIn = (data) => {
    dispatch(AddStokeInAction(data));
  };
  console.log(packages);
  return (
    <Box>
      <TitreGraphik>
        <span onClick={() => navigate(-1)}>⬅</span>New fast booking
      </TitreGraphik>
      <Box marginTop="2%">
        <form onSubmit={sendData}>
          <Box sx={{ background: "white", padding: { xs: "3%", md: "5%" } }}>
            <FormAddNewFastBooking />
            <NewFastBookingFormCostumer
              errors={errors}
              ReceiverInfo={ReceiverInfo}
              shipperInfo={shipperInfo}
              SetReceiverInfo={SetReceiverInfo}
              setShipperInfo={setShipperInfo}
            />
            <hr
              style={{
                border: "none",
                height: "1px",
                backgroundColor: "gray",
                paddingLeft: "10%",
                paddingRight: "10%",
                marginTop: "4%",
                marginBottom: "3%",
              }}
            />
            {packages.map((pack, index) => (
              <Package
                key={index}
                pack={pack}
                packages={packages}
                index={index}
                AddProductsPackage={AddProductsPackage}
                AddfilePackage={AddfilePackage}
                DeletefilePackage={DeletefilePackage}
                errorFiles={IndexsFileNull}
                UpdateQuantite={UpdateQuantite}
                handleRemove={handleRemove}
                UpdateStoke={UpdateStoke}
                setOpenAddStoke={setOpenAddStoke}
                deletePackage={deletePackage}
                UpdateStokeContainer={UpdateStokeContainer}
              />
            ))}

            <hr
              style={{
                border: "none",
                height: "1px",
                backgroundColor: "gray",
                paddingLeft: "10%",
                paddingRight: "10%",
                marginTop: "4%",
                marginBottom: "3%",
              }}
            />
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {" "}
                  <ButtonSonRadius
                    variant="contained"
                    sx={{
                      backgroundColor: "#064C91",
                      "&:hover": {
                        backgroundColor: "#0A3D6A", // Change background color on hover
                      },
                    }}
                    onClick={addPackage}
                  >
                    + Delivery docket
                  </ButtonSonRadius>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={onBoard ? 4 : 6}>
                      <LabelTypography>Payment</LabelTypography>
                      <Select name="paye" required fullWidth>
                        {listStatusPayment.map((p) => (
                          <MenuItem value={p}>{p}</MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    {user.Role !== "captain" && (
                      <>
                        <Grid item xs={12} md={onBoard ? 4 : 6}>
                          <LabelTypography>Status</LabelTypography>
                          <FormControl fullWidth>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="status"
                              name="status"
                              required
                            >
                              {dataStatus?.map((s) => (
                                <MenuItem
                                  key={s.id} // Added key for better list rendering
                                  value={s.id}
                                  onClick={() => {
                                    checkStatusOnBoard(s.etat);
                                  }}
                                >
                                  {s.etat}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        {onBoard && (
                          <Grid item xs={12} md={4}>
                            <LabelTypography>N° Voyage</LabelTypography>
                            <TextField
                              type="number"
                              placeholder="N° Voyage"
                              name="idVoyage"
                              required
                              fullWidth
                            />
                          </Grid>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TitreGraphik>
                        Price :{" "}
                        <NumericFormat
                          value={PrixTotal}
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix={" VT"}
                        />
                      </TitreGraphik>
                    </Grid>
                    <Grid item xs={12}>
                      <ButtonSonRadius
                        variant="contained"
                        type="submit"
                        sx={{
                          height: "6vh",
                        }}
                      >
                        Confirm information provided
                      </ButtonSonRadius>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </form>
        <CustomAlert
          open={etat !== "inisial"}
          onClose={() => {
            dispatch(etatInisialBookingSlice());
          }}
          etat={etat}
          message={message}
        />
      </Box>
      <AddStokeIn
        open={openAddStoke}
        onClose={() => setOpenAddStoke(false)}
        Add={(data) => {
          setOpenAddStoke(false);
          DispatchAddStokeIn(data);
        }}
      />
      <LoadingPage open={loading} />
    </Box>
  );
}

export default PageNewFastBookingcapitan;
