import { Box, Card, Grid } from "@mui/material";
import TitreGraphik from "../../components/Typography/TitreGraphik";
import { useNavigate } from "react-router-dom";
import FormVoyage from "../../components/Voyage/FormVoyage";
import Indexdasboard from "../../components/dashboard/IndexDashbord";
import { useVoyageFunction } from "../../hooks/useVoyageFunction";
import ButtonSonRadius from "../../components/Button/buttonSonRadius";
import { useDispatch } from "react-redux";
import { newVoyageSlice } from "../../store/slice/VoyageSlice";
import { useSelector } from "react-redux";
import CustomAlert from "../../components/Alert/Alert";
import { etatInisialVoyageSlice } from "../../store/slice/VoyageSlice";
export default function PageNewVoyage() {
  return <Indexdasboard Children={<NewVoyage />} />;
}

const NewVoyage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { message, etat } = useSelector((state) => state.VoyageSlice);

  const {
    Ports,
    setPorts,
    NewPort,
    PortEdit,
    DateTimeEdit,
    DeletePort,
    setNamePortDestination,
    NamePortDestination,
    setFormErrors,
    FormErrors,
  } = useVoyageFunction();
  console.log(NamePortDestination);
  const sendForm = (e) => {
    const errors = {};
    const now = new Date().toISOString();

    e.preventDefault();
    const formData = new FormData(e.target);
    if (formData.get("cutDate") < now) {
      errors.cutDate = "Cut-off date must be in the future";
    }
    if (formData.get("departureDate") < formData.get("cutDate")) {
      errors.departureDate = "Departure date must be after the cut-off date";
    }
    if (formData.get("arrivalDate") < formData.get("departureDate")) {
      errors.arrivalDate = "Arrival date must be after the departure date";
    }

    Ports.forEach((layover, index) => {
      if (layover.dateTime < formData.get("departureDate")) {
        errors[`layover-${index}`] =
          "Layover date must be after the departure date";
      }
      if (layover.dateTime > formData.get("arrivalDate")) {
        errors[`layover-${index}`] =
          "Layover date must be before the arrival date";
      }
    });
    if (Object.keys(errors).length !== 0) {
      setFormErrors(errors);
      return;
    }

    formData.append("NamePortDestination", NamePortDestination);
    formData.append("ports", JSON.stringify(Ports));
    dispatch(newVoyageSlice(formData)).then(() => {
      navigate("/admin/VoyageManagement");
    });
  };
  return (
    <Box>
      <form onSubmit={sendForm}>
        <Grid container spacing>
          <Grid item xs={12}>
            <TitreGraphik>
              <span onClick={() => navigate(-1)}>⬅</span>New Voyage
            </TitreGraphik>
          </Grid>

          <Grid item xs={12}>
            <Card sx={{ padding: "3%" }}>
              <FormVoyage
                PortsVoyage={Ports}
                setPorts={setPorts}
                NewPort={NewPort}
                PortEdit={PortEdit}
                DateTimeEdit={DateTimeEdit}
                DeletePort={DeletePort}
                setNamePortDestination={setNamePortDestination}
                formErrors={FormErrors}
              />
            </Card>
          </Grid>
          <Grid xs={12}>
            <ButtonSonRadius
              variant="contained"
              type="submit"
              sx={{
                height: "8vh",
                marginTop: "3%",
              }}
            >
              Add New voyage
            </ButtonSonRadius>
          </Grid>
        </Grid>
      </form>
      <CustomAlert
        open={etat !== "inisial"}
        onClose={() => {
          dispatch(etatInisialVoyageSlice());
        }}
        etat={etat}
        message={message}
      />
    </Box>
  );
};
