import { Box, Grid, IconButton } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ButtonSonRadius from "../Button/buttonSonRadius";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
//import { useEffect, useState } from "react";
import { useRef } from "react";
import LabelTypography from "../Typography/LabelTypography";
import DeleteIcon from "@mui/icons-material/Delete";

export default function UploadFile({ setFile, Deletefile, errorFile, file }) {
  //  const [ImageSrc, setImageSrc] = useState();
  const fileInputRef = useRef(null);
  const fileInputTakeRef = useRef(null);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleButtontakeClick = () => {
    fileInputTakeRef.current.click();
  };
  /*
  useEffect(() => {
    if (file) {
      if (file instanceof File) {
        const reader = new FileReader();
        reader.onload = () => {
          setImageSrc(reader.result);
        };
        reader.readAsDataURL(file);
      } else if (typeof file === "string") {
        if (file.startsWith(serveurUrl)) {
          setImageSrc(file);
        } else {
          setImageSrc(serveurUrl + file);
        }
      }
    }
  }, [file]);
*/
  return (
    <Box sx={{ background: "white" }}>
      <Grid container spacing={2} marginTop="1%">
        <Grid item xs={12} md={4}>
          <ButtonSonRadius
            onClick={handleButtonClick}
            startIcon={<FileUploadIcon />}
            variant="contained"
            sx={{
              width: "100%", // Ensures the button takes up the full width of the grid item
              padding: "10px 0",
              backgroundColor: "#064C91",
              "&:hover": {
                backgroundColor: "#0A3D6A", // Change background color on hover
              },
            }}
          >
            upload an image
          </ButtonSonRadius>
        </Grid>
        <Grid item xs={12} md={4}>
          <ButtonSonRadius
            onClick={handleButtontakeClick}
            startIcon={<PhotoCameraIcon />}
            variant="outlined"
            sx={{
              width: "100%", // Ensures the button takes up the full width of the grid item
              padding: "10px 0",
              color: "#064C91",
            }}
          >
            Take a photo
          </ButtonSonRadius>
        </Grid>{" "}
        <Grid item xs={12} md={4}>
          {file && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <LabelTypography>{file.name}</LabelTypography>
              <IconButton
                onClick={() => {
                  Deletefile();
                }}
                aria-label="delete"
              >
                <DeleteIcon />
              </IconButton>
            </div>
          )}
        </Grid>
        <Grid item xs={12}>
          {errorFile && (
            <div style={{ color: "red" }}>Please select a file</div>
          )}
        </Grid>
      </Grid>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }} // Masquer l'input
        accept="image/*"
        onChange={(e) => {
          setFile(e.target.files[0]);
        }}
      />
      <input
        type="file"
        ref={fileInputTakeRef}
        accept="image/*"
        capture="environment" // For capturing photos from the device's camera
        style={{ display: "none" }}
        onChange={(e) => {
          setFile(e.target.files[0]);
        }}
      />
    </Box>
  );
}
