import { Box, Card, Grid } from "@mui/material";
import ListBookingVoyageCosutmer from "../Booking/ListBookingVoyageCosutmer";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GetvoyageManifeste } from "../../store/slice/VoyageSlice";
import Indexdasboard from "../dashboard/IndexDashbord";
import TitreGraphik from "../Typography/TitreGraphik";
const head = [
  "N° booking",
  "Creation date",
  "Container",
  "Destination",
  "Customer",
  "Status",
  "Invoice amount",
];
function VoyageManifestePage() {
  return <Indexdasboard Children={<VoyageManifest />} />;
}
function VoyageManifest() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { booking } = useSelector((state) => state.VoyageSlice.voyageManifeste);
  const BookingFilter = booking?.filter((b) => b.status.etat === "On board");
  useEffect(() => {
    dispatch(GetvoyageManifeste(id));
  }, [dispatch, id]);
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {" "}
          <TitreGraphik>
            <span onClick={() => navigate(-1)}>⬅</span>Voyage Manifeste
          </TitreGraphik>
        </Grid>
        <Grid item xs={12} sx={{ display: { xs: "none", md: "flex" } }}>
          <Card>
            <ListBookingVoyageCosutmer
              head={head}
              Bookings={BookingFilter}
              TotalInvoice={true}
            />
          </Card>
        </Grid>
        <Grid item xs={12} sx={{ display: { xs: "flex", md: "none" } }}>
          <Box>
            <ListBookingVoyageCosutmer
              head={head}
              Bookings={BookingFilter}
              TotalInvoice={true}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default VoyageManifestePage;
