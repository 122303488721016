import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import ButtonSonRadius from "../Button/buttonSonRadius";
import LabelTypography from "../Typography/LabelTypography";
import TitreGraphik from "../Typography/TitreGraphik";
import { useDispatch } from "react-redux";
import { newPortAction } from "../../store/slice/PortsSlice";
import { useState } from "react";

function AddPortPopup({ open, onClose }) {
  const dispatch = useDispatch();
  const [Port, setPort] = useState("");
  const [error, setError] = useState(false);
  function onSubmit() {
    setError(false);
    if (Port === "") {
      setError(true);
    }
    return;
    dispatch(newPortAction({ portName: Port })).then((result) => {
      if (newPortAction.fulfilled.match(result)) {
        onClose();
        setPort("");
        setError(false);
      }
      if (newPortAction.rejected) {
        setError(true);
      }
    });
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle textAlign="center">
        <TitreGraphik sx={{ textAlign: "center" }}>Add Port</TitreGraphik>
      </DialogTitle>
      <DialogContent>
        <LabelTypography required={true}>Name Port</LabelTypography>
        <TextField
          type="text"
          placeholder="name port"
          name="portName"
          value={Port}
          onChange={(e) => setPort(e.target.value)}
          error={error}
        />
      </DialogContent>
      <DialogActions>
        <DialogActions>
          <ButtonSonRadius variant="contained" color="error" onClick={onClose}>
            cancel
          </ButtonSonRadius>
          <ButtonSonRadius
            variant="contained"
            onClick={onSubmit}
            color="primary"
          >
            Add
          </ButtonSonRadius>
        </DialogActions>
      </DialogActions>
    </Dialog>
  );
}

export default AddPortPopup;
