import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Api } from "../../utils/Api";

export const GetAllCategory = createAsyncThunk(
  "get all category package",
  async () => {
    const response = await Api.get("/category_package/getAll");
    return response.data;
  }
);


const initialState = {
  loading: false,
  CategoryPackage: [],
  stokeIn: [],
};

export const CategoryPackageSlice = createSlice({
  name: "Category Package Slice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(GetAllCategory.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetAllCategory.fulfilled, (state, action) => {
      state.CategoryPackage = action.payload;
      state.loading = false;
    });
    builder.addCase(GetAllCategory.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default CategoryPackageSlice.reducer;
