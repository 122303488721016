import { Box, Card } from "@mui/material";
import Indexdasboard from "../../components/dashboard/IndexDashbord";
import LoadingPage from "../../components/loading";
import TitreGraphik from "../../components/Typography/TitreGraphik";
import { useNavigate } from "react-router-dom";
import Package from "../../components/Package/Package";
import { useDispatch, useSelector } from "react-redux";
import { useBookingFunctions } from "../../hooks/useBookingFunctions";
import { useEffect } from "react";
import { serveurUrl } from "../../utils/Api";
import ButtonSonRadius from "../../components/Button/buttonSonRadius";
import { updateContenuBookingAction } from "../../store/slice/bookingSlice";
import { useState } from "react";

export default function EditDataBookingPage() {
  return (
    <Box>
      <Indexdasboard Children={<EditeDataBooking />} />
      <LoadingPage />
    </Box>
  );
}

function EditeDataBooking() {
  const {
    packages,
    setPackages,
    setOpenAddStoke,
    IndexsFileNull,
    setIndexFileNull,
    AddProductsPackage,
    AddfilePackage,
    UpdateQuantite,
    handleRemove,
    UpdateStoke,
    deletePackage,
    addPackage,
    UpdateStokeContainer,
    PrixTotal,
    calculePrixPack,
    areAllPackagesTrue,
    PackageDelete,
  } = useBookingFunctions();
  const navigate = useNavigate();
  const { bookingData } = useSelector((state) => state.BookingSlice);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  useEffect(() => {
    const transformedData = transformData(bookingData.package);
    setPackages(transformedData);
  }, [bookingData, setPackages]);

  async function sendData() {
    let stopProcess = false; // Initialiser le drapeau pour indiquer si le processus doit être arrêté

    packages.forEach((pack, index) => {
      if (!pack.file) {
        setIndexFileNull((prev) => [...prev, index]);
        stopProcess = true;
        return;
      }
    });

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      stopProcess = true;
    }

    if (stopProcess) {
      return;
    }

    if (IndexsFileNull.length > 0) {
      return;
    }
    const formData = new FormData();
    const inContainer = await areAllPackagesTrue();
    const dataPackFilter = packages.filter(
      (pack) => pack.produits.length > 0 && pack.file
    );
    formData.append("packagesDelete", JSON.stringify(PackageDelete));
    formData.append("packages", JSON.stringify(dataPackFilter));
    dataPackFilter.forEach((pack, index) => {
      formData.append(`image${index}`, pack.file);
      formData.append(`prixPack${index}`, calculePrixPack(pack.produits));
    });
    formData.append("prixTotal", PrixTotal);
    formData.append("inContainer", inContainer);
    formData.append("bookingId", bookingData.id);
    dispatch(updateContenuBookingAction(formData)).then((result) => {
      if (updateContenuBookingAction.fulfilled.match(result)) {
        navigate(`/bookingData/${bookingData.id}`);
      }
    });
  }
  return (
    <Box>
      <br />
      <br />
      <TitreGraphik>
        <span onClick={() => navigate(-1)}>⬅</span>Edit Booking Details
      </TitreGraphik>
      <br />
      <br />
      <Box>
        <Card>
          {packages.map((pack, index) => (
            <Package
              key={index}
              pack={pack}
              packages={packages}
              index={index}
              AddProductsPackage={AddProductsPackage}
              AddfilePackage={AddfilePackage}
              errorFiles={IndexsFileNull}
              UpdateQuantite={UpdateQuantite}
              handleRemove={handleRemove}
              UpdateStoke={UpdateStoke}
              setOpenAddStoke={setOpenAddStoke}
              deletePackage={deletePackage}
              UpdateStokeContainer={UpdateStokeContainer}
            />
          ))}
          <Box marginLeft="5%" marginTop="7%">
            <ButtonSonRadius
              marginLeft="3%"
              variant="contained"
              sx={{
                backgroundColor: "#064C91",
                "&:hover": {
                  backgroundColor: "#0A3D6A", // Change background color on hover
                },
              }}
              onClick={addPackage}
            >
              + Delivery docket
            </ButtonSonRadius>
          </Box>

          <hr
            style={{
              border: "none",
              height: "1px",
              backgroundColor: "gray",
              paddingLeft: "10%",
              paddingRight: "10%",
              marginTop: "4%",
              marginBottom: "3%",
            }}
          />
          <TitreGraphik>Price : {PrixTotal} vuv</TitreGraphik>
        </Card>
        <ButtonSonRadius
          variant="contained"
          onClick={sendData}
          sx={{
            height: "8vh",
            marginTop: "3%",
          }}
        >
          Confirm update
        </ButtonSonRadius>
      </Box>
      <LoadingPage />
    </Box>
  );
}

const transformData = (data) => {
  let transformedData = [];
  console.log(data);
  data?.forEach((item) => {
    let produits = [];
    item.products?.forEach((product) => {
      console.log(product);
      produits.push({
        packageProduct: product.pivot.id,
        quantity: product.pivot.NumberProduct,
        product: {
          id: product.id,
          category_id: product.category_id,
          nameProduct: product.nameProduct,
          Size: product.Size,
          UNIT: product.UNIT,
          prixProduct: product.prixProduct,
          created_at: product.created_at,
          updated_at: product.updated_at,
          deleted_at: product.deleted_at,
          nameCategory: product.category.nameCategory,
        },
      });
    });
    transformedData.push({
      id: item.id,
      produits: produits,
      file: `${serveurUrl}/${item.Urlimage}`,
      container: item?.inContainerPack === 1 ? true : false,
      stoke: item?.idStokeIn,
      produitsDelete: [],
    });
  });

  return transformedData;
};
