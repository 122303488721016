import React from "react";
import { Typography } from "@mui/material";

export default function ButtonRadius({ children, sx, ...props }) {
  return (
    <button
      style={{
        display: "inline-block",
        padding: "5px 10px",
        borderRadius: "20px",
        fontWeight: "bold",
        fontSize: "14px",
        ...sx,
      }}
      {...props}
    >
      <Typography
        variant="button"
        sx={{ fontFamily: "Poppins", textTransform: "capitalize" }}
      >
        {children}
      </Typography>
    </button>
  );
}
