export function FormatDate(param) {
  const date = new Date(param);

  const years = date.getFullYear();
  const months = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero-indexed
  const days = ("0" + date.getDate()).slice(-2);
  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);

  return `${years}-${months}-${days} ${hours}:${minutes}`;
}

export function FormatDateSansH(param) {
  const date = new Date(param);
  const years = date.getFullYear();
  const months = ("0" + (date.getMonth() + 1)).slice(-2);
  const days = ("0" + date.getDate()).slice(-2);
  return `${days}/${months}/${years}`;
}
