import { Grid, TextField } from "@mui/material";
import LabelTypography from "../Typography/LabelTypography";
import { useState } from "react";
import { MuiTelInput } from "mui-tel-input";

function CaptaineForm({ data, formError, password }) {
  const [tel, setTel] = useState(data?.Number ? data.Number : "+678");
  return (
    <Grid container spacing={2} sx={{ width: "100%" }}>
      <Grid item xs={12} md={6}>
        <LabelTypography>Full name</LabelTypography>
        <TextField
          type="text"
          placeholder="full name"
          defaultValue={data?.name}
          name="name"
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <LabelTypography>Phone number</LabelTypography>
        <MuiTelInput
          fullWidth
          defaultCountry="ma"
          value={tel}
          onChange={(value) => setTel(value)}
          name="number"
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <LabelTypography>Email</LabelTypography>
        <TextField
          type="text"
          placeholder="Email"
          name="Email"
          defaultValue={data?.email}
          fullWidth
          error={!!formError?.email}
          helperText={formError?.email}
          required
        />
      </Grid>
      {password && (
        <Grid item xs={12} md={6}>
          <LabelTypography>Password</LabelTypography>
          <TextField
            type="password"
            placeholder="Password"
            name="password"
            defaultValue={data?.password}
            fullWidth
            error={!!formError?.password}
            helperText={formError?.password}
            required
          />
        </Grid>
      )}
    </Grid>
  );
}

export default CaptaineForm;
