import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  MenuPhone: false,
};

export const StyleSlice = createSlice({
  name: "styleSlice",
  initialState,
  reducers: {
    MenuToolPhone: (state, action) => {
      state.MenuPhone = !state.MenuPhone;
    },
    MenuToolPhoneFalse: (state, action) => {
      state.MenuPhone = false;
    },
  },
});

export default StyleSlice.reducer;
export const { MenuToolPhone, MenuToolPhoneFalse } = StyleSlice.actions;
