import { Box, Grid, Card } from "@mui/material";
import TitreGraphik from "../Typography/TitreGraphik";
import ButtonTransparent from "../Button/ButtonTransparent";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { StyledTableCell, StyledTableRow } from "../../assets/style/Table";
import LabelTypography from "../Typography/LabelTypography";
import ButtonRadius from "../Button/ButtonRadius";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCapitaine } from "../../store/slice/VoyageSlice";
import AddCaptainePopup from "../Popup/AddCaptaine";
import EditCaptainePopup from "../Popup/EditCaptaine";
import CustomAlert from "../Alert/Alert";
import { etatInisialVoyageSlice } from "../../store/slice/VoyageSlice";

export default function CaptainList() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCapitaine());
  }, [dispatch]);
  const [AddPopup, setAddPopup] = useState(false);
  const [PopupEdit, setPopupEdit] = useState(false);
  const [dataUpdate, setdataUpdate] = useState({});
  const { capitaines, etat, message } = useSelector(
    (state) => state.VoyageSlice
  );
  return (
    <Box>
      <Card sx={{ display: { xs: "none", md: "flex" } }}>
        <Box width="100%" sx={{ padding: "3%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <TitreGraphik>Captains team management</TitreGraphik>
                <ButtonTransparent onClick={() => setAddPopup(true)}>
                  + Add New Captain
                </ButtonTransparent>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <TableContainer>
                <Table sx={{ minWidth: 700 }}>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell align="center">
                        Id Captain
                      </StyledTableCell>
                      <StyledTableCell align="center">Captain</StyledTableCell>
                      <StyledTableCell align="center">
                        Phone Number
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Email address
                      </StyledTableCell>
                      <StyledTableCell align="center">Action</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {capitaines?.map((captain) => (
                      <StyledTableRow>
                        <StyledTableCell align="center">
                          {captain.id}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {captain.name}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {captain.Number}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {captain.email}
                        </StyledTableCell>
                        <StyledTableCell>
                          <ButtonRadius
                            sx={{
                              background: "#064C91",
                              color: "white",
                              //  width: "30%",
                            }}
                            onClick={() => {
                              setPopupEdit(true);
                              setdataUpdate(captain);
                            }}
                          >
                            edit
                          </ButtonRadius>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </Card>
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <Box width="100%">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <TitreGraphik>Captains team management</TitreGraphik>
                <ButtonTransparent onClick={() => setAddPopup(true)}>
                  + Add New Captain
                </ButtonTransparent>
              </Box>
            </Grid>
            {capitaines?.map((captain) => (
              <Grid item xs={12}>
                <Card
                  //  key={voyageIndex}
                  variant="outlined"
                  sx={{ maxWidth: 345, padding: "3%", margin: "auto" }}
                >
                  <LabelTypography variant="body1" color="text.primary">
                    Id Captain
                    <LabelTypography component="span" color="#064C91">
                      <Link> {captain.id}</Link>
                    </LabelTypography>
                  </LabelTypography>
                  <LabelTypography variant="body1" color="text.primary">
                    Captain
                    <LabelTypography component="span" color="#064C91">
                      {captain.name}
                    </LabelTypography>
                  </LabelTypography>
                  <LabelTypography variant="body1" color="text.primary">
                    Phone Number
                    <LabelTypography component="span" color="#064C91">
                      {captain.Number}
                    </LabelTypography>
                  </LabelTypography>
                  <LabelTypography variant="body1" color="text.primary">
                    Email address
                    <LabelTypography component="span" color="#064C91">
                      {captain.email}
                    </LabelTypography>
                  </LabelTypography>

                  <ButtonRadius
                    sx={{
                      background: "#064C91",
                      color: "white",
                      width: "30%",
                    }}
                    onClick={() => {
                      setPopupEdit(true);
                      setdataUpdate(captain);
                    }}
                  >
                    edit
                  </ButtonRadius>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <AddCaptainePopup
        open={AddPopup}
        onclose={() => {
          setAddPopup(false);
        }}
      />

      <EditCaptainePopup
        open={PopupEdit}
        onclose={() => {
          setPopupEdit(false);
        }}
        datacapitan={dataUpdate}
      />
      <CustomAlert
        open={etat !== "inisial"}
        onClose={() => {
          dispatch(etatInisialVoyageSlice());
        }}
        etat={etat}
        message={message}
      />
    </Box>
  );
}
