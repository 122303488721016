import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Box,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Table,
  TableHead,
  TableBody,
} from "@mui/material";

import { styleInput } from "../../assets/style/Input";
import { StyledTableCell, StyledTableRow } from "../../assets/style/Table";
import { useDispatch, useSelector } from "react-redux";
import { getPorts } from "../../store/slice/PortsSlice";
import { GetStatistiqueSaleChart } from "../../store/slice/StatistiqueSlice";

const SalesChart = () => {
  const dispatch = useDispatch();
  const { ports } = useSelector((state) => state.PortsSlice);
  const { chartsale } = useSelector((state) => state.Statistique);
  const [Port, setPort] = useState(1);
  const [time, setTime] = useState("month");
  const portdata = ports?.find((p) => p.id === Port);
  useEffect(() => {
    dispatch(getPorts());
  }, [dispatch]);

  useEffect(() => {
    const data = {
      time: time,
      portId: Port,
    };
    dispatch(GetStatistiqueSaleChart(data));
  }, [dispatch, time, Port]);
  const dataSale = chartsale?.data;
  const labels = dataSale?.map((sale) => {
    const date = new Date(sale.day);

    if (time === "month") {
      return date.getDate().toString();
    } else if (time === "year") {
      return date.toLocaleString("default", { month: "short" });
    }
    return "";
  });
  const dataPort = dataSale?.map((port) => parseFloat(port.total_price));
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Sales",
        data: dataPort,
        fill: false,
        borderColor: "#064C91",
        tension: 0.1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        ticks: {
          display: false, // Hides the y-axis numbers
        },
        grid: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  function calculateGrowthRate(data, base = 100000) {
    return data?.map((value) => ((value - base) / base) * 100);
  }
  const growthRates = calculateGrowthRate(data.datasets[0].data);
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <h2>Sales:{portdata?.nomPort}</h2>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <FormControl>
              <Select
                labelId="sortlist"
                id="sortList"
                name="sortList"
                sx={styleInput}
                value={Port}
                onChange={(e) => {
                  setPort(e.target.value);
                }}
              >
                {ports?.map((port) => (
                  <MenuItem value={port.id}>{port.nomPort}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <Select
                labelId="sortlist"
                id="sortList"
                name="sortList"
                sx={styleInput}
                value={time}
                onChange={(e) => {
                  setTime(e.target.value);
                }}
              >
                <MenuItem value={"month"}>This month</MenuItem>
                <MenuItem value={"year"}>This year</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Line data={data} options={options} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Table>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Month</StyledTableCell>
                <StyledTableCell>Sales</StyledTableCell>
                <StyledTableCell>Growth rate</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {data?.labels?.slice(-3).map((dt, index) => (
                <StyledTableRow>
                  <StyledTableCell>{dt}</StyledTableCell>
                  <StyledTableCell>
                    {data.datasets[0].data[index]}
                  </StyledTableCell>
                  <StyledTableCell>{growthRates[index]}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SalesChart;
