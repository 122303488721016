import {
  Autocomplete,
  Box,
  Grid,
  TextField,
  InputAdornment,
} from "@mui/material";
import LabelTypography from "../Typography/LabelTypography";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetCategory } from "../../store/slice/ProductSlice.jsx";

export default function FormProduct({ setcategoryId, data }) {
  const { Category } = useSelector((state) => state.ProductSlice);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!Category[0]) {
      dispatch(GetCategory());
    }
  }, [dispatch, Category]);

  const handleCategoryChange = (event, value) => {
    if (value) {
      setcategoryId(value.id);
    } else {
      setcategoryId(null);
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <LabelTypography required={true}>category</LabelTypography>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={Category}
            getOptionLabel={(opt) => opt.nameCategory}
            fullWidth
            defaultValue={
              data?.category_id && data?.nameCategory
                ? {
                    category_id: data.category_id,
                    nameCategory: data.nameCategory,
                  }
                : null
            }
            onChange={handleCategoryChange}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="category"
                name="category"
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LabelTypography required={true}>product</LabelTypography>
          <TextField
            type="text"
            name="product"
            placeholder="product"
            fullWidth
            defaultValue={data?.nameProduct}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LabelTypography required={true}>unit</LabelTypography>
          <TextField
            type="text"
            name="unit"
            placeholder="unit"
            fullWidth
            defaultValue={data?.UNIT}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LabelTypography required={true}>price</LabelTypography>
          <TextField
            type="number"
            name="price"
            placeholder="price"
            fullWidth
            required
            defaultValue={data?.prixProduct}
            InputProps={{
              endAdornment: <InputAdornment position="end">vuv</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LabelTypography required={true}>price2</LabelTypography>
          <TextField
            type="number"
            name="price2"
            placeholder="price2"
            fullWidth
            defaultValue={data?.prixProduct2}
            required
            InputProps={{
              endAdornment: <InputAdornment position="end">vuv</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
