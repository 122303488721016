import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Api_Token } from "../../utils/Api";

export const GetVoyageDashboard = createAsyncThunk(
  "get voyage list dashboard",
  async (data) => {
    const response = await Api_Token.get(
      `voyage/getVoyageAndCaptainAndPorts?${
        data.sort ? `sortDir=${data.sort}&` : ""
      }${data.number ? `Number=${data.number}&` : ""}${
        data.statusId ? `statusId=${data.statusId}` : ""
      }`.replace(/&$/, "")
    );
    return response.data;
  }
);

export const GetVoyageManagemntFilter = createAsyncThunk(
  "get voyage list management filter",
  async (data) => {
    const response = await Api_Token.get(
      `voyage/getVoyageAndCaptainAndPorts?${
        data.sort ? `sortDir=${data.sort}&` : ""
      }${data.number ? `Number=${data.number}&` : ""}${
        data.statusId ? `statusId=${data.statusId}` : ""
      }${data.page ? `page=${data.page}&` : ""}`.replace(/&$/, "")
    );
    return response.data;
  }
);

export const GetVoyageById = createAsyncThunk(
  "get voyage by id",
  async (id) => {
    const response = await Api_Token.get(`voyage/voyageById/${id}`);
    return response.data;
  }
);

export const GetShips = createAsyncThunk("get ships", async () => {
  const response = await Api_Token.get(`ships/getShips`);
  return response.data;
});
export const getCapitaine = createAsyncThunk("get capitaine", async () => {
  const response = await Api_Token.get(`capitaine/getcapitaines`);
  return response.data;
});

export const AddCaptaineAction = createAsyncThunk(
  "add capitaine",
  async (data) => {
    const response = await Api_Token.post(`capitaine/addCapitaine`, data);
    return response.data;
  }
);

export const EditCaptaineAction = createAsyncThunk(
  "edit capitaine",
  async (data) => {
    const response = await Api_Token.post(`capitaine/editCapitaine`, data);
    return response.data;
  }
);

export const newVoyageSlice = createAsyncThunk(
  "new voyage",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api_Token.post(`voyage/newVoyage`, data);
      return response.data;
    } catch (error) {
      // Customize the error message if needed
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

export const editVoyageSlice = createAsyncThunk(
  "edit voyage",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api_Token.post(`voyage/editVoyage`, data);
      return response.data;
    } catch (error) {
      // Customize the error message if needed
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

export const NewShipsAction = createAsyncThunk("new ships", async (data) => {
  const response = await Api_Token.post(`ships/newShips`, data);
  return response.data;
});

export const EditShipsAction = createAsyncThunk("edit ships", async (data) => {
  const response = await Api_Token.post(`ships/editShip`, data);
  return response.data;
});

export const GetvoyageManifeste = createAsyncThunk(
  "voyage Manifeste",
  async (id) => {
    const response = await Api_Token.get(`voyage/voyageManifeste/${id}`);
    return response.data;
  }
);

const initialState = {
  loading: false,
  etat: "inisial", // inisial  , success  , error
  message: "",
  voyageDashboard: [],
  voyageManagemnet: [],
  infoScroole: {},
  VoyageById: {},
  voyageManifeste: {},
  ships: [],
  capitaines: [],
};
export const VoyagesSlice = createSlice({
  name: "Voyage slice",
  initialState,
  reducers: {
    etatInisialVoyageSlice: (state, action) => {
      state.etat = "inisial";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetVoyageDashboard.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetVoyageDashboard.fulfilled, (state, action) => {
      state.loading = false;
      state.voyageDashboard = action.payload.data;
    });
    builder.addCase(GetVoyageDashboard.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(GetVoyageManagemntFilter.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetVoyageManagemntFilter.fulfilled, (state, action) => {
      state.loading = false;
      state.infoScroole = action.payload;
      if (action.payload.current_page === 1) {
        state.voyageManagemnet = action.payload.data;
      } else {
        state.voyageManagemnet = [
          ...state.voyageManagemnet,
          ...action.payload.data,
        ];
      }
    });
    builder.addCase(GetVoyageManagemntFilter.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(GetVoyageById.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetVoyageById.fulfilled, (state, action) => {
      state.loading = false;
      state.VoyageById = action.payload;
    });
    builder.addCase(GetVoyageById.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(GetvoyageManifeste.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetvoyageManifeste.fulfilled, (state, action) => {
      state.loading = false;
      state.voyageManifeste = action.payload;
    });
    builder.addCase(GetvoyageManifeste.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(GetShips.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetShips.fulfilled, (state, action) => {
      state.loading = false;
      state.ships = action.payload;
    });
    builder.addCase(GetShips.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getCapitaine.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getCapitaine.fulfilled, (state, action) => {
      state.loading = false;
      state.capitaines = action.payload;
    });
    builder.addCase(getCapitaine.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(NewShipsAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(NewShipsAction.fulfilled, (state, action) => {
      state.etat = "success";
      state.message = action.payload.message;
      state.loading = false;
    });
    builder.addCase(NewShipsAction.rejected, (state, action) => {
      state.etat = "error";
      state.message = action.error.message;
      state.loading = false;
    });
    builder.addCase(EditShipsAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(EditShipsAction.fulfilled, (state, action) => {
      state.etat = "success";
      state.message = action.payload.message;
      state.loading = false;
    });
    builder.addCase(EditShipsAction.rejected, (state, action) => {
      state.etat = "error";
      state.message = action.error.message;
      state.loading = false;
    });
    builder.addCase(newVoyageSlice.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(newVoyageSlice.fulfilled, (state, action) => {
      state.etat = "success";
      state.message = action.payload.message;
      state.loading = false;
    });
    builder.addCase(newVoyageSlice.rejected, (state, action) => {
      state.etat = "error";
      state.message = action.payload;
      state.loading = false;
    });
    builder.addCase(editVoyageSlice.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(editVoyageSlice.fulfilled, (state, action) => {
      state.etat = "success";
      state.message = action.payload.message;
      const index = state.voyageManagemnet.findIndex(
        (c) => Number(c.id) === Number(action.payload.data.id)
      );
      if (index !== -1) {
        state.voyageManagemnet[index] = action.payload.data;
      }
      state.loading = false;
    });
    builder.addCase(editVoyageSlice.rejected, (state, action) => {
      state.etat = "error";
      state.message = action.payload;
      state.loading = false;
    });
    builder.addCase(AddCaptaineAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(AddCaptaineAction.fulfilled, (state, action) => {
      state.etat = "success";
      state.message = action.payload.message;
      state.capitaines = [...state.capitaines, action.payload.user];
      state.loading = false;
    });
    builder.addCase(AddCaptaineAction.rejected, (state, action) => {
      state.etat = "error";
      state.message = action.error.message;
      state.loading = false;
    });

    builder.addCase(EditCaptaineAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(EditCaptaineAction.fulfilled, (state, action) => {
      state.etat = "success";
      state.message = action.payload.message;
      const index = state.capitaines.findIndex(
        (c) => Number(c.id) === Number(action.payload.user.id)
      );
      if (index !== -1) {
        state.capitaines[index] = {
          ...state.capitaines[index],
          ...action.payload.user, // This will update the captain's data with the new data from the payload
        };
      }
      state.loading = false;
    });
    builder.addCase(EditCaptaineAction.rejected, (state, action) => {
      state.etat = "error";
      state.message = action.error.message;
      state.loading = false;
    });
  },
});

export default VoyagesSlice.reducer;
export const { etatInisialVoyageSlice } = VoyagesSlice.actions;
