import { Grid, Box, Card, Typography, Divider } from "@mui/material";
import Indexdasboard from "../../../components/dashboard/IndexDashbord";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetVoyageById } from "../../../store/slice/VoyageSlice";
import TitreGraphik from "../../../components/Typography/TitreGraphik";
import ListBookingVoyageCosutmer from "../../../components/Booking/ListBookingVoyageCosutmer";
import { NumericFormat } from "react-number-format";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LabelTypography from "../../../components/Typography/LabelTypography";
import { FormatDate } from "../../../hooks/FunctionGlobale";
const head = [
  "N° booking",
  "Creation date",
  "Container",
  "Destination",
  "Customer",
  "Status",
  "Invoice amount",
  "Paid",
];
export default function DetailVoyagePage() {
  const { id } = useParams();
  const { VoyageById } = useSelector((state) => state.VoyageSlice);
  const dispatch = useDispatch();
  console.log(VoyageById);
  useEffect(() => {
    if (id) {
      dispatch(GetVoyageById(id));
    }
  }, [id, dispatch]);
  return (
    <Indexdasboard
      Children={<DetailVoyage VoyageData={VoyageById} id={id} />}
    />
  );
}

function DetailVoyage({ VoyageData, id }) {
  const dispatch = useDispatch();
  const [NbBookings, SetNbBookings] = useState(0);
  const [TotalPrix, SetTotalPrix] = useState(0);
  const [TotalPaye, setTotalPaye] = useState(0);
  const [TotalInPaye, setTotatInPaye] = useState(0);
  const totalPorts = VoyageData?.ports?.length || 1;
  const activePortIndex = VoyageData?.ports?.findIndex(
    (port) => port.pivot.active === 1
  );
  console.log(activePortIndex);
  useEffect(() => {
    let nb = 0;
    let prix = 0;
    let prixNoPaye = 0;
    let prixPaye = 0;
    VoyageData?.booking?.map((b) => {
      nb++;
      prix += Number(b.price);
      if (b.paymentMethod === "no") {
        prixNoPaye += Number(b.price);
      } else {
        prixPaye += Number(b.price);
      }
    });
    SetTotalPrix(prix);
    setTotatInPaye(prixNoPaye);
    setTotalPaye(prixPaye);
    SetNbBookings(nb);
  }, [VoyageData]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TitreGraphik>Voyage Information</TitreGraphik>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Card style={{ padding: "20px" }}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm>
                  <Typography variant="subtitle1" gutterBottom>
                    Route
                  </Typography>
                  <Typography variant="h6">{VoyageData?.Route}</Typography>
                </Grid>

                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ marginRight: "3%" }}
                />
                <Grid item xs={12} sm>
                  <Typography variant="subtitle1" gutterBottom>
                    Date Cutoff
                  </Typography>
                  <Typography variant="h6">
                    {FormatDate(VoyageData?.dateCutof)}
                  </Typography>
                </Grid>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ marginRight: "3%" }}
                />
                <Grid item xs={12} sm>
                  <Typography variant="subtitle1" gutterBottom>
                    Date Departure
                  </Typography>
                  <Typography variant="h6">
                    {FormatDate(VoyageData?.dateDeparture)}
                  </Typography>
                </Grid>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ marginRight: "3%" }}
                />
                <Grid item xs={12} sm>
                  <Typography variant="subtitle1" gutterBottom>
                    Date Arrival
                  </Typography>
                  <Typography variant="h6">
                    {FormatDate(VoyageData.dateArrival)}
                  </Typography>
                </Grid>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ marginRight: "3%" }}
                />
                <Grid item xs={12} sm>
                  <Typography variant="subtitle1" gutterBottom>
                    Nb de booking
                  </Typography>
                  <Typography variant="h6">{NbBookings}</Typography>
                </Grid>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ marginRight: "3%" }}
                />
                <Grid item xs={12} sm>
                  <Typography variant="subtitle1" gutterBottom>
                    Total invoice
                  </Typography>
                  <Typography variant="h6">
                    <NumericFormat
                      value={TotalPrix}
                      displayType={"text"}
                      thousandSeparator={true}
                      suffix={" VUV"}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TitreGraphik>Ports Information</TitreGraphik>
        </Grid>
        {/*  <Grid item xs={12}>
       <Box>
            <Card style={{ padding: "20px" }}>
              {VoyageData?.ports?.map((port, index) => (
                <Box key={index} mb={2}>
                  <Typography variant="body1" color="text.primary">
                    Port Name: {port.nomPort}
                  </Typography>
                  <Typography variant="body1" color="text.primary">
                    Port Position: {port.position}
                  </Typography>
                  <Typography variant="body1" color="text.primary">
                    Type: {port.pivot.typePort}
                  </Typography>
                  <Typography variant="body1" color="text.primary">
                    Arrival Date:{" "}
                    {new Date(port.pivot.dateArrival).toLocaleString()}
                  </Typography>
                  <Divider />
                </Box>
              ))}
            </Card>
          </Box>
        </Grid> */}
        <Grid item xs={12}>
          <Card sx={{ padding: "20px" }}>
            <Grid container spacing={2}>
              {VoyageData?.ports?.map((port, index) => {
                return (
                  <>
                    <Grid
                      item
                      xs={3} // Dynamically calculate the grid size
                      container
                      direction="column"
                      alignItems="center"
                    >
                      <Typography variant="h6">{port.nomPort}</Typography>
                      <Typography variant="body2">
                        {port.pivot.typePort === "departure" ? "ETD" : "ETA"}
                      </Typography>
                      <Typography variant="body1">
                        {FormatDate(
                          port.pivot.typePort === "departure"
                            ? port.pivot.dateDeparture
                            : port.pivot.dateArrival
                        )}
                      </Typography>
                      {activePortIndex < index ? (
                        <DirectionsBoatIcon />
                      ) : (
                        <CheckCircleIcon color="success" />
                      )}
                    </Grid>

                    {/* Arrow (except for the last port) */}
                    {index < totalPorts - 1 && (
                      <Grid item xs={1} container justifyContent="center">
                        <ArrowForwardIcon
                          sx={{ fontSize: "2rem", color: "#1976d2" }}
                        />
                      </Grid>
                    )}
                  </>
                );
              })}
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <TitreGraphik>Capitain Information</TitreGraphik>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Card variant="outlined" sx={{ padding: "2%", margin: "auto" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  {" "}
                  <Typography variant="body1" color="text.primary">
                    Captain Name :
                    <Typography component="span" color="#064C91">
                      {VoyageData?.captain?.name}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  {" "}
                  <Typography variant="body1" color="text.primary">
                    Captain Email :
                    <Typography component="span" color="#064C91">
                      {VoyageData?.captain?.email}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography variant="body1" color="text.primary">
                    Captain Phone Number :
                    <Typography component="span" color="#064C91">
                      {VoyageData?.captain?.Number}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <TitreGraphik>Bookings Information</TitreGraphik>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <ListBookingVoyageCosutmer
              head={head}
              Bookings={VoyageData.booking}
              updateList={() => {
                dispatch(GetVoyageById(id));
              }}
            />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ padding: "2%" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <LabelTypography>
                  Total invoiced :
                  <NumericFormat
                    value={TotalPrix}
                    displayType={"text"}
                    thousandSeparator={true}
                    suffix={" VUV"}
                  />
                </LabelTypography>
              </Grid>
              <Grid item xs={12} md={6}>
                <LabelTypography>
                  Total Paid :
                  <NumericFormat
                    value={TotalPaye}
                    displayType={"text"}
                    thousandSeparator={true}
                    suffix={" VUV"}
                  />
                </LabelTypography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
