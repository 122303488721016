import {

  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  TextField,
  Grid,
} from "@mui/material";
import ButtonSonRadius from "../Button/buttonSonRadius";
import TitreGraphik from "../Typography/TitreGraphik";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useSelector } from "react-redux";
import { listStatusPayment } from "../../assets/Data";
import { useState } from "react";

export default function UpdateStatusBooking({
  open,
  onclose,
  title,
  onsend,
  id,
  getDataStatus,
}) {
  const dataStatus = useSelector((state) => state.Statistique.status);
  const [onBoard, setOnboard] = useState(false);
  const checkStatusOnBoard = (value) => {
    console.log(value.toLowerCase());
    if (value.toLowerCase() === "on board") {
      setOnboard(true);
    } else {
      setOnboard(false);
    }
  };
  function SendData(e) {
    e.preventDefault();
    const data = {
      id: id,
      data: e.target.data.value,
    };
    if (onBoard) {
      data.idVoyage = e.target.idVoyage.value;
    }
    onsend(data);
    setOnboard(false);
  }
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOnboard(false);
        onclose();
      }}
    >
      <form onSubmit={SendData}>
        <DialogTitle sx={{ textAlign: "center" }}>
          <TitreGraphik>{title}</TitreGraphik>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{title}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label={title}
                  name="data"
                >
                  {title === "update Payment"
                    ? listStatusPayment?.map((s) => (
                        <MenuItem value={s}>{s}</MenuItem>
                      ))
                    : dataStatus?.map((s) => (
                        <MenuItem
                          value={s.id}
                          onClick={() => {
                            checkStatusOnBoard(s.etat);
                          }}
                        >
                          {s.etat}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>
            {onBoard === true ? (
              <Grid item xs={12}>
                <TextField
                  type="number"
                  placeholder="id voyage"
                  name="idVoyage"
                  required
                  fullWidth
                />
              </Grid>
            ) : null}
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonSonRadius
            variant="contained"
            color="error"
            onClick={() => {
              setOnboard(false);
              onclose();
            }}
          >
            cancel
          </ButtonSonRadius>
          <ButtonSonRadius
            variant="contained"
            sx={{
              backgroundColor: "#064C91",

              "&:hover": {
                backgroundColor: "#0A3D6A", // Change background color on hover
              },
            }}
            type="submit"
          >
            update
          </ButtonSonRadius>
        </DialogActions>
      </form>
    </Dialog>
  );
}
