import { Box, Card, Grid } from "@mui/material";
import TitreGraphik from "../../components/Typography/TitreGraphik";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import LabelTypography from "../../components/Typography/LabelTypography";
import { styleInput } from "../../assets/style/Input";
import { useEffect, useState } from "react";
import ButtonSimple from "../../components/Button/ButtonSimple";
import { useDispatch, useSelector } from "react-redux";
import { getStatus } from "../../store/slice/StatistiqueSlice";
import VoyageList from "./VoyageList";
import { GetVoyageManagemntFilter } from "../../store/slice/VoyageSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import { CircularIndeterminate } from "../../components/loading";
import { Link, useNavigate } from "react-router-dom";
import CustomAlert from "../Alert/Alert";
import { etatInisialVoyageSlice } from "../../store/slice/VoyageSlice";
import UpdateVoyagePopup from "../Popup/UpdateVoyage";
function VoyageManagement({ NewVoyage, head }) {
  const dispatch = useDispatch();
  const { status } = useSelector((state) => state.Statistique);
  const { voyageManagemnet, infoScroole, message } = useSelector(
    (state) => state.VoyageSlice
  );
  const etatslice = useSelector((state) => state.VoyageSlice.etat);
  const navigate = useNavigate();
  const HasMore = infoScroole.current_page < infoScroole.last_page;
  const [Page, setPage] = useState(1);
  const [order, setOrder] = useState("desc");
  const [etat, setEtat] = useState("");
  const [updatePopup, setUdatePopup] = useState(false);
  const [data, setData] = useState({});
  useEffect(() => {
    dispatch(getStatus("voyage"));
  }, [dispatch]);

  useEffect(() => {
    const data = {
      sort: order,
      statusId: etat,
      page: Page,
    };
    dispatch(GetVoyageManagemntFilter(data));
  }, [dispatch, order, etat, Page]);
  console.log(NewVoyage);
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TitreGraphik>
            {" "}
            <span onClick={() => navigate(-1)}>⬅</span>Voyage management
          </TitreGraphik>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Card sx={{ padding: "1%", width: "100%" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  {NewVoyage && (
                    <Link to="/admin/newVoyage">
                      <ButtonSimple
                        variant="contained"
                        sx={{ background: "#064C91" }}
                      >
                        + Create a new voyage
                      </ButtonSimple>
                    </Link>
                  )}
                </Grid>
                <Grid item xs={6} md={3}>
                  <Box>
                    <Grid container>
                      <Grid
                        item
                        xs={3}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <LabelTypography id="sortList">sort</LabelTypography>
                      </Grid>
                      <Grid item xs={9}>
                        <FormControl fullWidth>
                          <Select
                            labelId="sortlist"
                            id="sortList"
                            name="sortList"
                            sx={styleInput}
                            value={order}
                            onChange={(e) => {
                              setOrder(e.target.value);
                              setPage(1);
                            }}
                          >
                            <MenuItem value={"desc"}>
                              From newest to oldest
                            </MenuItem>
                            <MenuItem value={"asc"}>
                              From oldest to newest
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Box>
                    <Grid container>
                      <Grid
                        item
                        xs={3}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <LabelTypography id="sortList">Filter</LabelTypography>
                      </Grid>
                      <Grid item xs={9}>
                        <FormControl fullWidth>
                          <Select
                            labelId="sortlist"
                            id="sortList"
                            name="sortList"
                            sx={styleInput}
                            value={etat}
                            onChange={(e) => {
                              setEtat(e.target.value);
                              setPage(1);
                            }}
                          >
                            {status.map((s) => (
                              <MenuItem value={s.id}>{s.etat}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <InfiniteScroll
                    dataLength={voyageManagemnet.length}
                    next={() => {
                      setPage(Page + 1);
                    }}
                    hasMore={HasMore}
                    loader={<CircularIndeterminate />}
                  >
                    <VoyageList
                      voyages={voyageManagemnet}
                      head={head}
                      setUdatePopup={setUdatePopup}
                      setData={setData}
                    />
                  </InfiniteScroll>
                </Grid>
              </Grid>
            </Card>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={3}>
                <Box>
                  <Grid container>
                    <Grid
                      item
                      xs={3}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <LabelTypography id="sortList">sort</LabelTypography>
                    </Grid>
                    <Grid item xs={9}>
                      <FormControl fullWidth>
                        <Select
                          labelId="sortlist"
                          id="sortList"
                          name="sortList"
                          sx={styleInput}
                          value={order}
                          onChange={(e) => setOrder(e.target.value)}
                        >
                          <MenuItem value={"desc"}>
                            From newest to oldest
                          </MenuItem>
                          <MenuItem value={"asc"}>
                            From oldest to newest
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <Box>
                  <Grid container>
                    <Grid
                      item
                      xs={3}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <LabelTypography id="sortList">Filter</LabelTypography>
                    </Grid>
                    <Grid item xs={9}>
                      <FormControl fullWidth>
                        <Select
                          labelId="sortlist"
                          id="sortList"
                          name="sortList"
                          sx={styleInput}
                          value={etat}
                          onChange={(e) => setEtat(e.target.value)}
                        >
                          {status.map((s) => (
                            <MenuItem value={s.id}>{s.etat}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>{" "}
              <Grid item xs={12}>
                {NewVoyage && (
                  <Link to="/admin/newVoyage">
                    <ButtonSimple
                      variant="contained"
                      sx={{ background: "#064C91" }}
                    >
                      + Create a new voyage
                    </ButtonSimple>
                  </Link>
                )}
              </Grid>
              <Grid item xs={12}>
                <InfiniteScroll
                  dataLength={voyageManagemnet.length}
                  next={() => {
                    setPage(Page + 1);
                  }}
                  hasMore={HasMore}
                  loader={<CircularIndeterminate />}
                >
                  <VoyageList
                    voyages={voyageManagemnet}
                    head={head}
                    setData={setData}
                    setUdatePopup={setUdatePopup}
                  />
                </InfiniteScroll>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <UpdateVoyagePopup
        open={updatePopup}
        onclose={() => {
          setUdatePopup(false);
        }}
        data={data}
      />
      <CustomAlert
        open={etatslice !== "inisial"}
        onClose={() => {
          dispatch(etatInisialVoyageSlice());
        }}
        etat={etatslice}
        message={message}
      />
    </Box>
  );
}

export default VoyageManagement;
