import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  DialogTitle,
  Box,
} from "@mui/material";

import TitreGraphik from "../Typography/TitreGraphik";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch } from "react-redux";

import ButtonSonRadius from "../Button/buttonSonRadius";
import FromVoyage from "../Voyage/FormVoyage";
import { useVoyageFunction } from "../../hooks/useVoyageFunction";

import { editVoyageSlice } from "../../store/slice/VoyageSlice";

export default function UpdateVoyagePopup({ open, onclose, data }) {
  const {
    Ports,
    setPorts,
    NewPort,
    PortEdit,
    DateTimeEdit,
    DeletePort,
    setNamePortDestination,
    setFormErrors,
    FormErrors,
    PortsDelete,
    NamePortDestination,
  } = useVoyageFunction();
  const dispatch = useDispatch();
  console.log(data);
  function Send(e) {
    e.preventDefault();
    const formData = new FormData(e.target);

    const errors = {};

    if (formData.get("departureDate") < formData.get("cutDate")) {
      errors.departureDate = "Departure date must be after the cut-off date";
    }
    if (formData.get("arrivalDate") < formData.get("departureDate")) {
      errors.arrivalDate = "Arrival date must be after the departure date";
    }

    Ports.forEach((layover, index) => {
      if (layover.dateTime < formData.get("departureDate")) {
        errors[`layover-${index}`] =
          "Layover date must be after the departure date";
      }
      /*  if (layover.dateTime > formData.get("arrivalDate")) {
        errors[`layover-${index}`] =
          "Layover date must be before the arrival date";
      }*/
    });
    if (Object.keys(errors).length !== 0) {
      setFormErrors(errors);
      return;
    }
    formData.append("id", data.id);
    formData.append("NamePortDestination", NamePortDestination);
    formData.append("ports", JSON.stringify(Ports));
    formData.append("portsDelete", JSON.stringify(PortsDelete));
    dispatch(editVoyageSlice(formData)).then((result) => {
      if (editVoyageSlice.fulfilled.match(result)) {
        setFormErrors({});
        onclose();
      }
    });
  }
  return (
    <Dialog
      open={open}
      onClose={() => {
        onclose();
      }}
      maxWidth="md"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          height: "80vh",
        },
      }}
    >
      <DialogTitle>
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TitreGraphik>update voyage</TitreGraphik>
          <IconButton color="error" onClick={onclose}>
            <CancelIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <form onSubmit={Send}>
        <DialogContent>
          <FromVoyage
            PortsVoyage={Ports}
            setPorts={setPorts}
            NewPort={NewPort}
            PortEdit={PortEdit}
            DateTimeEdit={DateTimeEdit}
            DeletePort={DeletePort}
            setNamePortDestination={setNamePortDestination}
            formErrors={FormErrors}
            data={data}
          />
        </DialogContent>
        <DialogActions>
          <ButtonSonRadius variant="contained" color="error" onClick={onclose}>
            cancel
          </ButtonSonRadius>
          <ButtonSonRadius
            variant="contained"
            type="submit"
            sx={{
              backgroundColor: "#064C91",

              "&:hover": {
                backgroundColor: "#0A3D6A", // Change background color on hover
              },
            }}
            //  onClick={sendProduct}
          >
            update
          </ButtonSonRadius>
        </DialogActions>
      </form>
    </Dialog>
  );
}
