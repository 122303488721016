import {
  Grid,
  Box,
  Card,
  Typography,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Indexdasboard from "../../../components/dashboard/IndexDashbord";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import TitreGraphik from "../../../components/Typography/TitreGraphik";
import ListBookingVoyageCosutmer from "../../../components/Booking/ListBookingVoyageCosutmer";

import { GetCostumerById } from "../../../store/slice/customerSlice";
const head = [
  "N° booking",
  "Creation date",
  "Container",
  "Destination",
  "N° Voyage",
  "Status",
  "Invoice amount",
];
export default function DetailCustomerPage() {
  const { id } = useParams();
  const { CostumerById } = useSelector((state) => state.CustomerSlice);
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(GetCostumerById(id));
    }
  }, [id, dispatch]);
  return (
    <Indexdasboard
      Children={<DetailCustomer CustomerData={CostumerById} id={id} />}
    />
  );
}

function DetailCustomer({ CustomerData, id }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { firstName, lastName, Email, NumberPhone, adress, company } =
    CustomerData;
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TitreGraphik>Customer Information</TitreGraphik>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Card style={{ padding: "20px" }}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm>
                  <Typography variant="subtitle1" gutterBottom>
                    Customer
                  </Typography>
                  <Typography variant="h6">
                    {firstName} {lastName}
                  </Typography>
                </Grid>
                {!isMobile && (
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ marginRight: "3%" }}
                  />
                )}
                <Grid item xs={12} sm>
                  <Typography variant="subtitle1" gutterBottom>
                    Company
                  </Typography>
                  <Typography variant="h6">{company?.nomCompany}</Typography>
                </Grid>
                {!isMobile && (
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ marginRight: "3%" }}
                  />
                )}
                <Grid item xs={12} sm>
                  <Typography variant="subtitle1" gutterBottom>
                    Address
                  </Typography>
                  <Typography variant="h6">{adress}</Typography>
                </Grid>
                {!isMobile && (
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ marginRight: "3%" }}
                  />
                )}
                <Grid item xs={12} sm>
                  <Typography variant="subtitle1" gutterBottom>
                    Email
                  </Typography>
                  <Typography variant="h6">{Email}</Typography>
                </Grid>
                {!isMobile && (
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ marginRight: "3%" }}
                  />
                )}
                <Grid item xs={12} sm>
                  <Typography variant="subtitle1" gutterBottom>
                    Phone number
                  </Typography>
                  <Typography variant="h6">{NumberPhone}</Typography>
                </Grid>
              </Grid>
            </Card>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <TitreGraphik>Bookings Information</TitreGraphik>
        </Grid>
        <Grid item xs={12}>
          <ListBookingVoyageCosutmer
            head={head}
            Bookings={CustomerData.bookings}
            updateList={() => {
              //dispatch(GetVoyageById(id));
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
