import { useState } from "react";

export function useVoyageFunction() {
  const [Ports, setPorts] = useState([{ port: null, dateTime: null }]);
  const [NamePortDestination, setNamePortDestination] = useState();
  const [PortsDelete, setPortsDelete] = useState([]);
  const [FormErrors, setFormErrors] = useState({});

  function NewPort() {
    console.log("Type of Ports:", typeof Ports);
    console.log("Is Ports an array?", Array.isArray(Ports));
    console.log("Ports:", Ports);

    setPorts((prev) =>
      Array.isArray(prev)
        ? [...prev, { port: null, dateTime: null }]
        : [{ port: null, dateTime: null }]
    );
  }

  function PortEdit(index, data) {
    setPorts((prev) => {
      const newPorts = [...prev];
      newPorts[index] = { ...newPorts[index], port: data };
      return newPorts;
    });
  }

  function DateTimeEdit(index, data) {
    setPorts((prev) => {
      const newPorts = [...prev];
      newPorts[index] = { ...newPorts[index], dateTime: data };
      return newPorts;
    });
  }

  function DeletePort(index) {
    setPorts((prev) => {
      const newPorts = prev.filter((_, indexPort) => indexPort !== index);
      setPortsDelete((prevDeleted) => {
        const newPort = prev[index].port;
        if (!prevDeleted.includes(newPort)) {
          return [...prevDeleted, newPort];
        }
        return prevDeleted;
      });
      return newPorts;
    });
  }

  return {
    Ports,
    setPorts,
    NewPort,
    PortEdit,
    DateTimeEdit,
    DeletePort,
    setNamePortDestination,
    NamePortDestination,
    setFormErrors,
    FormErrors,
    PortsDelete,
  };
}
