import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import TitreGraphik from "../Typography/TitreGraphik";
import FormProduct from "../Products/FormProduct";
import ButtonSonRadius from "../Button/buttonSonRadius";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  DeleteProductAction,
  UpdateProductAction,
} from "../../store/slice/ProductSlice";
export default function EditProduct({ open, onclose, data }) {
  const [categoryId, setCategoryId] = useState(null);
  useEffect(() => {
    setCategoryId(data?.category_id);
  }, [data]);
  const dispatch = useDispatch();
  function onSubmit(e) {
    e.preventDefault();
    console.log(categoryId);
    const dataForm = new FormData(e.target);
    dataForm.append("categoryId", categoryId);
    dataForm.append("id", data.id);
    dispatch(UpdateProductAction(dataForm)).then((result) => {
      if (UpdateProductAction.fulfilled.match(result)) {
        onclose();
      }
    });
  }

  function deleteProduct() {
    dispatch(DeleteProductAction(data.id)).then((result) => {
      if (DeleteProductAction.fulfilled.match(result)) {
        onclose();
      }
    });
  }
  return (
    <Dialog open={open} onClose={onclose} maxWidth="md" fullWidth>
      <DialogTitle>
        <TitreGraphik textAlign="center">Update product</TitreGraphik>
      </DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <FormProduct setcategoryId={setCategoryId} data={data} />
        </DialogContent>
        <DialogActions>
          <ButtonSonRadius variant="contained" color="error" onClick={onclose}>
            cancel
          </ButtonSonRadius>
          <ButtonSonRadius
            variant="contained"
            color="error"
            onClick={() => {
              deleteProduct();
            }}
          >
            delete
          </ButtonSonRadius>
          <ButtonSonRadius variant="contained" type="submit" color="primary">
            update
          </ButtonSonRadius>
        </DialogActions>
      </form>
    </Dialog>
  );
}
