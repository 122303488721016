import {
  Card,
  Dialog,
  Grid,
  Box,
  DialogContent,
  DialogActions,
} from "@mui/material";
import FormCustomer from "../customer/FormCustomer";
import ButtonSonRadius from "../Button/buttonSonRadius";
import TitreGraphik from "../Typography/TitreGraphik";
import { useDispatch } from "react-redux";
import {
  DeleteCustomerAction,
  EditCustomerAction,
} from "../../store/slice/customerSlice";
import { CheckEmailRegulaire } from "../../pages/all/new/AddCustomer";
import { useState } from "react";

export default function EditCustomer({ open, onClose, data, UpdateData }) {
  const dispatch = useDispatch();
  const [errorForm, setFormError] = useState({});
  function sendForm(e) {
    e.preventDefault();
    const Data = new FormData(e.target);
    Data.append("id", data.id);
    const error = {};
    if (e.target.Email.value) {
      if (!CheckEmailRegulaire(e.target.Email.value)) {
        console.log(CheckEmailRegulaire(e.target.Email.value));
        error.email = "email invalide";
        setFormError(error);
        return;
      }
    }
    dispatch(EditCustomerAction(Data)).then((result) => {
      if (EditCustomerAction.fulfilled.match(result)) {
        UpdateData();
      }
    });
    onClose();
  }

  function deleteCustomer() {
    dispatch(DeleteCustomerAction(data.id)).then((result) => {
      if (DeleteCustomerAction.fulfilled.match(result)) {
        UpdateData();
      }
    });
    onClose();
  }
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Card sx={{ padding: "3%" }}>
        <form onSubmit={sendForm}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TitreGraphik>
                <span onClick={onClose}>⬅</span> Edit customer
              </TitreGraphik>
            </Grid>
            <DialogContent
              dividers={true}
              sx={{ maxHeight: "60vh", overflow: "auto" }}
            >
              <Grid item xs={12}>
                <FormCustomer data={data} formError={errorForm} />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid item xs={12}>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <ButtonSonRadius
                    variant="contained"
                    type="submit"
                    sx={{
                      height: "6vh",
                      marginTop: "3%",
                      width: "30vh",
                    }}
                  >
                    Save
                  </ButtonSonRadius>
                  <ButtonSonRadius
                    variant="contained"
                    color="error"
                    onClick={deleteCustomer}
                    sx={{
                      height: "6vh",
                      marginTop: "3%",
                      marginLeft: "1%",
                      width: "30vh",
                    }}
                  >
                    delete
                  </ButtonSonRadius>
                </Box>
              </Grid>
            </DialogActions>
          </Grid>
        </form>
      </Card>
    </Dialog>
  );
}
