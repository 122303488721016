import {
  TableRow,
  TableCell,
  Button,
  TextField,
  Box,
  Grid,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import TitreGraphik from "../Typography/TitreGraphik";
import LabelTypography from "../Typography/LabelTypography";
import ButtonTransparent from "../Button/ButtonTransparent";
import { NumericFormat } from "react-number-format";

export default function ProductPackage({
  index,
  product,
  handleRemove,
  UpdateQuantite,
}) {
  console.log(product);
  return (
    <TableRow>
      <TableCell align="center">{product.product?.nameProduct}</TableCell>
      <TableCell align="center">{product.product.nameCategory}</TableCell>
      <TableCell align="center">
        <TextField
          value={product.quantity}
          type="number"
          min={1}
          onChange={(e) => {
            UpdateQuantite(e.target.value);
          }}
        />
      </TableCell>
      <TableCell align="center">
        {" "}
        <NumericFormat
          value={product.product.prixProduct}
          displayType={"text"}
          thousandSeparator={true}
          suffix={" VUV"}
        />
      </TableCell>
      <TableCell align="center">
        <NumericFormat
          value={product.product.prixProduct * product.quantity}
          displayType={"text"}
          thousandSeparator={true}
          suffix={" VUV"}
        />
      </TableCell>
      <TableCell align="center">
        <div>
          <Button
            onClick={() => handleRemove(index)}
            aria-label="delete"
            padding="0"
            color="error"
            variant="contained"
          >
            <DeleteIcon />
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
}

export function ProductPackageResponsive({
  index,
  indexProduit,
  product,
  handleRemove,
  UpdateQuantite,
}) {
  console.log(product);
  return (
    <Box sx={{ display: { xs: "flex", md: "none" } }}>
      <Box width="100%" marginLeft="3%">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TitreGraphik>Produit {indexProduit + 1}</TitreGraphik>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="column">
              <LabelTypography>Name</LabelTypography>
              <TextField value={product.product?.nameProduct} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="column">
              <LabelTypography>Category</LabelTypography>
              <TextField value={product.product.nameCategory} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="column">
              <LabelTypography>Category</LabelTypography>
              <TextField
                value={product.quantity}
                type="number"
                min={1}
                onChange={(e) => {
                  UpdateQuantite(e.target.value);
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="column">
              <LabelTypography>Unit price</LabelTypography>
              <TextField value={`${product.product.prixProduct} vuv`} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="column">
              <LabelTypography>Price Total</LabelTypography>
              <TextField
                value={`${product.product.prixProduct * product.quantity} vuv`}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <ButtonTransparent
              startIcon={<DeleteIcon />}
              onClick={() => handleRemove(index)}
              aria-label="delete"
              padding="0"
              color="error"
              sx={{
                backgroundColor: "white",
                "&:hover": {
                  backgroundColor: "white",
                },
              }}
            >
              Delete
            </ButtonTransparent>
          </Grid>
          <Grid item xs={12}>
            <hr
              style={{
                borderTop: "1px dashed ",
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
