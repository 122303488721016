import { Box, Grid, Card } from "@mui/material";
import TitreGraphik from "../Typography/TitreGraphik";
import ButtonTransparent from "../Button/ButtonTransparent";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { StyledTableCell, StyledTableRow } from "../../assets/style/Table";
import LabelTypography from "../Typography/LabelTypography";
import ButtonRadius from "../Button/ButtonRadius";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EditShipsAction,
  GetShips,
  NewShipsAction,
} from "../../store/slice/VoyageSlice";
import AddShips from "../Popup/AddShips";
import UpdateShips from "../Popup/UpdateShips";

export default function ListShips() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetShips());
  }, [dispatch]);
  const { ships } = useSelector((state) => state.VoyageSlice);
  const [addOpen, setAddOpen] = useState(false);
  const [UpdateOpen, setUpdateOpen] = useState(false);
  const [UpdateData, setUpdateData] = useState(false);

  function Addsend(e) {
    e.preventDefault();
    const data = {
      name: e.target.ships.value,
    };
    dispatch(NewShipsAction(data)).then(() => {
      if (NewShipsAction.fulfilled) {
        dispatch(GetShips());
      }
      setAddOpen(false);
    });
  }

  function editsend(e) {
    e.preventDefault();
    const data = {
      id: UpdateData.id,
      name: e.target.ships.value,
    };
    dispatch(EditShipsAction(data)).then(() => {
      if (EditShipsAction.fulfilled) {
        dispatch(GetShips());
      }
      setUpdateOpen(false);
      setUpdateData();
    });
  }
  return (
    <Box>
      <Card sx={{ display: { xs: "none", md: "flex" } }}>
        <Box width="100%" sx={{ padding: "  3%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <TitreGraphik>List of ships</TitreGraphik>
                <ButtonTransparent
                  onClick={() => {
                    setAddOpen(true);
                  }}
                >
                  + Add a ship
                </ButtonTransparent>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <TableContainer>
                <Table sx={{ minWidth: 700 }}>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell align="center">Id Ship</StyledTableCell>
                      <StyledTableCell align="center">
                        Ship name
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Geolocation
                      </StyledTableCell>
                      <StyledTableCell align="center">Captain</StyledTableCell>
                      <StyledTableCell align="center">
                        N° voyage
                      </StyledTableCell>
                      <StyledTableCell align="center">Status</StyledTableCell>
                      <StyledTableCell align="center">Action</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {ships?.map((ship) => (
                      <StyledTableRow>
                        <StyledTableCell align="center">
                          {ship?.id}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {ship?.nameShips}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {ship?.voyages[0]?.ports[0]?.nomPort}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {ship?.voyages[0]?.captain.name}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Link
                            to={`/admin/VoyageDetaill/${ship?.voyages[0]?.id}`}
                            style={{ textDecoration: "none", color: "inherit" }}
                          >
                            <LabelTypography
                              sx={{ textAlign: "center", color: "#064C91" }}
                            >
                              {ship?.voyages[0]?.id
                                ? `#${ship.voyages[0].id}`
                                : null}
                            </LabelTypography>
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell align="center"></StyledTableCell>
                        <StyledTableCell>
                          <ButtonRadius
                            sx={{
                              background: "#064C91",
                              color: "white",
                              //  width: "30%",
                            }}
                            onClick={() => {
                              setUpdateData(ship);
                              setUpdateOpen(true);
                            }}
                          >
                            edit
                          </ButtonRadius>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </Card>
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <Box width="100%">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <TitreGraphik>List of ships</TitreGraphik>
                <ButtonTransparent
                  onClick={() => {
                    setAddOpen(true);
                  }}
                >
                  + Add a ship
                </ButtonTransparent>
              </Box>
            </Grid>
            {ships?.map((ship) => (
              <Grid item xs={12}>
                <Card
                  //  key={voyageIndex}
                  variant="outlined"
                  sx={{ maxWidth: 345, padding: "3%", margin: "auto" }}
                >
                  <LabelTypography variant="body1" color="text.primary">
                    Id Ship
                    <LabelTypography component="span" color="#064C91">
                      <Link>{ship?.id}</Link>
                    </LabelTypography>
                  </LabelTypography>
                  <LabelTypography variant="body1" color="text.primary">
                    Ship Name:
                    <LabelTypography component="span" color="#064C91">
                      {ship?.nameShips}
                    </LabelTypography>
                  </LabelTypography>
                  <LabelTypography variant="body1" color="text.primary">
                    Geolocation :
                    <LabelTypography component="span" color="#064C91">
                      {ship?.voyages[0]?.ports[0]?.nomPort}
                    </LabelTypography>
                  </LabelTypography>
                  <LabelTypography variant="body1" color="text.primary">
                    Captain :
                    <LabelTypography component="span" color="#064C91">
                      {ship?.voyages[0]?.captain.name}
                    </LabelTypography>
                  </LabelTypography>
                  <LabelTypography variant="body1" color="text.primary">
                    N° voyage :
                    <LabelTypography component="span" color="#064C91">
                      <Link
                        to={`/admin/VoyageDetaill/${ship?.voyages[0]?.id}`}
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        {ship?.voyages[0]?.id ? `#${ship.voyages[0].id}` : null}
                      </Link>
                    </LabelTypography>
                  </LabelTypography>
                  <LabelTypography variant="body1" color="text.primary">
                    Status
                    <LabelTypography component="span" color="#064C91">
                      {ship?.last_voyage?.idStatus}
                    </LabelTypography>
                  </LabelTypography>
                  <ButtonRadius
                    sx={{
                      background: "#064C91",
                      color: "white",
                      width: "30%",
                    }}
                    onClick={() => {
                      setUpdateData(ship);
                      setUpdateOpen(true);
                    }}
                  >
                    edit
                  </ButtonRadius>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <AddShips
        open={addOpen}
        onClose={() => {
          setAddOpen(false);
        }}
        send={Addsend}
      />
      <UpdateShips
        open={UpdateOpen}
        onClose={() => {
          setUpdateOpen(false);
        }}
        data={UpdateData}
        send={editsend}
      />
    </Box>
  );
}
