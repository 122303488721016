import {
  Grid,
  TextField,
  Box,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import TitreGraphik from "../Typography/TitreGraphik";
import LabelTypography from "../Typography/LabelTypography";
import { styleInput } from "../../assets/style/Input";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetCustomerInputRecever,
  GetCustomerInputShipper,
} from "../../store/slice/customerSlice";

export default function NewFastBookingFormCostumer({
  errors,
  ReceiverInfo,
  SetReceiverInfo,
  shipperInfo,
  setShipperInfo,
}) {
  const Dispatch = useDispatch();
  const [serche, setSerche] = useState();
  const [searchReceiver, setsearcheReceiver] = useState();

  const {
    ShipperOptions,
    ReceiverOptions,
    loadingShipperOptions,
    loadingReceiverOptions,
  } = useSelector((state) => state.CustomerSlice);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      Dispatch(
        GetCustomerInputRecever({
          serche: searchReceiver,
        })
      );
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [Dispatch, searchReceiver]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      Dispatch(
        GetCustomerInputShipper({
          serche: serche,
        })
      );
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [Dispatch, serche]);

  const handleShipperChange = (event, newValue) => {
    if (newValue) {
      setShipperInfo({
        id: newValue.id || "",
        firstName: newValue.firstName || "",
        lastName: newValue.lastName || "",
        NumberPhone: newValue.NumberPhone || "",
        Email: newValue.Email || "",
        nomCompany: newValue.company?.nomCompany || "",
      });
    } else {
      setShipperInfo({
        id: "",
        firstName: "",
        lastName: "",
        NumberPhone: "",
        Email: "",
        nomCompany: "",
      });
    }
  };

  const handlerReceiverChange = (event, newValue) => {
    if (newValue) {
      SetReceiverInfo({
        id: newValue.id || "",
        firstName: newValue.firstName || "",
        lastName: newValue.lastName || "",
        NumberPhone: newValue.NumberPhone || "",
        Email: newValue.Email || "",
        nomCompany: newValue.company?.nomCompany || "",
      });
    } else {
      SetReceiverInfo({
        id: "",
        firstName: "",
        lastName: "",
        NumberPhone: "",
        Email: "",
        nomCompany: "",
      });
    }
  };

  const handleInputChange = (e, setter) => {
    const { name, value } = e.target;
    setter((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Box>
      <Grid container spacing={4} padding="5px">
        <Grid item xs={12}>
          <TitreGraphik>Shipper informations</TitreGraphik>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            options={ShipperOptions}
            getOptionLabel={(option) =>
              `${option.firstName || ""} - ${option.lastName || ""} : ${
                option.NumberPhone || ""
              }` +
              (option.company?.nomCompany
                ? ` - ${option.company.nomCompany}`
                : "")
            }
            clearOnBlur={false}
            onChange={handleShipperChange}
            loading={loadingShipperOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                placeholder="Chercher par Name or email or number ..."
                onChange={(e) => {
                  setSerche(e.target.value);
                }}
                InputProps={{
                  ...params.InputProps,
                  style: styleInput,
                  endAdornment: (
                    <>
                      {loadingShipperOptions ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LabelTypography required={true}>First Name Shipper</LabelTypography>
          <TextField
            type="text"
            fullWidth
            required
            name="firstName"
            aria-label="FirstNameShipper"
            value={shipperInfo.firstName}
            onChange={(e) => handleInputChange(e, setShipperInfo)}
            InputProps={{
              style: styleInput,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LabelTypography required={true}>Last Name Shipper</LabelTypography>
          <TextField
            type="text"
            fullWidth
            required
            aria-label="LastNameShipper"
            name="lastName"
            value={shipperInfo.lastName}
            onChange={(e) => handleInputChange(e, setShipperInfo)}
            InputProps={{
              style: styleInput,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LabelTypography required={true}>
            Shipper Phone Number
          </LabelTypography>
          <TextField
            type="text"
            fullWidth
            required
            name="NumberPhone"
            aria-label="ShipperPhoneNumber"
            value={shipperInfo.NumberPhone}
            error={errors.ShipperPhoneNumber ? true : false}
            helperText={errors.ShipperPhoneNumber}
            onChange={(e) => handleInputChange(e, setShipperInfo)}
            InputProps={{
              style: styleInput,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LabelTypography required={true}>Shipper E-mail</LabelTypography>
          <TextField
            type="text"
            fullWidth
            name="Email"
            aria-label="ShipperEmail"
            value={shipperInfo.Email}
            error={errors.ShipperEmail ? true : false}
            helperText={errors.ShipperEmail}
            onChange={(e) => handleInputChange(e, setShipperInfo)}
            InputProps={{
              style: styleInput,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LabelTypography required={true}>company Shipper</LabelTypography>
          <TextField
            type="text"
            fullWidth
            aria-label="companyShipper"
            name="nomCompany"
            value={shipperInfo.nomCompany}
            onChange={(e) => handleInputChange(e, setShipperInfo)}
            InputProps={{
              style: styleInput,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TitreGraphik>Receiver informations</TitreGraphik>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            options={ReceiverOptions}
            getOptionLabel={(option) =>
              `${option.firstName || ""} - ${option.lastName || ""} : ${
                option.NumberPhone || ""
              }` +
              (option.company?.nomCompany
                ? ` - ${option.company.nomCompany}`
                : "")
            }
            clearOnBlur={false}
            onChange={handlerReceiverChange}
            loading={loadingReceiverOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                placeholder="Chercher par Name or email or number ..."
                onChange={(e) => {
                  setsearcheReceiver(e.target.value);
                }}
                InputProps={{
                  ...params.InputProps,
                  style: styleInput,
                  endAdornment: (
                    <>
                      {loadingShipperOptions ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LabelTypography required={true}>First Name Receiver</LabelTypography>
          <TextField
            type="text"
            fullWidth
            required
            name="firstName"
            aria-label="FirstNameReceiver"
            value={ReceiverInfo.firstName}
            onChange={(e) => handleInputChange(e, SetReceiverInfo)}
            InputProps={{
              style: styleInput,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LabelTypography required={true}>Last Name Receiver</LabelTypography>
          <TextField
            type="text"
            fullWidth
            required
            name="lastName"
            aria-label="LastNameReceiver"
            value={ReceiverInfo.lastName}
            onChange={(e) => handleInputChange(e, SetReceiverInfo)}
            InputProps={{
              style: styleInput,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LabelTypography required={true}>
            Receiver Phone Number
          </LabelTypography>
          <TextField
            type="text"
            fullWidth
            required
            name="NumberPhone"
            aria-label="ReceiverPhoneNumber"
            value={ReceiverInfo.NumberPhone}
            error={errors.ReceiverPhoneNumber ? true : false}
            helperText={errors.ReceiverPhoneNumber}
            onChange={(e) => handleInputChange(e, SetReceiverInfo)}
            InputProps={{
              style: styleInput,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LabelTypography required={true}>Receiver E-mail</LabelTypography>
          <TextField
            type="text"
            fullWidth
            name="Email"
            aria-label="ReceiverEmail"
            value={ReceiverInfo.Email}
            error={errors.ReceiverEmail ? true : false}
            helperText={errors.ReceiverEmail}
            onChange={(e) => handleInputChange(e, SetReceiverInfo)}
            InputProps={{
              style: styleInput,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LabelTypography required={true}>company Receiver</LabelTypography>
          <TextField
            type="text"
            fullWidth
            onChange={(e) => handleInputChange(e, SetReceiverInfo)}
            name="nomCompany"
            aria-label="companyReceiver"
            value={ReceiverInfo.nomCompany}
            InputProps={{
              style: styleInput,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
