import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { StyledTableCell, StyledTableRow } from "../../assets/style/Table";
import { Box, Card, CardContent, Grid } from "@mui/material";
import LabelTypography from "../Typography/LabelTypography";
import { NumericFormat } from "react-number-format";
import ButtonRadius from "../Button/ButtonRadius";
import EditProduct from "../Popup/EditProduct";
import { useState } from "react";

const head = ["Category", "Product", "Unit", "Price", "Price2", "Action"];
function PriceList({ Product }) {
  const [PopupEdit, setPoductEdit] = useState(false);
  const [dataUpdate, setDataUpdate] = useState({});
  return (
    <Box>
      <TableContainer sx={{ display: { xs: "none", md: "flex" } }}>
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              {head.map((h) => (
                <StyledTableCell align="center">{h}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Product?.map((p) => (
              <StyledTableRow key={p.id}>
                <StyledTableCell align="center">
                  {p.nameCategory}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {p.nameProduct}
                </StyledTableCell>
                <StyledTableCell align="center">{p.UNIT}</StyledTableCell>
                <StyledTableCell align="center">
                  <NumericFormat
                    value={p.prixProduct}
                    displayType={"text"}
                    thousandSeparator={true}
                    suffix={" VUV"}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <NumericFormat
                    value={p.prixProduct2}
                    displayType={"text"}
                    thousandSeparator={true}
                    suffix={" VUV"}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <ButtonRadius
                    sx={{
                      background: "#064C91",
                      color: "white",
                      minWidth: "100px",
                      //  width: "30%",
                    }}
                    onClick={() => {
                      setDataUpdate(p);
                      setPoductEdit(true);
                    }}
                  >
                    edit
                  </ButtonRadius>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <Box width="100%">
          <Grid container spacing={2}>
            {Product?.map((p) => (
              <Grid item xs={12}>
                <Card
                  variant="outlined"
                  sx={{ maxWidth: 345, width: "95%", margin: "auto" }}
                >
                  <CardContent>
                    <LabelTypography textAlign="left">
                      category:
                      <LabelTypography component="span" color="#064C91">
                        {p.nameCategory}
                      </LabelTypography>
                    </LabelTypography>
                    <LabelTypography textAlign="left">
                      product :
                      <LabelTypography
                        component="span"
                        style={{ color: "#064C91" }}
                      >
                        {p.nameProduct}
                      </LabelTypography>
                    </LabelTypography>
                    <LabelTypography color="text.primary">
                      unit :
                      <LabelTypography component="span" color="#0671B5">
                        {p.UNIT}
                      </LabelTypography>
                    </LabelTypography>

                    <LabelTypography color="text.primary">
                      price :
                      <LabelTypography component="span" color="#0671B5">
                        <NumericFormat
                          value={p.prixProduct}
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix={" VUV"}
                        />
                      </LabelTypography>
                    </LabelTypography>
                    <LabelTypography color="text.primary">
                      price 2:
                      <LabelTypography component="span" color="#0671B5">
                        <NumericFormat
                          value={p.prixProduct2}
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix={" VUV"}
                        />
                      </LabelTypography>
                    </LabelTypography>
                    <ButtonRadius
                      sx={{
                        background: "#064C91",
                        color: "white",
                        width: "30%",
                      }}
                      onClick={() => {
                        setDataUpdate(p);
                        setPoductEdit(true);
                      }}
                    >
                      edit
                    </ButtonRadius>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <EditProduct
        open={PopupEdit}
        onclose={() => setPoductEdit(false)}
        data={dataUpdate}
      />
    </Box>
  );
}

export default PriceList;
