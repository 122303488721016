import React, { useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  Button,
  FormControl,
  Box,
  Grid,
  Card,
} from "@mui/material";
import Indexdasboard from "../../components/dashboard/IndexDashbord";
import TitreGraphik from "../../components/Typography/TitreGraphik";
import LabelTypography from "../../components/Typography/LabelTypography";
import { useDispatch } from "react-redux";
import { GetStatistiqueExcel } from "../../store/slice/StatistiqueSlice";
export default function ExportDataPage() {
  return <Indexdasboard Children={<PageExport />} />;
}

function PageExport() {
  const [exportOptions, setExportOptions] = useState({
    dataChoice: "",
    period: "",
    detailedManifest: false,
    multiStageTrip: false,
  });
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    console.log(name, value, checked, type);
    setExportOptions((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const HandlChangeOneValue = (event) => {
    const { name, value, checked, type } = event.target;
    setExportOptions((prevOptions) => ({
      ...prevOptions,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleExport = () => {
    console.log("Export options:", exportOptions);
    dispatch(GetStatistiqueExcel(exportOptions));
  };

  return (
    <Box>
      <TitreGraphik>Export to accounting software</TitreGraphik>
      <Card sx={{ padding: "2%" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TitreGraphik>Export</TitreGraphik>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl component="fieldset">
              <LabelTypography>
                <b>Choice of data to export</b>
              </LabelTypography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={exportOptions.dataChoice === "allData"}
                    onChange={handleChange}
                    name="dataChoice"
                    value="allData"
                  />
                }
                label="All"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={exportOptions.dataChoice === "sales"}
                    onChange={handleChange}
                    name="dataChoice"
                    value="sales"
                  />
                }
                label="Sales"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={exportOptions.dataChoice === "transportedVolume"}
                    onChange={handleChange}
                    name="dataChoice"
                    value="transportedVolume"
                  />
                }
                label="Transported volume"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={exportOptions.dataChoice === "reservations"}
                    onChange={handleChange}
                    name="dataChoice"
                    value="reservations"
                  />
                }
                label="Reservations"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl component="fieldset">
              <LabelTypography>
                <b>The period for which to export data</b>
              </LabelTypography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={exportOptions.period === "allPeriod"}
                    onChange={handleChange}
                    name="period"
                    value="allPeriod"
                  />
                }
                label="All"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={exportOptions.period === "month"}
                    onChange={handleChange}
                    name="period"
                    value="month"
                  />
                }
                label="Month"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={exportOptions.period === "quarter"}
                    onChange={handleChange}
                    name="period"
                    value="quarter"
                  />
                }
                label="Quarter"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={exportOptions.period === "year"}
                    onChange={handleChange}
                    name="period"
                    value="year"
                  />
                }
                label="Year"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <LabelTypography>
                <b>Detailed creation of the Manifesto</b>
              </LabelTypography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={exportOptions.detailedManifest}
                    onChange={HandlChangeOneValue}
                    name="detailedManifest"
                  />
                }
                label="Export Detailed Manifest with Data"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <LabelTypography>
                <b>Trip options</b>
              </LabelTypography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={exportOptions.multiStageTrip}
                    onChange={HandlChangeOneValue}
                    name="multiStageTrip"
                  />
                }
                label="Multi-stage trip"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleExport}>
              Export
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
}
