import { Grid, TextField } from "@mui/material";
import LabelTypography from "../Typography/LabelTypography";
import { MuiTelInput } from "mui-tel-input";
import { useState } from "react";
function FormCustomer({ data, formError }) {
  const [tel, setTel] = useState(data?.NumberPhone ? data.NumberPhone : "+678");
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <LabelTypography required={true}>First name</LabelTypography>
        <TextField
          type="text"
          placeholder="First name"
          name="Firstname"
          fullWidth
          required
          defaultValue={data?.firstName}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <LabelTypography required={true}>Last name</LabelTypography>
        <TextField
          type="text"
          placeholder="Last name"
          name="Lastname"
          fullWidth
          required
          defaultValue={data?.lastName}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <LabelTypography required={true}>Address</LabelTypography>
        <TextField
          type="text"
          placeholder="Adress"
          name="Adresse"
          fullWidth
          required
          defaultValue={data?.adress}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <LabelTypography required={true}>Town/island</LabelTypography>
        <TextField
          type="text"
          placeholder="Town/island"
          name="island"
          fullWidth
          required
          defaultValue={data?.island}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <LabelTypography>Company</LabelTypography>
        <TextField
          type="text"
          placeholder="Company"
          name="Company"
          fullWidth
          defaultValue={data?.company?.nomCompany}
        />
      </Grid>

      <Grid item xs={12}>
        <LabelTypography>Email</LabelTypography>
        <TextField
          type="text"
          placeholder="Email"
          name="Email"
          fullWidth
          defaultValue={data?.Email}
          error={!!formError?.email}
          helperText={formError?.email}
        />
      </Grid>
      <Grid item xs={12}>
        <LabelTypography required={true}>Phone number</LabelTypography>
        <MuiTelInput
          fullWidth
          defaultCountry="ma"
          value={tel}
          onChange={(value) => setTel(value)}
          name="number"
        />
      </Grid>
    </Grid>
  );
}

export default FormCustomer;
