export const statusInProgress = {
  backgroundColor: "#FFE1B3",
  color: "#FF6700",
};

export const statusChecked = {
  backgroundColor: "#E3FCE4",
  color: "#28A745",
};

export const statusPending = {
  backgroundColor: "#FFF3CD",
  color: "#FFC107",
};

 export const statusRejected = {
  backgroundColor: "#F8D7DA",
  color: "#DC3545",
};