import { Box, Grid } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TitreGraphik from "../Typography/TitreGraphik";
import LabelTypography from "../Typography/LabelTypography";
import { styleInput } from "../../assets/style/Input";
import { useSelector } from "react-redux";

function FormAddNewFastBooking() {
  const { ports } = useSelector((state) => state.PortsSlice);
  const { user } = useSelector((state) => state.AuthSlice);
  const defaultPort = ports?.find((p) => p?.pivot?.active === 1);
  return (
    <Box>
      <Grid container spacing={4} padding="5px">
        <Grid item xs={12}>
          <TitreGraphik>Ports informations</TitreGraphik>
        </Grid>
        <Grid item xs={12} md={6}>
          <LabelTypography required={true} id="departure-port-label">
            Port of departure
          </LabelTypography>
          <FormControl fullWidth>
            <Select
              labelId="departure-port-label"
              id="departure-port-select"
              name="idPortDepartude"
              sx={styleInput}
              required
              defaultValue={defaultPort?.id}
              value={user.Role === "dock" ? ports[0]?.id : defaultPort?.id}
            >
              {ports?.map((p) => (
                <MenuItem value={p.id}>{p.nomPort}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <LabelTypography id="destination-port-label" required={true}>
            Port of destination
          </LabelTypography>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Select
                labelId="destination-port-label"
                id="destination-port-select"
                name="idPortDestination"
                sx={styleInput}
                required
              >
                {ports?.map((p) => (
                  <MenuItem value={p.id}>{p.nomPort}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <hr
        style={{
          border: "none",
          height: "1px",
          backgroundColor: "gray",

          marginTop: "4%",
          marginBottom: "3%",
        }}
      />
    </Box>
  );
}

export default FormAddNewFastBooking;
