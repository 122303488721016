import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Box,
  Grid,
  Divider,
  Button,
} from "@mui/material";
import LabelTypography from "../Typography/LabelTypography";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { StyledTableCell, StyledTableRow } from "../../assets/style/Table";
import AfficherImagePackage from "../Popup/AfficherImagePackage";
import ButtonSonRadius from "../Button/buttonSonRadius";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "axios";
import { serveurUrl } from "../../utils/Api";
import TitreGraphik from "../Typography/TitreGraphik";
import UpdateStatusBooking from "../../components/Popup/updateStatusBooking";

import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  GetBookingData,
  updateStatusdAction,
  updatePayedAction,
} from "../../store/slice/bookingSlice";
import { useDispatch, useSelector } from "react-redux";
import ActionBookinStatus from "./ActionBookingStatus";
import { etatInisialBookingSlice } from "../../store/slice/bookingSlice";
import CustomAlert from "../../components/Alert/Alert";
import { Link, useNavigate } from "react-router-dom";
import editIcon from "../../assets/Images/editIcon.svg";
import { getStatus } from "../../store/slice/StatistiqueSlice";
import { NumericFormat } from "react-number-format";

export async function handleDownload(url) {
  try {
    const response = await axios.get(url, { responseType: "blob" });
    const link = document.createElement("a");
    const objectURL = URL.createObjectURL(response.data);
    link.href = objectURL;
    link.download = `image`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(objectURL);
  } catch (error) {
    console.error("Error downloading image:", error);
  }
}

function ListPackageAndProduct({ bookingData, id }) {
  const [open, setOpen] = useState({});
  const [openPopup, setOpenPopup] = useState(false);
  const [image, setImage] = useState();
  const [popupOnboard, setPopupOnboard] = React.useState(false);
  const [popupPaye, setPaye] = React.useState(false);
  const { user } = useSelector((state) => state.AuthSlice);
  const dispatch = useDispatch();
  const { etat, message } = useSelector((state) => state.BookingSlice);
  const navigate = useNavigate();

  const handleRowClick = (packageId) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [packageId]: !prevOpen[packageId],
    }));
  };

  function GetData() {
    dispatch(GetBookingData(id));
  }
  console.log(bookingData);
  const shipper = bookingData?.customer?.find(
    (customer) => customer.pivot.type === "Shipper"
  );
  const receiver = bookingData?.customer?.find(
    (customer) => customer.pivot.type === "Receiver"
  );
  const Stylebutton = {
    backgroundColor: "#064C91",
    "&:hover": {
      backgroundColor: "#064C91", // Same color on hover
    },
    "&:active": {
      backgroundColor: "#064C91", // Same color on click
    },
    "&:focus": {
      backgroundColor: "#064C91", // Same color on focus
    },
  };
  return (
    <Box>
      <br />
      <br />
      <TitreGraphik>
        <span onClick={() => navigate(-1)}>⬅</span> Booking Details N°{id}
      </TitreGraphik>
      <br />
      <br />

      <Box
        sx={{ display: { xs: "none", md: "flex" }, flexDirection: "column" }}
      >
        <Paper>
          <Box width="100%">
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell />
                  <StyledTableCell>Package ID</StyledTableCell>
                  <StyledTableCell>Price</StyledTableCell>
                  <StyledTableCell>Stockage</StyledTableCell>
                  <StyledTableCell>Container</StyledTableCell>
                  <StyledTableCell>Delivery docket​</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bookingData?.package?.map((pkg) => (
                  <React.Fragment key={pkg.id}>
                    <StyledTableRow>
                      <StyledTableCell>
                        <IconButton
                          size="small"
                          onClick={() => handleRowClick(pkg.id)}
                        >
                          {open[pkg.id] ? (
                            <KeyboardArrowUp />
                          ) : (
                            <KeyboardArrowDown />
                          )}
                        </IconButton>
                      </StyledTableCell>

                      <StyledTableCell> {pkg.id}</StyledTableCell>
                      <StyledTableCell>
                        <NumericFormat
                          value={pkg.prixPackages}
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix={" VT"}
                        />{" "}
                      </StyledTableCell>
                      <StyledTableCell>{pkg.stoke_in.type}</StyledTableCell>
                      <StyledTableCell>
                        {Number(pkg.inContainerPack) === 1 ? "oui" : "non"}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-start",
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              setImage(`${serveurUrl}/public/${pkg.Urlimage}`);
                              setOpenPopup(true);
                            }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              handleDownload(
                                `${serveurUrl}/api/package/${pkg.Urlimage}`
                              );
                            }}
                          >
                            <DownloadIcon color="primary" />
                          </IconButton>
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={6}
                      >
                        <Collapse
                          in={open[pkg.id]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box margin={1}>
                            <Table size="small" aria-label="products">
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell align="center">
                                    Category
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    product
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    Quantity
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    Price
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    Price Total
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {pkg.products.map((product) => (
                                  <StyledTableRow key={product.id}>
                                    <StyledTableCell align="center">
                                      {product.category.nameCategory}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {product.nameProduct}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {product.pivot.NumberProduct}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      <NumericFormat
                                        value={product.prixProduct}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        suffix={" VT"}
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      <NumericFormat
                                        value={
                                          product.prixProduct *
                                          product.pivot.NumberProduct
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        suffix={" VT"}
                                      />
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </Box>{" "}
        </Paper>
        <Box sx={{ display: "flex", marginTop: "2%" }}>
          {bookingData?.status?.etat === "Quote" && (
            <Link to="/EditbookingData">
              <Button
                variant="contained"
                sx={{ ...Stylebutton, marginLeft: "10px" }}
                size="medium"
                startIcon={
                  <img
                    src={editIcon}
                    alt="Edit"
                    height="20" // Adjust height as needed
                    style={{ marginRight: "10px" }}
                  />
                }
              >
                Edit Booking
              </Button>
            </Link>
          )}

          <Button
            variant="contained"
            sx={{ ...Stylebutton, marginLeft: "10px" }}
            size="medium"
            onClick={() => {
              dispatch(getStatus("booking"));
              setPopupOnboard(true);
            }}
            startIcon={
              <img
                src={editIcon}
                alt="Edit"
                height="20" // Adjust height as needed
                style={{ marginRight: "5px" }}
              />
            }
          >
            Edit status
          </Button>
          {user.Role !== "dock" && (
            <Button
              variant="contained"
              sx={{ ...Stylebutton, marginLeft: "10px" }}
              size="medium"
              onClick={() => {
                setPaye(true);
              }}
              startIcon={
                <img
                  src={editIcon}
                  alt="Edit"
                  height="20" // Adjust height as needed
                  style={{ marginRight: "5px" }}
                />
              }
            >
              Edit Payment
            </Button>
          )}
        </Box>
      </Box>
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <Box width="100%">
          <Paper sx={{ padding: 2, maxWidth: 600, margin: "auto" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <LabelTypography variant="h6" gutterBottom>
                  <b>Shipper:</b>
                </LabelTypography>
                <LabelTypography>
                  {shipper?.firstName} {shipper?.lastName}
                </LabelTypography>
                <LabelTypography>{shipper?.NumberPhone}</LabelTypography>
                <LabelTypography>{shipper?.Email}</LabelTypography>
              </Grid>
              <Grid item xs={12}>
                <LabelTypography variant="h6" gutterBottom>
                  <b>Receiver:</b>
                </LabelTypography>
                <LabelTypography>
                  {receiver?.firstName} {receiver?.lastName}
                </LabelTypography>
                <LabelTypography>{receiver?.NumberPhone}</LabelTypography>
                <LabelTypography>{receiver?.Email}</LabelTypography>
              </Grid>
              <Grid item xs={12}>
                <LabelTypography variant="h6" gutterBottom>
                  <b>Number of Packages :</b> {bookingData?.QtyPackage}
                </LabelTypography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {bookingData?.package?.map((pkg, index) => (
                <>
                  <Grid item xs={12}>
                    <LabelTypography
                      sx={{
                        fontSize: "1.5rem",
                      }}
                    >
                      <b>Package {index + 1}</b>
                    </LabelTypography>
                    {pkg?.products?.map((p) => (
                      <LabelTypography variant="h6" gutterBottom>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <LabelTypography variant="h6" gutterBottom>
                            <LabelTypography
                              variant="span"
                              sx={{ color: "#0671B5" }}
                            >
                              {p.pivot.NumberProduct}
                            </LabelTypography>{" "}
                            {p.nameProduct}{" "}
                          </LabelTypography>
                          <LabelTypography variant="h6" gutterBottom>
                            <NumericFormat
                              value={p.pivot.NumberProduct * p.prixProduct}
                              displayType={"text"}
                              thousandSeparator={true}
                              suffix={" VT"}
                            />
                          </LabelTypography>
                        </Box>
                      </LabelTypography>
                    ))}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <LabelTypography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <LabelTypography> Stockage :</LabelTypography>
                        <LabelTypography sx={{ color: "#061328" }}>
                          <b>{pkg.stoke_in.type}</b>
                        </LabelTypography>
                      </Box>
                    </LabelTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <LabelTypography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <LabelTypography>Container</LabelTypography>
                        <LabelTypography sx={{ color: "#061328" }}>
                          <b>
                            {Number(pkg.inContainerPack) === 1 ? "oui" : "non"}
                          </b>
                        </LabelTypography>
                      </Box>
                    </LabelTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <LabelTypography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <LabelTypography>Total :</LabelTypography>
                        <LabelTypography sx={{ color: "#061328" }}>
                          <b>
                            {" "}
                            <NumericFormat
                              value={pkg.prixPackages}
                              displayType={"text"}
                              thousandSeparator={true}
                              suffix={" VT"}
                            />{" "}
                          </b>
                        </LabelTypography>
                      </Box>
                    </LabelTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <ButtonSonRadius
                      variant="outlined"
                      startIcon={<DownloadIcon />}
                      fullWidth
                      style={{
                        borderColor: "#002855",
                        color: "#002855",
                        height: "8vh",
                      }}
                      sx={{ marginBottom: 2 }}
                      onClick={() => {
                        handleDownload(
                          `${serveurUrl}/api/package/${pkg.Urlimage}`
                        );
                      }}
                    >
                      Download delivery docket
                    </ButtonSonRadius>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </>
              ))}
            </Grid>

            <ActionBookinStatus
              b={bookingData}
              setPaye={setPaye}
              setPopupOnboard={setPopupOnboard}
              setId={() => {}}
            />
            {bookingData?.status?.etat === "Quote" && (
              <Link to="/EditbookingData">
                <Button
                  variant="contained"
                  sx={Stylebutton}
                  size="large"
                  fullWidth
                  onClick={() => {}}
                  startIcon={
                    <img
                      src={editIcon}
                      alt="Edit"
                      height="30" // Adjust height as needed
                      style={{ marginRight: "5px" }}
                    />
                  }
                >
                  Edit Booking
                </Button>
              </Link>
            )}
          </Paper>
        </Box>
      </Box>
      <UpdateStatusBooking
        open={popupOnboard}
        onclose={() => {
          setPopupOnboard(false);
        }}
        title="update status"
        onsend={(data) => {
          dispatch(updateStatusdAction(data)).then((result) => {
            if (updateStatusdAction.fulfilled.match(result)) {
              GetData();
            }
          });
          setPopupOnboard(false);
        }}
        id={id}
      />
      <UpdateStatusBooking
        open={popupPaye}
        onclose={() => {
          setPaye(false);
        }}
        title="update Payment"
        onsend={(data) => {
          dispatch(updatePayedAction(data)).then((result) => {
            if (updatePayedAction.fulfilled.match(result)) {
              GetData();
            }
          });
          setPaye(false);
        }}
        id={id}
      />
      <AfficherImagePackage
        open={openPopup}
        onClose={() => {
          setOpenPopup(false);
        }}
        image={image}
      />
      <CustomAlert
        open={etat !== "inisial"}
        onClose={() => {
          dispatch(etatInisialBookingSlice());
        }}
        etat={etat}
        message={message}
      />
    </Box>
  );
}

export default ListPackageAndProduct;
