import { Button, Typography } from "@mui/material";

function ButtonTransparent({ children, ...props }) {
  return (
    <Button
      variant="outlined"
      sx={{
        backgroundColor: "#DBE6ED",
        "&:hover": {
          backgroundColor: "#DCE7EE", // Keep the background color the same on hover
        },
      }}
      {...props}
    >
      <Typography
        variant="button"
        sx={{
          fontFamily: "Poppins",
          textTransform: "capitalize",
          fontSize: "80%",
        }}
      >
        {children}
      </Typography>
    </Button>
  );
}

export default ButtonTransparent;
