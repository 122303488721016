import SalesChart from "../../components/Statistique/Saleschart";
import { Box, Card, Grid } from "@mui/material";
import Indexdasboard from "../../components/dashboard/IndexDashbord";
import NumberGeneral from "../../components/Statistique/NumberGeneral";
import TitreGraphik from "../../components/Typography/TitreGraphik";
import ButtonSimple from "../../components/Button/ButtonSimple";
import VoyageList from "../../components/Voyage/VoyageList";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GetVoyageDashboard } from "../../store/slice/VoyageSlice";
import LoadingPage from "../../components/loading";
import { styleInput } from "../../assets/style/Input";
import { Link } from "react-router-dom";

export default function DashboardAdmin() {
  return (
    <Box>
      <Indexdasboard Children={<PageDashboard />} />
    </Box>
  );
}
const head = [
  "N°Voyage",
  "Ship name",
  "Captain's name",
  "Route",
  "Date and time cut-off",
  "Date & time departure",
  "Date and time Arrival",
];
export function PageDashboard() {
  const voyages = useSelector((state) => state.VoyageSlice.voyageDashboard);
  const dispatch = useDispatch();
  useEffect(() => {
    const data = {
      sort: "desc",
      number: 5,
    };
    dispatch(GetVoyageDashboard(data));
  }, [dispatch]);

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TitreGraphik>Dashboard</TitreGraphik>
        </Grid>
        <Grid item xs={12}>
          <NumberGeneral />
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ padding: "1%" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <TitreGraphik sx={{ fontSize: { xs: "1rem", md: "1.5rem" } }}>
                voyage List
              </TitreGraphik>
              <Link to="/admin/VoyageManagement">
                <ButtonSimple
                  sx={{ ...styleInput, color: "primary", padding: "10px" }}
                >
                  view more
                </ButtonSimple>
              </Link>
            </Box>
            <br />
            <VoyageList voyages={voyages} head={head} />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ padding: "1%" }}>
            <SalesChart />
          </Card>
        </Grid>
      </Grid>
      <LoadingPage />
    </Box>
  );
}
