import { Box, Card, Grid } from "@mui/material";
import Indexdasboard from "../../../components/dashboard/IndexDashbord";
import TitreGraphik from "../../../components/Typography/TitreGraphik";
import FormCustomer from "../../../components/customer/FormCustomer";
import ButtonSonRadius from "../../../components/Button/buttonSonRadius";
import { useDispatch, useSelector } from "react-redux";
import { SendCustomer } from "../../../store/slice/customerSlice";
import { useNavigate } from "react-router-dom";

import { useState } from "react";

export default function PageAddCustomer() {
  return <Indexdasboard Children={<AddCustomer />} />;
}
export const CheckEmailRegulaire = (email) => {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(email);
};
function AddCustomer() {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const { user } = useSelector((state) => state.AuthSlice);
  const [formError, setFormError] = useState({});
  function sendForm(e) {
    const error = {};
    e.preventDefault();

    const Data = new FormData(e.target);
    if (e.target.Email.value) {
      if (!CheckEmailRegulaire(e.target.Email.value)) {
        console.log(CheckEmailRegulaire(e.target.Email.value));
        error.email = "email invalide";
        setFormError(error);
        return;
      }
    }

    dispatch(SendCustomer(Data)).then((result) => {
      Navigate(`/${user.Role}/CustomerManagement`);
    });
  }
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TitreGraphik>
            <span
              onClick={() => {
                Navigate(-1);
              }}
            >
              ⬅
            </span>{" "}
            New customer
          </TitreGraphik>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={sendForm}>
            <Card sx={{ padding: "3%" }}>
              <FormCustomer formError={formError} />
              <ButtonSonRadius
                variant="contained"
                type="submit"
                sx={{
                  height: "10vh",
                  marginTop: "3%",
                  width: "30vh",
                }}
              >
                Save
              </ButtonSonRadius>
            </Card>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
}
