import { Typography } from "@mui/material";

function LabelTypography({ children, required, ...props }) {
  return (
    <Typography
      sx={{
        fontFamily: "Poppins",
        fontSize: "16px",
        marginBottom: "4px",
        fontWeight: "500",
      }}
      {...props}
    >
      {children} {required && <span style={{ color: "red" }}>*</span>}
    </Typography>
  );
}

export default LabelTypography;
