import React from "react";
import Typography from "@mui/material/Typography";

const TitreGraphik = ({ children, ...props }) => {
  return (
    <Typography
      variant="custom"
      fontFamily="Graphik"
      fontSize="30px"
      fontWeight="700"
      sx={{
        "@media (max-width: 600px)": {
          fontSize: "24px",
          fontWeight: "500",
        },
      }}
      {...props}
    >
      {children}
    </Typography>
  );
};

export default TitreGraphik;
