import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Box,
  DialogTitle,
} from "@mui/material";
import ButtonSonRadius from "../Button/buttonSonRadius";
import CancelIcon from "@mui/icons-material/Cancel";
import TitreGraphik from "../Typography/TitreGraphik";
import { useDispatch, useSelector } from "react-redux";
import { popupNumberClose } from "../../store/slice/bookingSlice";

const NumberBooking = () => {
  const dispatch = useDispatch();
  const { Number, PopupNumber } = useSelector((state) => state.BookingSlice);
  const onClose = () => {
    dispatch(popupNumberClose());
  };
  return (
    <Dialog open={PopupNumber} onClose={onClose}>
      <DialogTitle>
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton color="error" onClick={onClose}>
              <CancelIcon />
            </IconButton>
          </Box>
          <TitreGraphik>booking Number </TitreGraphik>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ textAlign: "center" }}>
        <TitreGraphik sx={{ textAlign: "center" }}>{Number}</TitreGraphik>
      </DialogContent>
      <DialogActions>
        <ButtonSonRadius variant="contained" color="error" onClick={onClose}>
          cancel
        </ButtonSonRadius>
      </DialogActions>
    </Dialog>
  );
};

export default NumberBooking;
