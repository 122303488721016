import { Box, Dialog, Grid, Select, TextField, MenuItem } from "@mui/material";
import LabelTypography from "../Typography/LabelTypography";
import { styleInput } from "../../assets/style/Input";
import TitreGraphik from "../Typography/TitreGraphik";
import ButtonSonRadius from "../Button/buttonSonRadius";
import { useDispatch, useSelector } from "react-redux";
import {
  EditBookingAction,
  GetBookingInvoice,
} from "../../store/slice/bookingSlice";
import { serveurUrl } from "../../utils/Api";
import { handleDownload } from "../Booking/ListPackageAndProduct";
import { listStatusPayment } from "../../assets/Data";
import { dateCreation } from "../../hooks/useDate";
import { useState } from "react";

export default function EditBooking({ open, onClose, data, UpdateData }) {
  const dispatch = useDispatch();
  const { status } = useSelector((state) => state.Statistique);
  const [onBoard, setOnboard] = useState(false);
  const checkStatusOnBoard = (value) => {
    console.log(value.toLowerCase());
    if (value.toLowerCase() === "on board") {
      setOnboard(true);
    } else {
      setOnboard(false);
    }
  };

  console.log(data);
  function sendForm(e) {
    e.preventDefault();
    const DataForm = new FormData(e.target);
    DataForm.append("id", data.id);
    dispatch(EditBookingAction(DataForm)).then((result) => {
      if (EditBookingAction.fulfilled.match(result)) {
        UpdateData();
      }
    });
    onClose();
  }
  const destination = data?.port?.find(
    (p) => p.pivot.typePort === "destination"
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth="lg" maxWidth="md">
      <form onSubmit={sendForm}>
        <Grid container spacing={2} padding="3%">
          <Grid item xs={12}>
            <TitreGraphik>
              <span onClick={onClose}>⬅ </span>Edit #{data?.id}
            </TitreGraphik>
          </Grid>
          <Grid item xs={12} md={4}>
            <LabelTypography>Creation date</LabelTypography>
            <TextField
              value={dateCreation(data?.created_at)}
              fullWidth
              sx={styleInput}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <LabelTypography>Destination</LabelTypography>
            <TextField value={destination?.nomPort} fullWidth sx={styleInput} />
          </Grid>
          <Grid item xs={12} md={4}>
            <LabelTypography>Customer</LabelTypography>
            <TextField
              value={`${data?.shipper[0]?.firstName} ${data?.shipper[0]?.firstName}`}
              fullWidth
              sx={styleInput}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <LabelTypography>N° Voyage</LabelTypography>
            <TextField
              value={data?.voyage?.id || ""}
              fullWidth
              sx={styleInput}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <LabelTypography>Date departure</LabelTypography>
            <TextField
              value={data?.voyage?.dateDeparture || ""}
              fullWidth
              sx={styleInput}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <LabelTypography>Date arrival</LabelTypography>
            <TextField
              value={data?.voyage?.dateArrival || ""}
              fullWidth
              sx={styleInput}
            />
          </Grid>
          <Grid item xs={12}>
            <LabelTypography>Status</LabelTypography>
            <Select fullWidth defaultValue={data?.idStatus} name="status">
              {status?.map((s) => (
                <MenuItem
                  value={s.id}
                  onClick={() => {
                    checkStatusOnBoard(s.etat);
                  }}
                >
                  {s.etat}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          {onBoard === true ? (
            <Grid item xs={12}>
              <TextField
                type="number"
                placeholder="N° Voyage"
                name="idVoyage"
                defaultValue={data?.voyage?.id}
                required
                fullWidth
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <LabelTypography>Payment</LabelTypography>
            <Select defaultValue={data?.Payé} name="paye" fullWidth>
              {listStatusPayment.map((p) => (
                <MenuItem value={p}>{p}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex" }}>
              <ButtonSonRadius
                variant="contained"
                type="submit"
                sx={{
                  height: "6vh",
                  marginTop: "3%",
                  width: "30vh",
                  marginRight: "10px",
                }}
              >
                Save
              </ButtonSonRadius>
              {["On quay", "On board", "Delivered"].includes(
                data?.status?.etat
              ) && (
                <>
                  <ButtonSonRadius
                    variant="contained"
                    sx={{
                      height: "6vh",
                      marginTop: "3%",
                      width: "30vh",
                      backgroundColor: "rgba(6, 137, 145, 0.1)", // 10% transparency
                      color: "#068991",
                      "&:hover": {
                        backgroundColor: "#068991",
                        color: "white", // Solid color on hover
                      },
                      marginRight: "10px",
                    }}
                    onClick={() => {
                      handleDownload(
                        `${serveurUrl}/api/package/${data.Urlimage}`
                      );
                    }}
                  >
                    Download
                  </ButtonSonRadius>

                  <ButtonSonRadius
                    variant="contained"
                    type="button"
                    sx={{
                      height: "6vh",
                      marginTop: "3%",
                      width: "30vh",
                      backgroundColor: "rgba(198, 0, 0, 0.1)", // 10% transparency
                      color: "#C60000",
                      "&:hover": {
                        backgroundColor: "#C60000",
                        color: "white", // Solid color on hover
                      },
                      marginRight: "10px",
                    }}
                    onClick={() => {
                      dispatch(GetBookingInvoice(data.id));
                    }}
                  >
                    Invoice
                  </ButtonSonRadius>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
}
