import React, { useEffect } from "react";
import {
  Grid,
  Box,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableContainer,
  Checkbox,
} from "@mui/material";
import { useState } from "react";

import TitreGraphik from "../Typography/TitreGraphik";
import ButtonSonRadius from "../Button/buttonSonRadius";
import UploadFile from "./Uploadfile";
import ProductPackage, { ProductPackageResponsive } from "./ProductPackage";
import AddProduitsPopup from "../Popup/AddProduitsPopup";
import LabelTypography from "../Typography/LabelTypography";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { Getstoke } from "../../store/slice/bookingSlice";

export const StyleCell = {
  minWidht: "50px",
  fontWeight: "bold",
};
function Package({
  pack,
  index,
  packages,
  AddProductsPackage,
  AddfilePackage,
  DeletefilePackage,
  errorFiles,
  UpdateQuantite,
  handleRemove,
  UpdateStoke,
  deletePackage,
  UpdateStokeContainer,
}) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(Getstoke());
  }, [dispatch]);
  const [open, setOpen] = useState(false);
  //const [Prix, setPrix] = useState(0);
  const { stokeIn } = useSelector((state) => state.BookingSlice);

  /*  useEffect(() => {
    const calculePrix = () => {
      let totalPrice = 0;
      pack.produits.forEach((produit) => {
        console.log(produit.product.prixProduct);
        console.log(produit.quantity);
        totalPrice += produit.product.prixProduct * produit.quantity;
      });
      setPrix(totalPrice);
    };

    calculePrix();
  }, [packages, pack.produits]);*/
  console.log(pack);
  return (
    <>
      {index !== 0 && (
        <hr
          style={{
            border: "none",
            height: "1px",
            backgroundColor: "gray",
            marginTop: "4%",
            marginBottom: "3%",
          }}
        />
      )}
      <Box width="100%" paddingLeft="3%">
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            sx={{
              //  display: {
              //    xs: "none",
              //    md: "flex",
              //  },
              alignItems: "flex-end",
              justifyContent: "space-between",
            }}
          >
            <TitreGraphik>Delivery docket {index + 1}</TitreGraphik>

            <UploadFile
              setFile={(file) => AddfilePackage(index, file)}
              Deletefile={() => {
                DeletefilePackage(index);
              }}
              errorFile={errorFiles.includes(index)}
              file={pack.file}
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonSonRadius
              variant="contained"
              onClick={() => setOpen(true)}
              sx={{
                //marginRight: "3%",
                backgroundColor: "#064C91",
                "&:hover": {
                  backgroundColor: "#0A3D6A",
                },
                width: { xs: "100%", md: "20%" },
              }}
            >
              +Add Product
            </ButtonSonRadius>
          </Grid>
          {/*  <Grid
            item
            xs={12}
            sx={{
              display: {
                xs: "flex",
                md: "none",
              },
            }}
            alignItems="flex-start"
          >
            <TitreGraphik>Delivery docket {index + 1}</TitreGraphik>
          </Grid>  <Grid
            item
            xs={12}
            sx={{
              display: {
                xs: "flex",
                md: "none",
              },
              alignItems: "flex-end",
              justifyContent: "flex-end",
              height: "100%", // Pour que le bouton prenne toute la hauteur du Grid item
            }}
          >
            <ButtonSonRadius
              variant="contained"
              onClick={() => {
                deletePackage(index);
              }}
              color="error"
              sx={{
                marginRight: "2%",

                marginLeft: "3%",
              }}
            >
              delete Delivery docket
            </ButtonSonRadius>
            <ButtonSonRadius
              variant="contained"
              onClick={() => setOpen(true)}
              sx={{
                marginRight: "2%",
                backgroundColor: "#064C91",
                "&:hover": {
                  backgroundColor: "#0A3D6A",
                },
              }}
            >
              +Add Product
            </ButtonSonRadius>
          </Grid>
      */}

          <Grid xs={12} padding="10px">
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <Box width="100%">
                <TableContainer marginLeft="2%" marginRight="2%">
                  <Table>
                    <TableHead sx={{ background: "#F8F8F8" }}>
                      <TableCell align="center" sx={StyleCell}>
                        Name
                      </TableCell>
                      <TableCell align="center" sx={StyleCell}>
                        Category
                      </TableCell>
                      <TableCell align="center" sx={StyleCell}>
                        Quantité
                      </TableCell>
                      <TableCell align="center" sx={StyleCell}>
                        Unité Price
                      </TableCell>
                      <TableCell align="center" sx={StyleCell}>
                        Price Total
                      </TableCell>
                      <TableCell align="center" sx={StyleCell}></TableCell>
                    </TableHead>
                    <TableBody>
                      {pack.produits.map((product, indexProduit) => (
                        <ProductPackage
                          index={index}
                          indexProduit={indexProduit}
                          product={product}
                          handleRemove={() => {
                            handleRemove(index, indexProduit);
                          }}
                          UpdateQuantite={(value) => {
                            UpdateQuantite(index, indexProduit, value);
                          }}
                          UpdateStoke={(value) => {
                            UpdateStoke(index, indexProduit, value);
                          }}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <Box>
                <Grid container spacing={2}>
                  {pack.produits.map((product, indexProduit) => (
                    <Grid item xs={12} sx={{ padding: "1%" }}>
                      <ProductPackageResponsive
                        index={index}
                        indexProduit={indexProduit}
                        product={product}
                        handleRemove={() => {
                          handleRemove(index, indexProduit);
                        }}
                        UpdateQuantite={(value) => {
                          UpdateQuantite(index, indexProduit, value);
                        }}
                        UpdateStoke={(value) => {
                          UpdateStoke(index, indexProduit, value);
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={4} order={{ xs: 1, md: 1 }}>
            <Box padding="2%" marginTop="4%" sx={{ background: "white" }}>
              <Grid container spacing={2} marginTop="1%">
                <Grid item xs={6} sx={{}}>
                  <LabelTypography required={true}>storage</LabelTypography>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={pack.stoke || null}
                      onChange={(e) => {
                        UpdateStoke(index, e.target.value);
                      }}
                      required
                      sx={{ height: "6vh" }}
                    >
                      {stokeIn?.map((s) => (
                        <MenuItem value={s.id}>{s.type}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} order={{ xs: 2, md: 2 }}>
            <Box padding="2%" marginTop="4%" sx={{ background: "white" }}>
              <Grid container spacing={2} marginTop="1%">
                <Grid item xs={6}>
                  <LabelTypography required={true}>
                    in a container
                  </LabelTypography>
                </Grid>
                <Grid item xs={3}>
                  <Checkbox
                    color="primary"
                    size="small"
                    checked={pack.container}
                    onChange={(event) => {
                      //  setOpenAddStoke(event.target.checked);
                      console.log(event.target.checked);
                      UpdateStokeContainer(index, !event.target.checked);
                    }}
                    //icon={<AddCircleOutlineIcon />}
                    //checkedIcon={<AddCircleOutlineIcon />}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} order={{ xs: 3, md: 3 }}>
            <Box padding="2%" marginTop="4%" sx={{ background: "white" }}>
              <Grid container spacing={2} marginTop="3%">
                {/*   <Grid item xs={12} md={12}>
                  <LabelTypography>price package :</LabelTypography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <LabelTypography>
                    <NumericFormat
                      value={Prix}
                      displayType={"text"}
                      thousandSeparator={true}
                      suffix={"VUV"}
                    />
                  </LabelTypography>
                </Grid>*/}
                <ButtonSonRadius
                  variant="contained"
                  onClick={() => {
                    deletePackage(index);
                  }}
                  color="error"
                  sx={{
                    marginRight: "2%",

                    marginLeft: "3%",
                  }}
                >
                  delete Delivery docket
                </ButtonSonRadius>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <AddProduitsPopup
        open={open}
        onclose={() => {
          setOpen(false);
        }}
        AddProducts={(products) => {
          AddProductsPackage(index, products);
        }}
      />
    </>
  );
}

export default Package;
