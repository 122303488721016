import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { StyledTableCell, StyledTableRow } from "../../assets/style/Table";
import StatusLabel from "../status/status";
import ButtonRadius from "../Button/ButtonRadius";
import DateFormate from "../date/dateFormat";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { Box, Card, CardContent, Grid } from "@mui/material";
import LabelTypography from "../Typography/LabelTypography";
import EditBooking from "../Popup/EditBooking";
import { useState } from "react";
import { getStatus } from "../../store/slice/StatistiqueSlice";
import { useDispatch } from "react-redux";
import { NumericFormat } from "react-number-format";
import { TableFooter } from "@mui/material";
import { FormatDateSansH } from "../../hooks/FunctionGlobale";
import { useSelector } from "react-redux";

export default function ListBookingVoyageCosutmer({
  head,
  Bookings,
  dateSimple,
  updateList,
  TotalInvoice,
}) {
  const dispatch = useDispatch();
  const [PopupEdit, setPopupEdit] = useState(false);
  const [DataPopup, setDataPopup] = useState();
  const { user } = useSelector((state) => state.AuthSlice);
  function editPopup(id, data) {
    dispatch(getStatus("booking"));
    setDataPopup(data);
    setPopupEdit(true);
  }
  const totalInvoiceAmount = Bookings?.reduce(
    (total, b) => total + parseFloat(b.price),
    0
  );

  return (
    <Box>
      <TableContainer
        sx={{
          overflowX: "auto",

          display: { xs: "none", md: "flex" },
        }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {head.map((h) => (
                <StyledTableCell align="center">{h}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Bookings?.map((b) => {
              const destination = b?.port?.find(
                (p) => p.pivot.typePort === "destination"
              );
              return (
                <StyledTableRow>
                  {head.includes("N° booking") && (
                    <StyledTableCell align="center">
                      <Link
                        to={`/bookingData/${b.id}`}
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <LabelTypography
                          sx={{ textAlign: "center", color: "#064C91" }}
                        >
                          #{b.id}
                        </LabelTypography>
                      </Link>
                    </StyledTableCell>
                  )}
                  {head.includes("Creation date") && (
                    <StyledTableCell align="center">
                      {FormatDateSansH(b.created_at)}
                    </StyledTableCell>
                  )}{" "}
                  {head.includes("Container") && (
                    <StyledTableCell align="center">
                      {b.inContainer === 1 ? "oui" : "non"}
                    </StyledTableCell>
                  )}
                  {head.includes("Route") && (
                    <StyledTableCell align="center">
                      {b?.voyage?.Route}
                    </StyledTableCell>
                  )}
                  {head.includes("Destination") && (
                    <StyledTableCell align="center">
                      {destination?.nomPort}
                    </StyledTableCell>
                  )}
                  {head.includes("Customer") && (
                    <StyledTableCell align="center">
                      {b?.shipper?.[0]?.firstName +
                        " " +
                        b?.shipper?.[0]?.lastName || "N/A"}
                    </StyledTableCell>
                  )}
                  {head.includes("N° Voyage") && (
                    <StyledTableCell align="center">
                      <Link
                        to={`/${user.Role}/VoyageDetaill/${b?.voyage?.id}`}
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <LabelTypography
                          sx={{ textAlign: "center", color: "#064C91" }}
                        >
                          {b.status?.etat === "On board"
                            ? "#" + b?.voyage?.id
                            : null}
                        </LabelTypography>
                      </Link>
                    </StyledTableCell>
                  )}
                  {head.includes("Date departure") &&
                    (b.voyage?.dateDeparture ? (
                      dateSimple ? (
                        <StyledTableCell align="center">
                          {format(new Date(b.voyage.dateDeparture), "dd MMM")}
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell
                          align="center"
                          sx={{ minWidth: "150px" }}
                        >
                          <DateFormate date={b.voyage.dateDeparture} />
                        </StyledTableCell>
                      )
                    ) : (
                      <StyledTableCell></StyledTableCell>
                    ))}
                  {head.includes("Date arrival") &&
                    (b.voyage?.dateArrival ? (
                      dateSimple ? (
                        <StyledTableCell align="center">
                          {format(new Date(b.voyage.dateArrival), "dd MMM")}
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell
                          align="center"
                          sx={{ minWidth: "150px" }}
                        >
                          <DateFormate date={b.voyage.dateArrival} />
                        </StyledTableCell>
                      )
                    ) : (
                      <StyledTableCell></StyledTableCell>
                    ))}
                  {head.includes("Status") && (
                    <StyledTableCell align="center">
                      <StatusLabel status={b.status?.etat} />
                    </StyledTableCell>
                  )}
                  {head.includes("Invoice amount") && (
                    <StyledTableCell align="center">
                      <NumericFormat
                        value={b.price}
                        displayType={"text"}
                        thousandSeparator={true}
                        suffix={" VT"}
                      />
                    </StyledTableCell>
                  )}
                  {head.includes("Paid") && (
                    <StyledTableCell align="center">
                      <LabelTypography>
                        {b.Payé === "no" ? "no" : "yes"}
                      </LabelTypography>
                    </StyledTableCell>
                  )}
                  {head.includes("Payment") && (
                    <StyledTableCell align="center">
                      <LabelTypography>{b.Payé}</LabelTypography>
                    </StyledTableCell>
                  )}
                  {head.includes("Bill of lading") && (
                    <StyledTableCell align="center">
                      <ButtonRadius
                        sx={{ background: "#068991", color: "white" }}
                      >
                        Download
                      </ButtonRadius>
                    </StyledTableCell>
                  )}
                  {head.includes("Invoice") && (
                    <StyledTableCell align="center">
                      <ButtonRadius
                        sx={{ background: "#A70000", color: "white" }}
                      >
                        Invoice
                      </ButtonRadius>
                    </StyledTableCell>
                  )}
                  {head.includes("Action") && (
                    <StyledTableCell align="center">
                      <ButtonRadius
                        onClick={() => {
                          editPopup(b.id, b);
                        }}
                        sx={{
                          background: "#064C91",
                          color: "white",
                          minWidth: "100px",
                        }}
                      >
                        Edit
                      </ButtonRadius>
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              );
            })}
          </TableBody>
          {TotalInvoice && Bookings?.length > 0 && (
            <TableFooter>
              <StyledTableRow>
                {head.includes("N° booking") && <StyledTableCell />}
                {head.includes("Creation date") && <StyledTableCell />}
                {head.includes("Container") && <StyledTableCell />}
                {head.includes("Route") && <StyledTableCell />}
                {head.includes("Destination") && <StyledTableCell />}
                {head.includes("Customer") && <StyledTableCell />}
                {head.includes("N° Voyage") && <StyledTableCell />}
                {head.includes("Date departure") && <StyledTableCell />}
                {head.includes("Date arrival") && <StyledTableCell />}
                {head.includes("Status") && <StyledTableCell />}

                {head.includes("Invoice amount") && (
                  <StyledTableCell align="center">
                    <LabelTypography sx={{ textstyle: "bold", color: "black" }}>
                      <NumericFormat
                        value={totalInvoiceAmount}
                        displayType={"text"}
                        thousandSeparator={true}
                        suffix={" VT"}
                      />
                    </LabelTypography>
                  </StyledTableCell>
                )}

                {/* Add empty cells for other columns if necessary */}
                {head.includes("Paid") && <StyledTableCell />}
                {head.includes("Payment") && <StyledTableCell />}
                {head.includes("Bill of lading") && <StyledTableCell />}
                {head.includes("Invoice") && <StyledTableCell />}
                {head.includes("Action") && <StyledTableCell />}
              </StyledTableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
      <Box sx={{ display: { xs: "flex ", md: "none" } }}>
        <Box sx={{ width: "95%", margin: "auto" }}>
          <Grid container spacing={2}>
            {Bookings?.map((b) => {
              const destination = b?.port?.find(
                (p) => p.pivot.typePort === "destination"
              );
              return (
                <Grid item xs={12}>
                  <Card
                    variant="outlined"
                    sx={{ maxWidth: 345, padding: "2%" }}
                  >
                    <CardContent>
                      {head.includes("N° booking") && (
                        <LabelTypography variant="h6" component="div">
                          N° Booking:{" "}
                          <Link
                            to={`/bookingData/${b.id}`}
                            style={{
                              textDecoration: "none",
                              color: "inherit",
                              textAlign: "center",
                              color: "#064C91",
                            }}
                          >
                            #{b.id}
                          </Link>
                        </LabelTypography>
                      )}
                      {head.includes("Destination") && (
                        <LabelTypography variant="body1" color="text.primary">
                          Destination:
                          <LabelTypography component="span" color="#064C91">
                            {destination?.nomPort}
                          </LabelTypography>
                        </LabelTypography>
                      )}
                      {head.includes("Customer") && (
                        <LabelTypography variant="body1" color="text.primary">
                          Customer:
                          <LabelTypography component="span" color="#064C91">
                            {b?.shipper?.[0]?.firstName +
                              " " +
                              b?.shipper?.[0]?.lastName || "N/A"}
                          </LabelTypography>
                        </LabelTypography>
                      )}
                      {head.includes("N° Voyage") && (
                        <LabelTypography variant="body1" color="text.primary">
                          N° Voyage:
                          <LabelTypography component="span" color="#064C91">
                            {b?.voyage?.id}
                          </LabelTypography>
                        </LabelTypography>
                      )}
                      {head.includes("date deparature") && (
                        <LabelTypography variant="body1" color="text.primary">
                          Date departure:
                          <LabelTypography component="span" color="#064C91">
                            {b.voyage?.dateDeparture &&
                              format(
                                new Date(b.voyage?.dateDeparture),
                                "dd MMM"
                              )}
                          </LabelTypography>
                        </LabelTypography>
                      )}
                      {head.includes("date arrival") && (
                        <LabelTypography variant="body1" color="text.primary">
                          Date arrival:
                          <LabelTypography component="span" color="#064C91">
                            {b.voyage?.dateArrival &&
                              format(new Date(b.voyage?.dateArrival), "dd MMM")}
                          </LabelTypography>
                        </LabelTypography>
                      )}
                      {head.includes("Status") && (
                        <LabelTypography variant="body1" color="text.primary">
                          Status: <StatusLabel status={b.status?.etat} />
                        </LabelTypography>
                      )}
                      {head.includes("Container") && (
                        <LabelTypography variant="body1" color="text.primary">
                          Container : {b.inContainer === 1 ? "oui" : "non"}
                        </LabelTypography>
                      )}
                      {head.includes("Payment") && (
                        <LabelTypography color="text.primary">
                          Payment :{" "}
                          <LabelTypography component="span" color="#064C91">
                            {b.Payé}
                          </LabelTypography>
                        </LabelTypography>
                      )}
                      {head.includes("Invoice amount") && (
                        <LabelTypography color="text.primary">
                          Invoice amount:
                          <NumericFormat
                            value={b?.price}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={" VT"}
                          />
                        </LabelTypography>
                      )}
                    </CardContent>
                    {head.includes("Action") && (
                      <ButtonRadius
                        onClick={() => {
                          editPopup(b.id, b);
                        }}
                        sx={{
                          background: "#064C91",
                          color: "white",
                          width: "30%",
                          marginLeft: "5%",
                        }}
                      >
                        Edit
                      </ButtonRadius>
                    )}
                  </Card>
                </Grid>
              );
            })}
            {TotalInvoice && Bookings?.length > 0 && (
              <>
                <Grid item xs={12}>
                  <LabelTypography>Total Invoice</LabelTypography>
                </Grid>
                <Grid item xs={12}>
                  <LabelTypography sx={{ textstyle: "bold", color: "black" }}>
                    <NumericFormat
                      value={totalInvoiceAmount}
                      displayType={"text"}
                      thousandSeparator={true}
                      suffix={" VT"}
                    />
                  </LabelTypography>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Box>
      <EditBooking
        open={PopupEdit}
        onClose={() => {
          setPopupEdit(false);
        }}
        UpdateData={updateList}
        data={DataPopup}
      />
    </Box>
  );
}
