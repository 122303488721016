import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Api } from "../../utils/Api";

export const GetProduct = createAsyncThunk("GetProduct", async (params) => {
  const response = await Api.get(`product/getProductbyParams?name=${params}`);
  return response.data;
});

export const GetPricelist = createAsyncThunk("GetPricelist", async (data) => {
  const response = await Api.get(
    `product/getPriceList?name=${data.params}&page=${data.page}`
  );
  return response.data;
});
export const GetCategory = createAsyncThunk("get category", async () => {
  const response = await Api.get(`category_Product/getCategory`);
  return response.data;
});

export const NewProductAction = createAsyncThunk(
  "new product action",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.post(`product/AddProduct`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue({ error: error.message });
    }
  }
);

export const UpdateProductAction = createAsyncThunk(
  "update product action",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.post(`product/updateProduct`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue({ error: error.message });
    }
  }
);

export const DeleteProductAction = createAsyncThunk(
  "delete product action",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`product/deleteProduct/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue({ error: error.message });
    }
  }
);
const initialState = {
  ProductsStore: [],
  ProductPriceList: [],
  infoPriceList: {},
  Category: [],
  loading: false,
  etat: "inisial", // inisial  , success  , error
  message: "",
};
export const productSlice = createSlice({
  name: "product slice",
  initialState,
  reducers: {
    etatInisialProductSlice: (state, action) => {
      state.etat = "inisial";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetProduct.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetProduct.fulfilled, (state, action) => {
      state.ProductsStore = action.payload;
      state.loading = false;
      console.log(action.payload);
    });
    builder.addCase(GetProduct.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(GetPricelist.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetPricelist.fulfilled, (state, action) => {
      state.loading = false;
      state.infoPriceList = action.payload;
      if (action.payload.current_page === 1) {
        state.ProductPriceList = action.payload.data;
      } else {
        state.ProductPriceList = [
          ...state.ProductPriceList,
          ...action.payload.data,
        ];
      }
    });
    builder.addCase(GetPricelist.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(GetCategory.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetCategory.fulfilled, (state, action) => {
      state.Category = action.payload;
      state.loading = false;
    });
    builder.addCase(GetCategory.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(NewProductAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(NewProductAction.fulfilled, (state, action) => {
      state.etat = "success";
      state.message = action.payload.message;
      state.loading = false;
    });
    builder.addCase(NewProductAction.rejected, (state, action) => {
      state.etat = "error";
      state.message = action.payload.message;
      state.loading = false;
    });
    builder.addCase(UpdateProductAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(UpdateProductAction.fulfilled, (state, action) => {
      state.etat = "success";
      state.message = action.payload.message;
      const index = state.ProductPriceList.findIndex(
        (p) => Number(p.id) === Number(action.payload.product.id)
      );
      console.log("index", index);
      console.log(
        "son number :",
        action.payload.product.id,
        typeof action.payload.product.id
      );
      console.log(
        " number :",
        action.payload.product.id,
        typeof Number(action.payload.product.id)
      );
      console.log("ProductPriceList before update:", state.ProductPriceList);
      if (index !== -1) {
        state.ProductPriceList[index] = action.payload.product;
      }
      state.loading = false;
    });
    builder.addCase(UpdateProductAction.rejected, (state, action) => {
      state.etat = "error";
      state.message = action.payload.message;
      state.loading = false;
    });

    builder.addCase(DeleteProductAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(DeleteProductAction.fulfilled, (state, action) => {
      state.etat = "success";
      state.message = action.payload.message;
      const index = state.ProductPriceList.findIndex(
        (p) => Number(p.id) === Number(action.payload.id)
      );
      console.log(index);
      // Remove the product from the list if found
      if (index !== -1) {
        state.ProductPriceList.splice(index, 1);
      }
      state.loading = false;
    });
    builder.addCase(DeleteProductAction.rejected, (state, action) => {
      state.etat = "error";
      state.message = action.payload.message;
      state.loading = false;
    });
  },
});

export default productSlice.reducer;
export const { etatInisialProductSlice } = productSlice.actions;
