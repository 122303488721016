// useBookingFunctions.js
import { useEffect, useState } from "react";

export const useBookingFunctions = () => {
  const [packages, setPackages] = useState([
    {
      produits: [],
      produitsDelete: [],
      file: null,
      container: false,
    },
  ]);
  const [PackageDelete, setPackagesDelete] = useState([]);
  const [IndexsFileNull, setIndexFileNull] = useState([]);
  const [PrixTotal, setPrixTotal] = useState(0);

  useEffect(() => {
    let totalPrice = 0;
    packages.forEach((item) => {
      const { produits } = item;
      produits.forEach((produit) => {
        console.log(produit.product.prixProduct);
        console.log(produit.quantity);
        totalPrice += produit.product.prixProduct * produit.quantity;
      });
    });
    setPrixTotal(totalPrice);
  }, [packages]);

  const AddProductsPackage = (index, products) => {
    setPackages((prev) => {
      const updatedProducts = [...prev];
      const updatedProduits = [...updatedProducts[index].produits, ...products];
      updatedProducts[index] = {
        ...updatedProducts[index],
        produits: updatedProduits,
      };
      return updatedProducts;
    });
  };

  const AddfilePackage = (index, file) => {
    setPackages((prev) => {
      const updatedProducts = [...prev];

      updatedProducts[index] = {
        ...updatedProducts[index],
        file: file,
      };
      return updatedProducts;
    });
  };

  const DeletefilePackage = (index) => {
    setPackages((prev) => {
      const updatedProducts = [...prev];
      updatedProducts[index] = {
        ...updatedProducts[index],
        file: null, // Or delete updatedProducts[index].file
      };
      return updatedProducts;
    });
  };

  const UpdateQuantite = (indexPack, indexProduct, quantity) => {
    if (quantity > 0) {
      setPackages((prev) => {
        const updatedPack = [...prev];
        updatedPack[indexPack].produits[indexProduct] = {
          ...updatedPack[indexPack].produits[indexProduct],
          quantity: quantity,
        };
        return updatedPack;
      });
    }
  };

  const handleRemove = (indexPack, indexProduct) => {
    setPackages((prev) => {
      const updatedPack = [...prev];
      const productToRemove = updatedPack[indexPack].produits[indexProduct];

      if (productToRemove.packageProduct) {
        updatedPack[indexPack].produitsDelete = updatedPack[
          indexPack
        ].produitsDelete.concat(productToRemove.packageProduct);
      }
      updatedPack[indexPack].produits.splice(indexProduct, 1);
      return updatedPack;
    });
  };

  const UpdateStoke = (indexPack, stoke) => {
    setPackages((prev) => {
      const updatedPack = [...prev];
      updatedPack[indexPack] = {
        ...updatedPack[indexPack],
        stoke: stoke,
      };
      return updatedPack;
    });
  };

  const UpdateStokeContainer = (indexPack, stoke) => {
    setPackages((prev) => {
      const updatedPack = [...prev];
      updatedPack[indexPack] = {
        ...updatedPack[indexPack],
        container: !updatedPack[indexPack].container,
      };
      return updatedPack;
    });
  };

  const areAllPackagesTrue = () => {
    return packages.every((pkg) => pkg.container === true);
  };

  const deletePackage = (index) => {
    if (packages[index].id) {
      setPackagesDelete((prev) => [...prev, packages[index].id]);
    }
    setPackages(packages.filter((_, i) => i !== index));
  };

  const addPackage = () => {
    setPackages([
      ...packages,
      {
        produits: [],
        file: null,
        container: false,
        stoke: null,
      },
    ]);
  };

  const calculePrixPack = (produits) => {
    let totalPrice = 0;
    produits.forEach((produit) => {
      console.log(produit.product.prixProduct);
      console.log(produit.quantity);
      totalPrice += produit.product.prixProduct * produit.quantity;
    });
    return totalPrice;
  };

  return {
    packages,
    setPackages,
    IndexsFileNull,
    setIndexFileNull,
    AddProductsPackage,
    AddfilePackage,
    DeletefilePackage,
    UpdateQuantite,
    handleRemove,
    UpdateStoke,
    deletePackage,
    addPackage,
    UpdateStokeContainer,
    areAllPackagesTrue,
    calculePrixPack,
    PrixTotal,
    PackageDelete,
  };
};
