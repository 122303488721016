import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import TitreGraphik from "../Typography/TitreGraphik";
import LabelTypography from "../Typography/LabelTypography";
import ButtonSonRadius from "../Button/buttonSonRadius";

export default function AddShips({ onClose, open, send }) {
  return (
    <Dialog onClose={onClose} open={open}>
      <form onSubmit={send}>
        <DialogTitle>
          <TitreGraphik>Add ships</TitreGraphik>
        </DialogTitle>
        <DialogContent>
          <LabelTypography required={true}>name ships</LabelTypography>
          <TextField
            type="text"
            name="ships"
            placeholder="name ships"
            required
          />
        </DialogContent>
        <DialogActions>
          <ButtonSonRadius variant="contained" color="error" onClick={onClose}>
            cancel
          </ButtonSonRadius>
          <ButtonSonRadius variant="contained" type="submit" color="primary">
            Add
          </ButtonSonRadius>
        </DialogActions>
      </form>
    </Dialog>
  );
}
