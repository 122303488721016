import { Grid, Box, TextField, IconButton } from "@mui/material";
import LabelTypography from "../Typography/LabelTypography";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useEffect, useState } from "react";
import { AddCircle, DeleteOutlineRounded } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getPorts } from "../../store/slice/PortsSlice";
import { getStatus } from "../../store/slice/StatistiqueSlice";
import { getCapitaine, GetShips } from "../../store/slice/VoyageSlice";
import { Icon, InputAdornment } from "@mui/material";
import AddPort from "../Popup/AddPort";
import AddPortPopup from "../Popup/AddPort";
import CustomAlert from "../Alert/Alert";
import { etatInisialPortSlice } from "../../store/slice/PortsSlice";

const FromVoyage = ({
  PortsVoyage,
  NewPort,
  PortEdit,
  DateTimeEdit,
  DeletePort,
  setNamePortDestination,
  formErrors,
  data,
  setPorts,
}) => {
  const { ports, etat, message } = useSelector((state) => state.PortsSlice);
  const { status } = useSelector((state) => state.Statistique);
  const { ships, capitaines } = useSelector((state) => state.VoyageSlice);
  const [popupPort, setPopupPort] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPorts());
    dispatch(getStatus("voyage"));
    dispatch(GetShips());
    dispatch(getCapitaine());
  }, [dispatch]);

  useEffect(() => {
    const portFilter = data?.ports
      ?.slice(1)
      .map((p) => ({
        port: p.id,
        dateTime: p.pivot.dateArrival,
      }))
      .sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime));
    setPorts(portFilter);
    console.log(portFilter);
  }, [data?.ports, setPorts]);
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LabelTypography required={true}>Ship name</LabelTypography>

          <FormControl fullWidth>
            <Select
              labelId="Ship"
              id="Ship"
              required
              name="Ship"
              placeholder="Ship name"
              defaultValue={data?.idShips}
            >
              {ships?.map((p) => (
                <MenuItem value={p.id}>{p.nameShips}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <LabelTypography required={true}>Captain's name</LabelTypography>
          <FormControl fullWidth>
            <Select
              labelId="Captain"
              id="Captain"
              required
              name="Captain"
              placeholder="Captain's name"
              defaultValue={data?.idCaptain}
            >
              {capitaines?.map((p) => (
                <MenuItem value={p.id}>{p.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <LabelTypography required={true}>Route</LabelTypography>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="route"
              required
              placeholder="Route"
              defaultValue={data?.Route}
            >
              <MenuItem value="North">North</MenuItem>
              <MenuItem value="South">South</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton>
              <AddCircle
                color="primary"
                onClick={() => {
                  setPopupPort(true);
                }}
              />
            </IconButton>
            <LabelTypography required={true}>Departure Port</LabelTypography>
          </Box>

          <FormControl fullWidth>
            <Select
              labelId="Departure Port"
              id="Departure Port"
              required
              name="DeparturePort"
              placeholder="Departure Port"
              defaultValue={data?.ports[0]?.id}
            >
              {ports?.map((p) => (
                <MenuItem value={p.id}>{p.nomPort}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4} sx={{ marginTop: "1%" }}>
          <LabelTypography required={true}>
            Date and time cut-off
          </LabelTypography>
          <TextField
            type="datetime-local"
            fullWidth
            required
            name="cutDate"
            placeholder="Date and time cut-off"
            error={!!formErrors.cutDate}
            helperText={formErrors.cutDate}
            defaultValue={data?.dateCutof}
          />
        </Grid>
        <Grid item xs={12} md={4} sx={{ marginTop: "1%" }}>
          <LabelTypography required={true}>
            Date & time departure
          </LabelTypography>
          <TextField
            type="datetime-local"
            fullWidth
            required
            name="departureDate"
            placeholder="Date & time departure"
            error={!!formErrors.departureDate}
            helperText={formErrors.departureDate}
            defaultValue={data?.dateDeparture}
          />
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "3%",
            }}
          >
            <LabelTypography required={true}>Layovers </LabelTypography>
            <IconButton
              color="primary"
              onClick={() => {
                NewPort();
              }}
            >
              <AddCircle />
            </IconButton>
          </Box>
          <Grid container spacing={2}>
            {PortsVoyage?.map((p, index) => (
              <Grid xs={12}>
                <Box sx={{ border: "1px solid", marginTop: "1%" }} padding="1%">
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <LabelTypography textAlign="center">
                          Port {index + 1}
                        </LabelTypography>{" "}
                        <IconButton
                          color="error"
                          onClick={() => DeletePort(index)}
                        >
                          <DeleteOutlineRounded />
                        </IconButton>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <Select
                          labelId={`port${index}`}
                          id={`port${index}`}
                          value={p.port}
                          onChange={(e) => {
                            PortEdit(index, e.target.value);
                          }}
                          required
                          placeholder={`port${index}`}
                        >
                          {ports?.map((p) => (
                            <MenuItem value={p.id}>{p.nomPort}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        type="datetime-local"
                        fullWidth
                        required
                        value={p.dateTime}
                        placeholder="date"
                        onChange={(e) => {
                          DateTimeEdit(index, e.target.value);
                        }}
                        error={!!formErrors[`layover-${index}`]}
                        helperText={formErrors[`layover-${index}`]}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <LabelTypography required={true}>Status</LabelTypography>
          <FormControl fullWidth>
            <Select
              labelId="Status"
              id="Status"
              name="Status"
              defaultValue={data?.idStatus}
              required
            >
              {status?.map((s) => (
                <MenuItem value={s.id}>{s.etat}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <AddPortPopup
        open={popupPort}
        onClose={() => {
          setPopupPort(false);
        }}
      />
      <CustomAlert
        open={etat !== "inisial"}
        onClose={() => {
          dispatch(etatInisialPortSlice());
        }}
        etat={etat}
        message={message}
      />
    </Box>
  );
};

export default FromVoyage;
