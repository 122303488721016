import LabelTypography from "../Typography/LabelTypography";
import editIcon from "../../assets/Images/editIcon.svg";
import { Box, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { getStatus } from "../../store/slice/StatistiqueSlice";

export default function ActionBookinStatus({
  setPopupOnboard,
  setId,
  setPaye,

  b,
}) {
  const Dispatch = useDispatch();
  const Stylebutton = {
    backgroundColor: "#064C91",
    "&:hover": {
      backgroundColor: "#064C91", // Same color on hover
    },
    "&:active": {
      backgroundColor: "#064C91", // Same color on click
    },
    "&:focus": {
      backgroundColor: "#064C91", // Same color on focus
    },
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          background: "#F5F5F5",
          padding: "4%",
        }}
      >
        <LabelTypography color="text.primary">
          status :
          <LabelTypography component="span"> {b?.status?.etat}</LabelTypography>
        </LabelTypography>

        <Button
          variant="contained"
          sx={Stylebutton}
          size="small"
          onClick={() => {
            setId(b.id);
            Dispatch(getStatus("booking"));
            setPopupOnboard(true);
          }}
          startIcon={
            <img
              src={editIcon}
              alt="Edit"
              height="20" // Adjust height as needed
              style={{ marginRight: "5px" }}
            />
          }
        >
          Edit
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          background: "#F5F5F5",
          padding: "4%",
        }}
      >
        <LabelTypography color="text.primary">
          Payment :<LabelTypography component="span"> {b.Payé}</LabelTypography>
        </LabelTypography>

        <Button
          variant="contained"
          sx={Stylebutton}
          size="small"
          onClick={() => {
            setId(b.id);
            setPaye(true);
          }}
          startIcon={
            <img
              src={editIcon}
              alt="Edit"
              height="20" // Adjust height as needed
              style={{ marginRight: "5px" }}
            />
          }
        >
          Edit
        </Button>
      </Box>
    </>
  );
}
