import React from "react";
import { format } from "date-fns";
import { Box } from "@mui/material";
import TitreGraphik from "../Typography/TitreGraphik";

const DateFormate = ({ date }) => {
  // Convertir la chaîne de date en objet Date
  const formattedDate = new Date(date);
  //const formattedDateString = format(formattedDate, "dd MMM HH:mm");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        pb: "14px",
        backgroundColor: "white",
        border: "1px solid #0284c7",
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#064C91",
          border: "1px solid #064C91",
          height: 26,
        }}
      />
      <Box sx={{ mt: "-18px", zIndex: 10, color: "#FFFFFF" }}>
        {format(formattedDate, "dd MMM")}
        <br />
        <TitreGraphik
          component="span"
          sx={{ fontSize: { xs: "1rem", md: "1.5rem" }, color: "#064C91" }}
        >
          {format(formattedDate, "HH:mm")}
        </TitreGraphik>
      </Box>
    </Box>
  );
};

export default DateFormate;
