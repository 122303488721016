import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  statusPending,
  statusChecked,
  statusRejected,
  statusInProgress,
} from "../../assets/style/status";

const statusLabel = {
  display: "inline-block",
  padding: "5px 10px",
  borderRadius: "20px",
  fontWeight: "bold",
  fontSize: "14px",
};

function StatusLabel({ status }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  let style = {};

  switch (status) {
    case "in-progress":
      style = statusInProgress;
      break;
    case "checked":
      style = statusChecked;
      break;
    case "pending":
      style = statusPending;
      break;
    case "rejected":
      style = statusRejected;
      break;
    default:
      break;
  }
  console.log(style);
  return (
    <span
      style={{
        ...statusLabel,
        ...style,
        fontSize: isSmallScreen ? "12px" : "14px",
      }}
    >
      {status}
    </span>
  );
}

export default StatusLabel;
