// src/App.js
import React from "react";
import SalesChart from "../../components/Statistique/Saleschart";
import VolumeBreakdown from "../../components/Statistique/VolumeBreakdown";
import JourneyTimeAnalysis from "../../components/Statistique/JourneyTimeAnalysis";
import { Container, Grid, Paper } from "@mui/material";
import Indexdasboard from "../../components/dashboard/IndexDashbord";

function StatistiqueAdmin() {
  return <Indexdasboard Children={<Statistique />} />;
}

const Statistique = () => {
  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ padding: "2%" }}>
            <SalesChart />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ padding: "2%" }}>
            <VolumeBreakdown />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper
            sx={{
              padding: "2%",
              height: "100%",
            }}
          >
            <JourneyTimeAnalysis />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default StatistiqueAdmin;
